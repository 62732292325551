/******************************************************************************/
/**********         Spécifique aux fiches films          ***********/
/******************************************************************************/

.collection .btn {
    display: block;
    margin-bottom: 5px;
}

#movie_status {
    margin-bottom: 30px;
}

#movie_visionnage {
	margin-bottom: 15px;
	
    .visionnage {
		margin-bottom: 15px;
		
		li {
			margin-bottom: 5px;
			
			a {
				display: block;
				text-align: left;
				
				@media (max-width: 1200px){
					padding: 6px;
				}
				
				.fa {
					margin-right: 5px;
				}
			}
		}
	}
}

.casting#castingMovie {
	ul {
		display: flex;
		flex-wrap: wrap;
		
		li {
			height: auto;
			min-height: 80px;
			
			a {
				color: #676767;

				.sbox_edit {
					font-size: 10px;
				}
			}

			img.miniature {
				border-radius: 100%;
			}

			p.movieRole {
				color: #797979;
			}
		}
	}
}

#movie_team {
    margin-top: 30px;
	
	p {
		margin: 0;
	}
	
	h3 {
		color: #6D6D6D;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
	}
	
	ul {
		line-height: 16px;
	}
	
	.glyphicon, .fa {
		font-size: 11px;
	}
}

#movie_sagas {
    margin-bottom: 30px;
}

#row_crew,
#row_awards,
#row_comments {
    padding: 15px 0;
}
#row_crew {
    background: white;
}

#movie_ifyoulike {
    margin-bottom: 30px;
	
	.movie_like {
		float: left;
		padding-bottom: 10px;
		height: 200px;
		width: 127px;
		margin-right: 15px;
		
		@media screen and (min-width: 1201px) {
			&:nth-child(4n) {
				margin-right: 0px;
			}
			
			&:nth-child(n+9) {
				display:none;
			}
		}
		
		@media screen and (min-width:992px) and (max-width: 1200px) {
			width: 141px;
			height: 210px;
			
			&:nth-child(3n) {
				margin-right: 0px;
			}
			
			&:nth-child(n+10) {
				display:none;
			}
		}
		
		@media screen and (min-width:768px) and (max-width: 991px) {
			width: 132px;
			
			&:nth-child(5n) {
				margin-right: 0px;
			}
		}
		
		@media screen and (min-width:501px) and (max-width: 767px) {
			width: 23.5%;
			margin-right: 2%;
			
			&:nth-child(4n) {
				margin-right: 0px;
			}
			
			&:nth-child(n+9) {
				display:none;
			}
		}
		
		@media screen and (min-width:0px) and (max-width: 500px) {
			width: 32%;
			margin-right: 2%;
			
			&:nth-child(3n) {
				margin-right: 0px;
			}
			
			&:nth-child(n+10) {
				display:none;
			}
		}
		
		a {
			display: block;
			width: 100%;
			overflow: hidden;
			height: 100%;
			text-align: center;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			transition: all 1000ms ease-in-out;
		}
		
		.ifyoulike_cover {
			height: 100%;
		}
	}
}

#field_chronique {
    width: 100%;
    margin-bottom: 15px;
}

.movie_chronique {
    overflow: hidden;
    margin-bottom: 15px;
	
	.avatar_link_member {
		border-radius: 100%;
		margin-right: 10px;
		
		img {
			border-radius: 100%;
		}
	}
	
	.link_chronique {
		font-size: 13px;
		font-weight: bold;
		white-space: nowrap;
		margin: 0;
	}
	
	.note {
		color: #A7A7A7;
	}
	
	.comment_admin {
		margin: 0;
		
		a {
			color: #D2D2D2;
		}
	}
}

.movie_edit_date {
    display: none;
    margin: 0px;
    margin-bottom: 15px;
}

.movie_vision {
    background: $color_gray;
    padding: 5px 10px;
    margin-bottom: 15px;
	
	.label_span {
		width: 100px;
		display: inline-block;
		padding-left: 5px;
	}
}

.movie_vision_actions 
	.fa
		font-size: 16px;
		color: #333;
	
	&:hover
		opacity: 0.8;

.add_acteur
	.add_icon
		width 75px
		height 75px
		background white
		
		.fa
			font-size 35px

	h4
		opacity 0
		transition all 250ms ease-in-out

	&:hover
		h4
			opacity 1


.title_gray 	
	font-size: 17px;
	font-weight: 700;
	line-height: 20px;
	color: #676767;

.comment_text
	word-break: break-word;

	img
		max-width 100%

	@media (max-width: 991px)
		clear: both;
		padding-top: 5px;

		p
			margin-top: 0;
/* Challenges cine */

$color_challenge_2015 = #a29069;
$color_challenge_2016 = #031249;
$color_challenge_2017 = #a29069;
$color_challenge_2018 = #5f4b8c;
$color_challenge_2019 = #ff6f61;
$color_challenge_2020 = #f1c03b;
$color_challenge_2021 = #000000;
$color_challenge_2022 = #ad0b22;
$color_challenge_2023 = #f2789e;
$color_challenge_2024 = #106d84;
$color_challenge_2025 = #000000;

.challenge_nav
	padding: 10px;
	margin: 10px 0;
	
	.logo_cine_challenge
		@media (max-width 992px)
			max-width: 250px
			margin auto

	.nav
		display: inline-block;
		
		li
			a
				transition: all 300ms ease-in-out;
	
    for year in 2015 2016 2017 2018 2019 2020 2021 2022 2023 2024 2025
		&.challenge_nav_{year}
			.nav
				li
					a
						background white
						color lookup('$color_challenge_' + year)

					&.active, &:hover
						a
							background lookup('$color_challenge_' + year)
							color white

.nav.nav_challenge_genre, .nav.nav_challenge_year
	margin:10px 0px; 
	
	> li
		margin-bottom 5px

		> a
			padding: 3px 5px
			
			&.big
				font-size:16px

.challenge_explications
	font-size: 14px;
	margin-bottom: 20px;
	
	> .row
		display: flex;
		flex-direction: row;
		align-items: center;
	
	h1
		font-size: 26px;

	.scoreWrap
		justify-content: flex-end;

		@media (max-width: 767px)
			justify-content: space-between

		.ltitle
			@media (max-width: 500px)
				font-size: 12px
	
	.scoreTotal
		display: flex
		justify-content: center
		align-items: flex-end
		font-family: 'Oswald',Verdana,sans-serif;
		font-size: 40px;
		text-align: right;

		@media (max-width: 500px)
			font-size: 24px

		.slash 
			font-size: 0.5em

		.total
			font-size: 0.5em

		&.-tv
			.slash 
				@media (max-width: 500px)
					display: none

			.total
				@media (max-width: 500px)
					display: none
	
	for year in 2015 2016 2017 2018 2019 2020 2021 2022 2023 2024 2025
		&.expli_{year}
			h1
				color: lookup('$color_challenge_' + year)
			
			.scoreTotal span.vus
				color: lookup('$color_challenge_' + year)
			
			.progress
				display: flex

				.progress-bar
					background-color: lookup('$color_challenge_' + year)

					&.-ongoing
						background-color: #3f902b

					&.-finished
						background-color: #337ab7

					&.-wish
						background-color: #B10048

					&.-abandon
						background-color: #29641a

#top100 
	@media (max-width: 767px)
		margin: 0 -15px
		width: calc(100% + 30px)
		max-width: none
	
	tr.vu 
		background:#E2EBF1

		&:hover 
			background:#C8DFEE

		a.linkCine
			color #337ab7
	
	tr.ongoing
		background:#dbf3d5

		&:hover 
			background:darken(#dbf3d5,10%)

		a.linkCine
			color: #3f902b

		.advancement_media .advancement_progress.advancement_progress_primary
			background #3f902b

	tr.abandon
		background:#dae6d8

		&:hover 
			background:darken(#dae6d8,10%)

		a.linkCine
			color: #3f902b

		.advancement_media .advancement_progress.advancement_progress_primary
			background #3f902b
	
	tr.wish
		background:#F3E7EC

		&:hover 
			background: #F7D7E4

		a.linkCine
			color: #B10048
	
	tr.pasvu
		background:#E4E4E4

		&:hover 
			background: #CECECE

		a.linkCine
			color: #181818


	td
		vertical-align:middle

		&.col_percent
			width 300px

			@media (max-width: 767px)
				width 90px

			.advancement_media
				@media (max-width: 767px)
					width: 100%

			.percent_serie
				@media (max-width: 767px)
					display: none

#wishChallenge 
	float:left

	span
		display: inline-block;
		padding: 5px 10px;
		border-radius: 24px;

		&#challenge_films_vus
			background:#C8DFEE;

		&#challenge_films_wish
			background: #F7D7E4

		&#challenge_films_non_vu
			background:#CECECE;

		&#challenge_series_ongoing
			background: darken(#dbf3d5,10%);

		&#challenge_series_abandon
			background: #dae6d8

#submenu_challenge li { float:left; padding: 5px; margin: 2px; }
#submenu_challenge li.current, #submenu_challenge li:hover { background:#3570C2; color: white; }
#submenu_challenge li a, #submenu_challenge li a:hover { text-decoration:none; }
#submenu_challenge li.current a, #submenu_challenge li a:hover { color:white; }
#submenu_challenge { list-style:none; padding: 20px 0px 30px; }

#challenge_explications p { font-size: 14px; line-height:20px; width: 70%; }
#challenge_tweetThat { float: right; }

.ch-grid { margin: 20px 0 0 0; padding: 0; list-style: none; display: block; text-align: center; width: 100%; } 
.ch-grid:after, .ch-item:before { content: ''; display: table; } 
.ch-grid:after { clear: both; } 
.ch-grid li { width: 190px; height: 190px; display: inline-block; margin: 10px; } 
.ch-item { width: 100%; height: 100%; border-radius: 50%; position: relative; box-shadow: 0 1px 2px rgba(0,0,0,0.1); cursor: default; } 
.ch-info-wrap{ position: absolute; width: 150px; height: 150px; border-radius: 50%; 
-webkit-perspective: 800px; -moz-perspective: 800px; -o-perspective: 800px; -ms-perspective: 800px; perspective: 800px; 
-webkit-transition: all 0.4s ease-in-out; -moz-transition: all 0.4s ease-in-out; -o-transition: all 0.4s ease-in-out; -ms-transition: all 0.4s ease-in-out; transition: all 0.4s ease-in-out; 
top: 20px; left: 20px; background: #f9f9f9 url(../../../images/bg.jpg); box-shadow: 0 0 0 20px rgba(255,255,255,0.2), inset 0 0 3px rgba(115,114, 23, 0.8); 
} 
.ch-info{ position: absolute; width: 150px; height: 150px; border-radius: 50%; 
-webkit-transition: all 0.4s ease-in-out; -moz-transition: all 0.4s ease-in-out; -o-transition: all 0.4s ease-in-out; -ms-transition: all 0.4s ease-in-out; transition: all 0.4s ease-in-out; -webkit-transform-style: preserve-3d; -moz-transform-style: preserve-3d; -o-transform-style: preserve-3d; -ms-transform-style: preserve-3d; transform-style: preserve-3d; 
} 
.ch-info > div { display: block; position: absolute; width: 100%; height: 100%; border-radius: 50%; background-position: center center; 
-webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -o-backface-visibility: hidden; -ms-backface-visibility: hidden; backface-visibility: hidden; } 
.ch-info .ch-info-back { -webkit-transform: rotate3d(0,1,0,180deg); -moz-transform: rotate3d(0,1,0,180deg); -o-transform: rotate3d(0,1,0,180deg); -ms-transform: rotate3d(0,1,0,180deg); transform: rotate3d(0,1,0,180deg); background: #000; }
.ch-info h3 { color: #fff; text-transform: uppercase; letter-spacing: 2px; font-size: 14px; margin: 0 15px; padding: 40px 0 0 0; height: 90px; font-family: 'Open Sans', Arial, sans-serif; text-shadow: 0 0 1px #fff, 0 1px 2px rgba(0,0,0,0.3); } 
.ch-info p { color: #fff; padding: 10px 5px; font-style: italic; margin: 0 30px; font-size: 12px; border-top: 1px solid rgba(255,255,255,0.5); } 
.ch-info p a { display: block; color: #fff; color: rgba(255,255,255,0.7); font-style: normal; font-weight: 700; text-transform: uppercase; font-size: 9px; letter-spacing: 1px; padding-top: 4px; font-family: 'Open Sans', Arial, sans-serif; } 
.ch-info p a:hover { color: #fff222; color: rgba(255,242,34, 0.8); } 
.ch-item:hover .ch-info-wrap { box-shadow: 0 0 0 0 rgba(255,255,255,0.8), inset 0 0 3px rgba(115,114, 23, 0.8); } 
.ch-item:hover .ch-info { -webkit-transform: rotate3d(0,1,0,-180deg); -moz-transform: rotate3d(0,1,0,-180deg); -o-transform: rotate3d(0,1,0,-180deg); -ms-transform: rotate3d(0,1,0,-180deg); transform: rotate3d(0,1,0,-180deg); } 
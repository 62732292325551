
#listing 
	> li
		display flex
		align-items center
		justify-content space-between
		position relative
		background #e8e8e8
		margin 15px 0px
		border-left 10px solid black

		&.active
			background-color #EBF1FA

		&.vu
			border-left 10px solid #3570C2
			// background #dae9ff

			&.termine
				border-color: $color_game_termine;

			&.joue
				border-color: $color_game_joue;

			&.encours
				border-color: $color_game_encours;
			
			&.pause
				border-color: $color_game_pause;

		&.wish
			border-left 10px solid $color_wish
	
		@media (max-width 778px)
			flex-direction column

	.number
		flex none
		width 100px
		text-align center
		font-size 15px
		font-weight bold
		margin 0px
		color #444
		font-size 40px
		padding 30px 0px

		@media (max-width 778px)
			// display none
			padding 0

	.affiche
		flex none

		> a
			display block

			img
				display block

				@media (max-width 778px)
					width 100%
					height auto

		&.affiche_film
			width 75px
			text-align left
			overflow hidden
			text-align center
			padding 0px

			@media (max-width 778px)
				order 0
				width 100%
				max-width 75px
				height auto

		&.affiche_game
			width 75px
			text-align left
			overflow hidden
			text-align center
			padding 0px

			@media (max-width 778px)
				order 0

			> a  
				display: block;
				height: 100px;
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;

		&.affiche_serie
			width 200px
			height 100px
			overflow hidden
			text-align center
			padding 0px

			@media (max-width 778px)
				order 0
				width 100%
				max-width 200px
				height auto

	.infos 
		@media (max-width 778px)
			order 2

	h3
		font-size 20px
		font-weight bold
		overflow hidden
		text-align left

		span.dlc
			background: $color_dlc
			color: white;
			padding: 4px 7px;
			display: inline-block;
			font-size: 12px;
			line-height: 12px;
			margin-left: 10px;

	.annee
		color #333

	.moyenne_wrap
		flex none
		width 160px
		color #555
		text-align: center
		margin-right: 20px;

		@media (max-width 778px)
			order 3

		.noteCircle 
			width 70px
		
		.moyenneCircle
			width 70px

		.moyenne
			font-size 24px
			font-weight bold
			margin 0
			padding 0

			&.mynote
				border-top 1px solid #555
				margin-top 10px
				padding-top 10px

		.nbrevotes
			font-size 10px
			font-weight bold
			margin 0
			padding 0

	.youfollow
		font-weight bold
		background #3570C2
		color white
		padding 3px 8px
		display inline-block
		font-size 13px

	.infos_films
		line-height 20px
		position relative

		@media (max-width: 778px)
			.visionnage
				display flex
				align-items center
				justify-content center

	.infos_game
		.status_1
			color $color_game_termine

		.status_2
			color $color_game_joue
			
		.status_3
			color $color_game_encours

		.status_4
			color $color_wish

		.status_5
			color $color_game_pause

	ul.options
		right 0px

	li a.extend
		position absolute
		top 5px
		right 5px

.link_precedent
	float left
	padding-left 16px

.link_suivant
	float right
	padding-right 16px


.circle-svg
	display: block;
	margin: 10px auto;
	max-height: 80px;

	&.-small
		max-height: 40px;

	text
		text-align:center;
		color:black;
		font-size: 10px;
		font-weight: bold;
		margin: 0;
		padding: 0;

	path.percent
		stroke: black;
		stroke: red;
		fill: none;
		stroke-width: 2.6;
		stroke-linecap: round;
		animation: progress 1s ease-out forwards;

	path.around
		stroke: #c4c4c4;
		fill: none;
		stroke-width: 2.8;

body.darkmode
	.circle-svg
		text
			fill $color_dark_text

		path.around
			stroke $color_dark_subtitle 
			stroke-width 0
		
		// path.percent
		// 	fill: #686868;

.status_game_1
	color: $color_game_termine;

.status_game_2
	color: $color_game_joue;

.status_game_3
	color: $color_game_encours;

.status_game_4
	color: $color_wish;

.status_game_5
	color: $color_game_pause;
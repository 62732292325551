.rentree_table { width:100%; }
.rentree_titleserie { width:300px; }

.rentree_legend
    .trserierentree
        display: inline-block
        padding 2px 5px

.rentree_network
    a   
        font-size: 25px;

.trserierentree
    background #FFF

    td 
        vertical-align:middle; 
        padding:5px 20px 5px 5px;

    &.rentreeheader
        background:none;
        font-weight:bold;

    &.newserie
        // background #FAF472
        background #fffdc9
        
        &.nextyear
            background #EBE9C4

    &.renouvelee
        // background #C7F64E
        background #e7ffa9

    &.annulee
        // background #FC5858
        background #FFE0E0
.flex
  display flex

@media screen and (min-width: --breakpoint-sm-min)
  .sm-flex
    display flex

@media screen and (min-width: --breakpoint-md-min)
  .md-flex
    display flex

@media (min-width: --breakpoint-lg-min)
  .lg-flex
    display flex

.flex-column
  flex-direction column

.flex-row
  flex-direction row

@media screen and (min-width: --breakpoint-sm-min)
  .sm-flex-column
    flex-direction column
  .sm-flex-row
    flex-direction row

@media screen and (min-width: --breakpoint-md-min)
  .md-flex-column
    flex-direction column
  .md-flex-row
    flex-direction row

@media (min-width: --breakpoint-lg-min)
  .lg-flex-column
    flex-direction column
  .lg-flex-row
    flex-direction row

.flex-wrap
  flex-wrap wrap

.items-start
  align-items flex-start
.items-end
  align-items flex-end
.items-center
  align-items center
.items-baseline
  align-items baseline
.items-stretch
  align-items stretch

.self-start
  align-self flex-start
.self-end
  align-self flex-end
.self-center
  align-self center
.self-baseline
  align-self baseline
.self-stretch
  align-self stretch

justifyValues = {
  justify-start: flex-start, 
  justify-end: flex-end, 
  justify-center: center, 
  justify-between: space-between, 
  justify-around: space-around
}

for index, key in justifyValues
  .{index}
    justify-content key
  
for index, key in justifyValues
  @media screen and (min-width: --breakpoint-sm-min)
    .sm-{index}
      justify-content key

  @media screen and (min-width: --breakpoint-md-min)
    .md-{index}
      justify-content key

  @media (min-width: --breakpoint-lg-min)
    .lg-{index}
      justify-content key

.content-start
  align-content flex-start
.content-end
  align-content flex-end
.content-center
  align-content center
.content-between
  align-content space-between
.content-around
  align-content space-around
.content-stretch
  align-content stretch

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto
  flex 1 1 auto
  min-width 0
  min-height 0
  
.flex-none
  flex none

.order-0
  order 0
.order-1
  order 1
.order-2
  order 2
.order-3
  order 3
.order-last
  order 99999

/******************************************************************************/
/**********                 Spécifique aux fiches séries          ***********/
/******************************************************************************/

.label.status_serie {
	display: inline-block;
	padding: 0 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;

	@media (max-width: 767px){
		white-space: inherit;
		text-align: left;
		line-height: 10px;
		padding: 5px 10px;
	}
}

.fiche_header_tvshow {
	.fiche_v2_header_title {
		@media (max-width: 767px){
			display: flex;
			flex-direction: column;
		}
		
		h1 {
			@media (max-width: 767px){
				width: calc(100% - 100px);
				order: 2;
			}
		}
	}
	
	p.item_year {
		display: inline;

		@media (max-width: 767px){
			display: block;
			width: calc(100% - 100px);
			order: 0;
		}
		
		.label.status_serie { 
			position: relative;
			top: -2px;
			margin-left: 10px;
			
			@media (max-width: 767px){
				margin-top: 8px;
				margin-left: 0;
			}
		}
	}

	p.item_show_status {
		margin: 0;
		margin-top: 5px;

		@media screen and (max-width: 767px){
			order: 3;
		}

		@media screen and (min-width: 768px){
			display: inline;
			position: relative;
			top: -2px;
			margin-left: 10px;
		}
	}

	h2 {
		@media screen and (max-width: 767px){
			order: 3
		}
	}

	.header_line_infos {
		@media screen and (max-width: 767px){
			order: 10;
		}
	}
}

.tvshow_season_casting {
	.casting {
		li {
			img.miniature {
				border-radius: 100%;
				with: auto;
				
				&.lazy {
					background: gray;

					&.min_small {
						width: 75px;
						height: 75px;
					}
				}
			}
		}
		
		.casting_big li img.miniature {
			border-radius: 0;
			margin: 0;
			float: none;
		}
	}
}

.casting {
	.casting_big {
		display: flex;
		align-items: initial;
		flex-wrap: wrap;
		
		li {
			height: auto;
			
			img.lazy {
				background: gray;
			}
			
			h4 {
				width: 100%;
			}
			
			p {
				float: none;
				width: 100%;
				clear: both;
			}
		}
	}
}


#row_manage_serie {
    margin: 15px 0;
}

#row_tabs_serie {
    margin: 15px 0;
	
	.tab-pane {
		min-height: 600px;
		margin-top: 15px;
	}
	
	.tab-content-seasons {
		.tab-pane {
			min-height: auto;
			margin-top: 0;
		}
	}
}

/*.item_diffusion_network a {
	background: #fff;
    display: block;
	padding: 5px;
}*/

.sticky-wrapper {
    position: relative;
}

ul.nav.nav-pills.nav-pills-narrow#tvshow_menu {
	a {
		@media (max-width: 767px){
			padding: 2px 5px;
			font-size: 12px;
		}
	}
}

.seasons_change {
	padding: 10px 0;
	transition: all 200ms ease-in-out;
	
	> .container {
		padding: 0;
	}
	
	&.stuck {
		position: fixed;
		top: 60px;
		left: 0;
		right: 0;
		z-index: 1000;
		background: #FFF;
		box-shadow: 1px 1px 2px #d8d8d8;
		transition: all 300ms ease-in-out;
		
		> .container {
			padding: 0 15px;
		}
	}
	
	.nav-pills>li>a {
		padding: 3px 5px;
		min-width: 20px;
		text-align: center;
	}
}

.topbar_casting {
	padding: 10px 0;
	transition: all 200ms ease-in-out;
	
	> .container {
		padding: 0;
		width: 100%;
	}
	
	&.stuck {
		position: fixed;
		top: 60px;
		left: 0;
		right: 0;
		z-index: 1000;
		background: #FFF;
		box-shadow: 1px 1px 2px #d8d8d8;
		transition: all 300ms ease-in-out;
		
		> .container {
			padding: 0 15px;

			@media (min-width: 768px) {
				width: 750px;
			}

			@media (min-width: 992px) {
				width: 970px;
			}

			@media (min-width: 1200px) {
				width: 1170px;
			}
		}
	}
	
	.nav-pills>li {
		height: auto;
		margin: 0;

		> a {
			padding: 3px 5px;
			min-width: 20px;
			text-align: center;
		}
	}
}

.pourcentage_series {
	padding: 0;
	margin: 15px 0;
	margin-top: 0;
}
.change_add_serie {
    padding: 0;
	margin: 15px 0;
}
.change_add_serie button {
	width: 100%;
}
.change_status_serie {
    position: relative;
	width: 100%;
	
	button {
		width: 100%;
		font-size: 16px;
	}
	
	ul.dropdown-menu {
		li {
			a.current {
				background: #3d9bdc;
				color: #fff;
			}
		}
	}
}

#row_movie_casting {
	background: $color_gray;
	padding: 15px 0;

	h2, .h2wide h2 {
		color: #2B2B2B;
	}
}

#castingTvShow {
	.casting {
		a {
			&.actor_link_pic {
				display: block;
				margin-bottom: 5px;
				float: left;

				&.big {
					width 100%;
				}
				
				.miniature_bg {
					width: 100%;
					height: 225px;
					background-size:cover;
					background-repeat: no-repeat;
					background-position: 50% 50%;
				}
			}
			
			.sbox_edit {
				font-size: 10px;
			}
		}
	}
}

.tvshow_season_episodes {
    margin-top: 0px;
	
	h3.season_title {
		font-size: 20px;
	}
	
	.part_title_wrap {
		padding: 0;
		
		h3.part_title {
			font-size: 20px;
			background: #e0e0e0;
			padding: 10px;
			margin: 0;
		}
	}
	
	.table {
		>thead>tr>th {
			border-bottom: 1px solid #DDD;
			color: #676767;
		}
		
		tr.episode {
			&.ep_diff { 
				background: #FFF; 
			}
			&.ep_nondiff {
				color: #808080;
				
				a {
					color: #808080;
				}
			}
			&.checkedni { background-color:#d6d6d6; }
			&.checkedvu { background-color:#D2E2FF; }
		}
		
		td, th {
			border-top: none;
			vertical-align: middle;

			@media (max-width: 767px) {
				padding: 10px 2px;
			}

			&.col_episode_num { 
				width: 48px; 
				
				@media (max-width: 767px) {
				    width: auto;
					padding-right: 7px;
				}
			
				.episode_number {
					font-weight: 600;
					font-size: 14px;
					padding: 0;

					@media (max-width: 767px) {
						font-size: 12px;
					}
				}
			}
			&.col_episode_addicted { 
				width: 16px; 

				> a {
					display:block;

					img {
						display:block;
						vertical-align: middle;
					}
				}
			}
			&.col_episode_title {
				line-height: 14px;

				> a {
					font-size: 14px;

					@media (max-width: 767px) {
						font-size: 12px;
					}
					
					.ep_nondiff & {
						color: #9a9a9a;
					}
				}
				
				span.fr {
					@media (max-width: 767px) {
						font-size: 10px;
					}
				}
			}
			&.col_episode_edit { 
				width: 64px;
				font-size: 14px;
				
				span.ep_id {
					font-size: 9px;
					color: #c3c3c3;
				}
			}
			&.col_episode_diffusion {
				width: 80px;
				line-height: 14px;
				
				@media (max-width: 768px){
					font-size: 10px;
					width: auto;
					padding: 10px 0px;
					padding-right: 10px;
				}
				
				@media (max-width: 380px){
					display: none;
				}
				
				.airtime {
					font-size: 11px;
					
					@media (max-width: 768px){
						display: none;
					}
				}
				
				.duree {
					font-size: 11px;
					
					@media (max-width: 768px){
						font-size: 10px;
					}
				}
			}
			&.col_episode_moyenne {
				position: relative;
				width: 65px;
				text-align: center;
				line-height: 14px;

				.note {
					display: inline-block;
					font-weight: bold;
					font-size: 18px;
					line-height: 14px;
				}

				.nbvotes {
					display: block;
					font-size: 11px;
					opacity: 0.8;
					/*position: absolute;*/
					/*bottom: 3px;*/
					/*width: 55px;*/
					//text-align: center;
				}
			}
			&.col_episode_note {
				width: 60px;
				text-align: center;

				.note {
					font-weight: bold;
					font-size: 18px;
				}
			}
			&.col_episode_comments_number {
				width: 60px;
				text-align: center;
			}
			&.col_episode_seendate {
				width:80px;
				font-size: 11px;
    			line-height: 12px;

				ul {
					margin: 0;
				}
			}
			&.col_episode_seenbutton { 
				width: 141px;
				padding: 0;
				text-align: right;
				
				.btn-poss {
					padding: 6px 8px;
				}
			}
		}
		&.comments_zone_line {
			>td {
				border: none;
			}
		}
	}
}

.btnViewing {
	display: inline-flex;
	width: 100px;
	height: 34px;
	margin: 0;
	margin-right: 6px;

	.btn {
		&.dropdown-toggle {
			padding: 6px 7px;
		}
		
		&:first-child {
			width: 60px;
			text-align: left;
		
			.number {
				float: right;
			}

			.fa-eye { display: none; }
			.fa-minus-circle { display: none; }
			.fa-eye-slash { display: inline; }
		}
	}

	&.btn-group .btn+.btn {
		margin-left: 0;
	}
	
	.input-group-btn-vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		width: 16px;
		margin-left: -1px;
		margin-right: -1px;
	
		> .btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 100%;
			height: 50%;
			padding: 0;
			position: relative;
			border-radius: 0;

			i{
				font-size: 0.6em;
				font-weight: 300;
			}
		}
	}
	
	.dropdown-menu {
		.check_vu { display: block; }
		.check_nv { display: none; }
		.check_ni { display: block; }
	}
	
	input.notinterested {
		display: none;
	}
	
	&.vu {
		.btn {
			color: #fff;
			background-color: $color_darkblue;
			border-color: #2e6da4;
			
			&:hover {
				background-color: $color_primary
				border-color: #204d74;
			}
			
			&:first-child {
				.fa-eye { display: inline; }
				.fa-minus-circle { display: none; }
				.fa-eye-slash { display: none; }
			}
		}
		
		.dropdown-menu {
			.check_vu { display: none; }
			.check_nv { display: block; }
			.check_ni { display: block; }
		}
	}
	
	&.disabled {
		.btn {
			/*color: #afafaf;
			background-color: #dedede;
			border-color: #bdbdbd;
			
			&:hover {
				background-color: #c5c5c5;
				border-color: #828282;
			}*/
			
			&:first-child {
				.fa-eye { display: none; }
				.fa-minus-circle { display: inline; }
				.fa-eye-slash { display: none; }
			}
		}
	}
	
	&.ni {
		.btn {
			color: #fff !important;
			background-color: #9a9a9a !important;
			border-color: #848484 !important;
			
			&:hover {
				background-color: #a2a2a2 !important;
				border-color: #848484 !important;
			}
			
			&:first-child {
				.fa-eye { display: none; }
				.fa-minus-circle { display: inline; }
				.fa-eye-slash { display: none; }
			}
		}
		
		.dropdown-menu {
			.check_vu { display: block; }
			.check_nv { display: block; }
			.check_ni { display: none; }
		}
	}
}

.table .comments_zone_line td {
    border: none;
}

#coch_all_season {
    float: right;
	
	.allseasons_txt {
		line-height: 34px;
		margin-right: 15px;
	}
	
	.possede_allseasons_wrapper {
		.btn {
			padding: 6px 8px;
		}
	}
}

.episode_vision {
    background: $color_gray;
    padding: 5px 10px;
    margin-bottom: 10px;
	
	.label_span {
		width: 100px;
		display: inline-block;
		padding-left: 5px;
	}
	
	.episode_edit_date {
		display: none;
		margin-top: 10px;
		
		.form-group {
			margin-bottom: 10px;
		}
		
		.datepickerepisode {
			width:100%;
		}
	}
}

.episode_vision_actions .fa {
    font-size: 16px;
    color: #333;
	
	&:hover {
		opacity: 0.8;
	}
}

.table_crew_most_used {
	h3 {
		margin-top: 0;
		margin-bottom:10px;
		font-size: 18px;
	}
	
	a {
		font-size: 16px;
	}
}

#accordion_crew, #accordion_audiences {
	.panel-heading {
		padding: 0;
		
		h4 {
			padding: 0;
			
			> a {
				display: block;
				padding: 10px 15px;
			}
		}
	}
}

#accordion_audiences_expand_all {
	span.down {
		display: inline;
	}
	span.up {
		display: none;
	}
	&.opened {
		span.down {
			display: none;
		}
		span.up {
			display: inline;
		}
	}
}

.row_temps_visionnage {
	.number_eps {
		// font-size: 40px;
		font-weight: 700;
		line-height: 30px;
	}
	
	.seen_time {
		font-size: 18px;
		font-weight: bold;
	}
}

#tvshow_synopsis {
	p.adaptation:nth-child(1) {
		margin-top: 10px;
	}
}
/******************************************************************************/
/**********                 Spécifique aux fiches épisodes          ***********/
/******************************************************************************/

.fancybox-container
	-webkit-overflow-scrolling: touch;

.fancybox-slide > .container_fiche_episode
	position: relative;
	padding: 0;
	height: 90vh;
	margin: 0;

	@media (max-width: 991px)
		width: 100%;
		height: 100vh;

	> .row
		margin: 0;

	.alert-danger-episodenotseen
		border-radius: 0;
		text-align: center;
		background: #d41411;
		color: #fff;

		.display_fiche_episode
			margin-top: 10px;
			border-radius: 0;
			display: block;
			white-space: normal;

.container_fiche_episode
	.fancybox-close-small
		top: 24px;
		right: 30px;
		width: 30px;
		height: 30px;
		border-radius: 100%;

		@media screen and (max-width: 480px)
			top: 6px;
		

		&:after
			top: 0;
			right: 0;

.ep_navigation
	position: absolute;
	top: 9px;
	right: 55px;
	width: 70px;

	@media screen and (max-width: 480px)
		top: 22px;
		right: 15px;

	.ep_nav
		display: inline-block;
		width: 30px;
		height: 30px;
		color: #777;
		font-size: 17px;
		line-height: 30px;
		text-align: center;
		border-radius: 100%;
		margin-left: 5px;
		background: #fff;

		&:hover
			color: #555;
			background: #eee;
		

		&.ep_nav_disabled
			color: #e0e0e0;
			border-color: #e0e0e0;

			&:hover
				color: #e0e0e0;
				background: #fff;

.fiche_episode
	position:relative;

	abbr[title]
		text-decoration: none;

	.row_episode_header
		background: #f1f1f1;
		padding: 15px;

		h1
			font-size: 20px;

			small
				font-size: 18px;

				&.titre_fr
					@media screen and (max-width: 767px)
						display: block;

			.label-info
				font-size: 11px;
				font-weight: 400;
				border-radius: 0;
				margin-left: 10px;
				padding: 2px;
				vertical-align: middle;

	.row_episode_content
		.episode_pic
			display: block;
			width:100%;
			margin-bottom: 15px;

	.row_episode_user
		margin-bottom: 15px;

		.average_circle_wrapper
			top: 25px;

	.col_episode_info
		p
			margin-top: 2px;

		.ep_synopsis
			font-style: italic;

	.note_graph
		margin-bottom: 30px;

		.bar
			background: rgba(0, 0, 0, 0);

			.bar_inner
				background: #333;
			

			.number
				display: block;
			

			&:nth-child(even)
				.number
					bottom: -20px;


[data-notify]
	z-index: 200000 !important;

.loadingAjaxEpisode
	position: absolute;
	top: 0;
	left : 0;
	width:100%;
	height:100%;
	background: red;
	z-index: 100;
	background: rgba(33, 33, 33, 0.76);
	display: none;

// http://tobiasahlin.com/spinkit/
.sk-cube-grid
	width: 40px;
	height: 40px;
	margin: 100px auto;

	.sk-cube
		width: 33%;
		height: 33%;
		background-color: #333;
		float: left;
		animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;

	.sk-cube1
		animation-delay: 0.2s; 
	.sk-cube2
		animation-delay: 0.3s; 
	.sk-cube3
		animation-delay: 0.4s; 
	.sk-cube4
		animation-delay: 0.1s; 
	.sk-cube5
		animation-delay: 0.2s; 
	.sk-cube6
		animation-delay: 0.3s; 
	.sk-cube7
		animation-delay: 0s; 
	.sk-cube8
		animation-delay: 0.1s; 
	.sk-cube9
		animation-delay: 0.2s; 

@keyframes sk-cubeGridScaleDelay
	0%, 70%, 100%
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	 35%
		  -webkit-transform: scale3D(0, 0, 1);
		  transform: scale3D(0, 0, 1);

.btnOrderCommentEpisodes
	> button
		padding: 2px 5px;
		margin-bottom: 5px;
	
	li
		span
			font-weight: bold;
			padding: 5px 10px;
			display: inline-block;
		
		a.current
			background: $color_darkblue;
			color: #fff;

.wrapper_episode_comments
	h2
		margin-bottom: 10px;
	
	
	.filter_pagination_episode
		margin: 10px 0;
		clear: both;
		
		.pagination_comments_episodes 
			margin: 0;

ul.comments_episodes
	margin-top: 15px;

	> li
		position: relative;
		padding: 5px 0 10px 70px;
		border-bottom: 1px solid #EEE;
		min-height: 70px;

		@media (max-width: 991px)
			padding: 5px 0;

		.avatar_member
			position: absolute;
			top: 5px;
			left: 5px;
			margin-right: 5px;
			border-radius: 100%;
			border: 3px solid #bbbdbb;

			@media (max-width: 991px)
				position: relative;
				float:left;
				margin-right: 10px;
				top: 0;
				left: 0;

			&.connected
				border-color: #56da32;

			img
				display: inline-block;
				border-radius: 100%;

		.date
			display: block;
			font-size: 10px;
			color: #999999;
			float: none;

			.seconds
				display: none;

		.commentateur
			font-family: 'Oswald', Arial, sans-serif;
			font-size: 22px;
			font-weight: normal;
			line-height: 20px;
			padding-bottom: 10px;

			@media (max-width: 991px)
				display: block;
				padding-top: 5px;

		.basic
			padding-bottom: 10px;

		.deleteepisode
			float: left;
			margin: 0;
			padding: 3px 5px;

.form_comment_episode
	.message
		width:100%;
		height: 40px;
		padding:0px;
		margin:0px;
		transition: all 300ms ease-in;
	
	
	.btnsubmit
		display: none;
	
	
	&.open
		.message
			height: 150px;
		
		
		.btnsubmit
			display: block;

.average_circle_wrapper
	position: relative;
	margin-bottom: 20px;

	.average_circle_txt
		position: absolute;
		top: 0;
		color: #000;
		font-weight: 300;
		padding: 0;
		width: 100%;
		text-align: center;

		.average_number
			display: inline-block;
			font-weight: 600;
			padding-top: 10px;
		

		.average_circle_votes
			display: block;
			font-size: 10px;
			position: absolute;
			width: 100%;

	.average_circle_episode
		width: 100%;

		canvas
			display: block;
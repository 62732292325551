body#dashboard_admin
    padding-bottom: 0

.waves-effect
    position: relative;
    z-index: 1;
    display: inline-block;
    overflow: hidden;
    user-select: none;
    transition: .3s ease-out;
    vertical-align: middle;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

.sidenav-main
    display: block;
    position: fixed;
    z-index: 999;
    top: 60px;
    width: 260px;
    height: 100%;
    transition: .3s ease all;

    @media (max-width 767px)
        width 60px

.brand-sidebar
    position: fixed;
    top: 0;
    left: 0;
    height: 64px;
    background: #fff;
    backface-visibility: hidden;

    .logo-wrapper
        font-size: 3rem;
        line-height: 17px!important;
        margin: 0;
        white-space: nowrap;

    .brand-logo
        font-size: 2.1rem;
        line-height: 0;
        position: absolute;
        display: inline-block;
        margin: 1px 0;
        padding: 22px 12px 22px 22px;
        color: #333;

.sidenav
    z-index: 999;
    top: 0;
    left: 0;
    overflow-y: auto;
    width: 260px;
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%);
    padding-bottom: 60px;
    -webkit-transform: translateX(-105%);
    -ms-transform: translateX(-105%);
    transform: translateX(-105%);
    background-color: #fff;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 64px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 65px;
    transition: auto!important;
    backface-visibility: hidden;

    &.sidenav-fixed 
        position: fixed;
        left: 0;
        transform: translateX(0);
        height: 100%;
        overflow: auto;

    li
        float: none;
        line-height: 44px;
        padding: 0;
        list-style-type: none;

        > a 
            display: block;
            font-size: 14px;
            font-weight: 400;
            height: 36px;
            line-height: 36px;
            color: rgba(0,0,0,.87);
            margin-right: 1.15rem;
            padding: 0 0 0 21px!important;
            transition: none;

            i
                font-size: 1.4rem;
                line-height: inherit;
                display: block;
                float: left;
                width: 24px;
                height: 36px;
                line-height: 36px;
                color: rgba(0,0,0,.54);
                margin: 0 20px 0 0;
            
            span.menu-title 
                visibility: visible;
                transition: opacity .2s linear;
                opacity: 1;

                @media (max-width 767px)
                    display none

            &.active 
                margin: 0;
                color: #fff;
                background: -webkit-linear-gradient(45deg,#303f9f,#7b1fa2);
                background: linear-gradient(45deg,#303f9f,#7b1fa2);
                box-shadow: 3px 3px 20px 0 rgba(123,31,162,0.5);
                padding: 0 0 0 21px!important;
                transition: none;
                margin-right: 1.15rem;
                border-radius: 0 5px 5px 0;

                @media (max-width: 767px)
                    margin-right 5px
                i  
                    color white
    
            &:hover 
                border-radius: 0 5px 5px 0;

            &:not(.active):hover
                background-color: rgba(0,0,0,.035)!important;

        
        &.menu-category
            @media (max-width: 767px)
                display none

            span
                font-size: 12px;
                font-weight: 400;
                line-height: 30px;
                height: 30px;
                margin: 0;
                padding 0 20px
                color: rgb(150 150 150);
                text-transform: uppercase;

.brand-sidebar, .sidenav
    width: inherit;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);

.admin_main
    padding-left: 260px;

    @media (max-width 767px)
        padding-left 60px

.admin_title_wrapper
    width: 100%;
    padding: 20px 0;
    margin 0
    background: 0 0
    background: -webkit-linear-gradient(45deg,#303f9f,#7b1fa2)!important;
    background: linear-gradient(45deg,#303f9f,#7b1fa2)!important;

    .admin-title
        font-size: 30px
        line-height: 30px
        color white
        padding 20px 0

.mm_pic
    position relative

    .mm_picture
        width: 100%
        height: 113px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;

    .mm_delete
        display block
        position absolute
        top 0
        right 10px

        img 
            display block

.dashboard_col_actions
    // position:fixed;
    // right:0;
    height: calc(100vh - 170px);
    background: #eaeaea;

    .our_actions
        height: calc(100vh - 225px);
        overflow-y: scroll;
        font-size: 12px;

body.darkmode
    .sidenav
        background $color_dark_background

        li  
            > a 
                color $color_dark_text

                i 
                    color $color_dark_text

    .dashboard_col_actions
        background $color_dark_tile
        color $color_dark_text
        color $color_dark_textlight

    .chart
        a
            color $color_dark_text

.unchecked
    background: linear-gradient(-45deg, #eee 20%, #fff 20%, #fff 30%, #eee 30%, #eee 70%, #fff 70%, #fff 80%, #eee 80%);
    background-size: 20px 20px;
    background-position: 25px 25px;
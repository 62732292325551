/**** Windows Phone 8 and Internet Explorer 10 Fix ****/
@viewport width: device-width;

html, body 
	font-family: 'Roboto', sans-serif;
	font-size: 13px;

html 
	height: 100%;

body 
	position: relative;
	top: 0px;
	left: 0px;
	min-height: 100%;
	background-color: #FAFAFA;
	overflow-y: scroll;
	padding-bottom: 170px;
	
	a 
		color: $color_blue;
		text-decoration: none;
		
		&:hover 
			color: #1e618e;
			text-decoration: none;
	
	&.betamode:after
		content: "beta";
		position: fixed;
		z-index: 10000;
		width: 80px;
		height: 25px;
		background: #c1356e;
		top: 7px;
		left: -20px;
		text-align: center;
		font-size: 13px;
		font-family: sans-serif;
		text-transform: uppercase;
		font-weight: bold;
		color: #fff;
		line-height: 27px;
		transform: rotate(-45deg);
	
	&.localmode:after
		content: "local";
		position: fixed;
		z-index: 10000;
		width: 80px;
		height: 25px;
		background: #5cb85c;
		top: 7px;
		left: -20px;
		text-align: center;
		font-size: 13px;
		font-family: sans-serif;
		text-transform: uppercase;
		font-weight: bold;
		color: #fff;
		line-height: 27px;
		transform: rotate(-45deg);

abbr[data-original-title], abbr[title] 
	text-decoration: none;

p 
	margin: 10px 0px;

h1,h2,h3,h4,h5 
	font-weight: bold;
	margin: 10px 0;

h2 
	font-size: 14px;

h3 
	font-size: 13px;

h4 
	font-size: 12px;

ul 
	list-style: none;
	padding: 0;

g, path 
	box-sizing: none;

button,input,optgroup,select,textarea 
	color: #333;

.hidden 
	display: none;

.clear 
	clear: both;

.verticalAlign 
	vertical-align: middle;

.table.valign-middle
	> thead,
	> tbody,
	> tfoot
		> tr
			> th, > td
				vertical-align: middle

form .submit 
	cursor: pointer;

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse
	max-height: 480px;

#blackveil 
	display: none;
	background-color: black;
	opacity: 0.3;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;

.colorpicker 
	z-index: 1500;

/* Expand inner */

.expand_wrapper
	overflow: hidden;
	position: relative;

.expand_inner .seemore,
.expand_inner .closeseemore 
	position: absolute;
	right: 50%;
	bottom: 0px;
	z-index: 10;
	margin-right: -50px;
	width: 100px;
	bottom: auto;
	top: 0px;
	right: 0;
	margin: 0;
	color: #333 !important;


[data-notify="progressbar"] 
	margin-bottom: 0px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 5px;

.bg-white
	background white

.-radius
	border-radius 100%

.-gray-light
	color #4a4a4a

.select2-container .select2-choice
	height 32px
	line-height 34px

.table-striped>tbody>tr:nth-of-type(odd)
	background-color: #f3f3f3;

.material-switch > input[type="checkbox"] {
    display: none;   
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative; 
    width: 40px;  
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

.tooltip-inner {
	max-width: 300px;
	min-width: 100px;
}
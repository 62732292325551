/* Global */
#punwrap {margin: 0px; }
.pun { color: #333; padding:0px; }

/* Login */
#loginForm h2, #registerForm h2, #request_pass h2 { margin:10px 0px; }

/* Pour les titres de sections */
.section_forum_hideshow { position:absolute; top:20px; right:380px; } /* pour cacher ou non les sections sur la page d'accueil */
.section_forum_hideshow a { color:#FFF; }

/* Chatbox*/
#chatbox_wrapper { position:relative; }
#chatbox_wrapper #loading { position:relative; }
#chatbox_wrapper #autoscroll { position:relative;  }
#chatbox_wrapper .divshout { margin:5px; font-size: 12px; }
#chatbox { background: #F3F3F4;color: black; border: 1px solid #DBDBDB;  }
#chatbox_content { margin:0px 10px; }

/* Discussions récentes */
.recent_talks .inbox{ position:relative; height:100px; overflow:hidden;}
.recent_talks ul{ position:relative; top:0px; left:0px; }
.recent_talks ul li {clear:left;}
.recent_talks ul li div{ height:20px; white-space:nowrap; overflow:hidden; float:left; }
.recent_talks ul li div.subject { width:480px; }
.recent_talks ul li div.forum { width:245px; }
.recent_talks ul li div.last_post { width:235px; }

/* Index forum - tableau sections */
.forum_category {
	margin-bottom: 6px;
	
	.forum_cat_header { 
		position:relative; 
	}
	
	table {
		tr {
			h3 {
				margin: 0;
			}
			
			&:hover {
				background-color:#EBF5FB;
			}
		}
	}
}

.isticky { font-weight:bold;  background: #D0EAFF; }

/* Icones du forum */
.icon 			{ background-image : url(../../../images/forum/icon_normal.png); }  /* Sujet normal */
.inew .icon, .inew.icon 		{ background-image : url(../../../images/forum/icon_new.png); } /* Nouveau sujet */

.iclosed .icon 	{ background-image : url(../../../images/forum/icon_normal_closed.png); } /* Sujet ferme */
.iclosed .icon.inew 	{ background-image : url(../../../images/forum/icon_new_closed.png); } /* Sujet ferme */
.iredirect .icon { background-image : url(../../../images/forum/icon_normal.png);} /* Redirection */
.isticky .icon 	{ background-image : url(../../../images/forum/icon_normal_sticky.png); } /* Sujet epingle */
.isticky .icon.inew 	{ background-image : url(../../../images/forum/icon_new_sticky.png); } /* Sujet epingle */
.imoved .icon 	{ background-image : url(../../../images/forum/icon_normal.png); } /* Sujet deplace */

/* Pagination */
.pag_forum { 
	margin-left:15px; 
	
	a { 
		display:inline-block;
		font-size:20px;
		padding: 5px;
		border: 1px solid #E0E0E0;
		
		&.current, &:hover { 
			background: #E0E0EE; 
			border: 1px solid; 
			text-decoration:none; 
		}
	}
}

/* Viewtopic */
.firstpost { border-top: 1px solid #E0E0E0;  }
.blockpost { border-bottom: 1px solid #E0E0E0;/*  margin: 10px 0px; */ padding: 10px 0px; margin-bottom: 6px; }

/* Post */
.rowodd, .roweven { border-bottom: 1px solid #E0E0E0; }
#posterror { margin-top: 15px; }

/* Options*/
#menu_options li{ padding: 10px; margin: 10px 5px 10px 0px; }
#menu_options li.isactive { font-weight:bold; }
#menu_options li.isactive, #menu_options li:hover{ background: white;-webkit-border-radius: 10px;-moz-border-radius: 10px;border-radius: 10px; }

/* 2.1 clearing floats and invisible items */
.pun .clearer, .pun .nosize { height:0px; with:0px; line-height:0px; font-size:0; overflow:hidden; }
.pun .clearer, .pun .clearb { clear:both; }
.pun .nosize { position: absolute; left: -10000px; }
/* 2.2 overflow settings for posts */
.blockpost .box, .postleft, .postsignature, .postmsg { overflow: hidden; }
/* 3.1 this attempts to eliminate rounding errors in gecko browsers. */
div>div>div.postfootleft, div>div>div.postfootright {padding-top: 1px; margin-top: -8px}
/* 3.2 this is only visible to ie6 windows and cures various bugs. do not alter comments */
/* begin ie6win fix \*/
* html .inbox, * html .inform, * html .pun, * html .intd, * html .tclcon {height: 1px}
* html .inbox div.postmsg {width: 98%}
/* end of ie6win fix */
/* 3.3 this is the equivelant of 3.2 but for ie7. it is visible to other browsers but does no harm */
/*begin ie7win fix */
.pun, .pun .inbox, .pun .inform, .pun .intd, .pun .tclcon {min-height: 1px}
/* end of ie7win fix */
/* these are hidden in normal display. add comments to make them visible */
#brdfooter h2, #brdstats h2, #brdstats .conl dt, #brdstats .conr dt, #modcontrols dt, #searchlinks dt, div.postright h3
{ position: absolute;	display: block;	overflow: hidden;	width: 1em;	left: -999em }
/* 5.1. setup all left and right content using floats. */  
.conr {	float: right;	text-align: right;	clear: right;	width: 40%; margin: 0px; }
.conl {	
	float: left;
	width: 55%;
	overflow: hidden;
	white-space: nowrap;

	@media (max-width: 767px){
		width: 100%;
	}
}
label.conl {	width: auto;	overflow: visible;	margin-right: 10px}
/* 5.2 set up page numbering and posts links */
/* div.linkst .conl, div.linksb .conl, div.postlinksb .conl {width:18em}
div.linkst .conr, div.linksb .conr, div.postlinksb .conr {width:16em} */
form div.linksb .conr {width: 32em}
/* 5.3 keep breadcrumbs from shifting to the right when wrapping */
.linksb ul, .postlinksb ul {margin-left: 18em}
/* 5.4 settings for profile and admin interface.*/
div.block2col {padding-bottom: 1px}
div.block2col div.blockform, div.block2col div.block, #viewprofile dd {margin-left: 14em}
#profileavatar img { float: right; margin-left: 1em }
#viewprofile dl {float: left; width: 100%; overflow: hidden}
/****************************************************************/
/* 6. table setup */
/****************************************************************/
/* 6.1 table basic setup */
.pun table {width: 100%}
/* 6.2 fixed table setup */
#punindex table, #vf table {table-layout: fixed}
.tcl {text-align: left; width: 50%}
.tc2, .tc3, .tcmod {width: 8%; text-align: center}
.tcr {width: 20%; text-align: right}
#punsearch #vf .tcl, #punmoderate #vf .tcl {width: 41%}
#punsearch #vf .tc2 {width: 18%; text-align: left}
.tcl, .tcr {overflow: hidden}
/* 6.3 other table setup */
#users1 .tcl {width: 40%}
#users1 .tcr {width: 25%}
#users1 .tc2 {width: 25%; text-align: left}
#users1 .tc3 {width: 10%; text-align: center}
#debug .tcr {width: 85%; white-space: normal}
#punindex td.tcr span.byuser {display: block}
/****************************************************************/
/* 7. viewtopic setup */
/****************************************************************/
/* 7.1 this is the basic structure. */
div.postleft, div.postfootleft {	float:left;	width: 18em;	overflow: hidden;	position: relative;}
div.postright, div.postfootright {	margin-left: 18em;	border-left: 3px solid #DFDFDF; }
div.postfootright, p.multidelete {text-align: right}
div.blockpost>div>div.inbox {padding-bottom: 1px}
/* 7.3 this is the div which actually contains the post and is inside .postright */
div.postmsg {width:100%; line-height: 20px; }
/* 7.4 these items control overflow and scrolling within posts. */
div.incqbox {width: 100%; overflow: hidden}
div.scrollbox {width: 100%; overflow: auto}
img.postimg {max-width: 100%}
a .postimg {max-width: 100%}
/* 7.5 turn off the poster information column for preview */
#postpreview div.postright {border-left: none}
/* 7.6 create the horizontal line above signatures */
div.postsignature hr {margin-left: 0px;	width: 200px;	text-align: left;	height: 1px;	border:none}
/* 7.7 maximum height for search results as posts. position go to post link */
div.searchposts div.postmsg {height: 8em}
div.searchposts dd p {padding-top: 3em}
/* 7.8 class for bbcode [u] */
span.bbu {text-decoration: underline}
/* 8.1 horizontal display of online list, main navigation menu and breadcrumbs */
#onlinelist dd, #onlinelist dt, #birthdatelist dd, #birthdatelist dt, #onlinetodaylist dd, #onlinetodaylist dt, #brdmenu li, div.linkst li, div.linksb li, div.postlinksb li, div.postfootright li, ul.bblinks li { display: inline; height: 0 }
/* 8.2 turn on square icon for posterror list */
#posterror ul li {list-style: square inside}
/* 8.3 right alignment of descriptions in ordinary member view of other members profiles */
#viewprofile dt {text-align: right}
/* 9.1 makes textareas and long text inputs shrink with page */
div.txtarea textarea, input.longinput {width: 100%;max-width:100%; padding: 0px; margin: 0px;font-weight: normal;}
/*.pun label {display: block}*/
#qjump select {width: 50%}
/* 10.2 internal padding */
#helpfile div.box {padding: 10px}
/* 10.3 other templates */
#punredirect div.block, #punmaint div.block {margin: 50px 20% 12px 20%}
.tclcon p {display: none; }
div#mp_bar_ext {width: 100px;height: 10px;text-align: left;}
div#mp_bar_int { height: 10px; }
#footer_contener li {text-indent : 0px; }
 /* font align for footer */
#brdfooter div.box div.inbox span.go_to_top {text-align: center;} /* center go to top */
#brdmenu  { height: 29px;line-height: 29px;}
#brdwelcome, #brdmenu {  border:none; }
/* bouton ----------- factice button post and reply */
.pun p.postlink, #conr { text-align: right; position: relative; top : 0px; left: 6px; font-size:1.5em; }
/* iewin font size only - to allow iewin to zoom. do not remove comments \*/ * html .pun {font-size: 68.75%} /* end ie win font size */
.pun pre {font-family: monaco, "bitstream vera sans mono", "courier new", courier, monospace}
.pun h4 {font-size: 1.2em; }
.pun h3 {font-size: 1.3em}
#brdtitle h1 {font-size: 1.4em}
div.postmsg p {line-height: 1.4}
div.postleft dt {font-size: 1.1em}
.pun pre {font-size: 1.2em}
div.postleft dt, div.postmsg h4, td.tcl h3, div.forminfo h3, p.postlink, div.linkst li,
div.linksb li, div.postlinksb li, .blockmenu li, #brdtitle h1, .pun span.warntext, .pun p.warntext {font-weight: bold}
.pun td, .pun th { border-bottom: none; border-right: none }
.pun .tcl {border-left: none}
div>fieldset { border-style: solid; border-width: 1px }
div.block2col div.blockform, div.block2col div.block, div.block#brdfooter {margin-bottom: 0px}
div.linksb {margin-top: -12px}
div.postlinksb {margin-top: -6px}
#brdstats {margin-top: 12px}
div.block div.inbox, div.blockmenu div.inbox {padding: 3px 6px}
.pun ul, .pun dl, div.blockmenu li, #announce div.inbox div {padding: 3px 0}
#brdtitle p {padding-top: 0px}
div.linksb, div.postlinksb {padding: 3px 6px 8px 6px}
#brdwelcome, #brdfooter dl a, div.blockmenu li, div.rbox input  {line-height: 1.4em}
#viewprofile dt, #viewprofile dd {padding: 0 3px; line-height: 2em}
#brdmenu li, div.rbox input, div.blockform p input  { padding-left:5px; padding-right:5px;}
#brdmenu { padding: 0px; }
#brdmenu ul { padding: 0px; }
.pun th, .pun td {padding: 4px 6px}
.pun td p {padding: 5px 0 0 0}
div.postleft dl, div.postright {padding: 6px}
dd.usercontacts, dd.postavatar {margin-top: 5px}
dd.postavatar {margin-bottom: 5px; }
dd.postavatar, dd.postavatar img { width: 100px; height: 100px; }
div.postsignature {padding-top: 15px}
div.postmsg .postedit { padding-top: 15px; color:#999; }
div.postmsg h4 {margin-bottom: 10px}

.pun blockquote, div.codebox {margin: 5px 0px 15px 15px; padding: 8px; border-left: 5px solid #ACD3EE; background: #D6EAF6; font-size: 13px; }
.pun blockquote blockquote { background:#e4f1f9;  border-left: 5px solid #90c4e8; }
.pun blockquote blockquote blockquote { background:#F2F8FC;  border-left: 5px solid #82BDE5; }
div.postfootleft p, div.postfootright ul, div.postfootright div {padding: 2px}
div.blockpost input, div.blockpost label { padding: 3px; display: inline }
p.multidelete { padding-top: 15px; padding-bottom: 5px }
div.postmsg p {padding: 0}
div.inform {padding-bottom: 12px}
.pun fieldset {padding: 0px 12px 0px 12px}
div.infldset {padding: 9px 0px 12px 0}
.pun legend {padding: 0px 6px; font-weight: bold;border: none;    margin-bottom: 0;}
div.forminfo { margin-bottom: 12px; padding: 9px 10px }
ul.bblinks li {padding-right: 20px}
ul.bblinks {padding-bottom: 10px; padding-left: 4px}
div.icon { float: left; display: block; width:32px; height:32px; margin-right:10px; text-indent: -9900px; }
div.searchposts div.icon {margin-left: 0}
td div.tclcon {margin-left: 2.3em}
p.poll_info { text-align: center; clear: left; margin: 7px; }
div.poll_question { float: left; clear: both; text-align: right; width: 50%; margin: 0.3em; height: 1.2em; }
div.poll_result,div.poll_result_yesno {float: left;text-align: left;width: 45%;margin: 0.3em;padding: 0;height: 1.2em;line-height: 1.2em;}
div.poll_result_yesno { width: 22%; margin-right: 0.1em; }
img.poll_bar { height: 1.2em; margin: 0; background:#58A7DC; }
div.linkst { margin:10px auto; padding-top: 10px; }

/* Home */
strong.itsme { color:red;}
strong.friend { color:#234257;}

@media screen and (max-width: 768px)
{	
	div.postleft, div.postfootleft { float:none; width: 100%; }
	div.postright, div.postfootright { margin: 0; border:none; }
	
	#message .postleft dl { margin-bottom: 10px; }
	#message .postleft dd { display: none; }
	div.postleft dl, div.postright { padding: 0; }
}

#smilies{
	img {
		max-width: 24px;
		margin-right: 2px;
	}
}
footer
	background-color: #1A1A1A;
	font-family: 'Oswald', Verdana, sans-serif;
	font-size: 20px;
	font-weight: 300;
	margin-top: 50px;
	position: absolute;
	width: 100%;
	bottom: 0px;
	height: 170px;
	overflow: hidden;
	color: #FFF;
	clear: both;
	padding-top 20px
	
	a
		color: #FFF;

	h4
		// font-family: 'Roboto',sans-serif;
		// font-size: 15px;
		// font-weight: 600;
		font-family: 'Oswald',sans-serif;
		font-size: 18px;
		font-weight: 300;
		margin: 0;
		margin-top: 14px;
		margin-bottom: 5px;

	
	/**** Menu Contact - Facebook - Twitter ****/
	.footer_menu 
		margin: 8px 0px;

		> li 
			padding 0
			padding-right 10px

			> a
				font-family: 'Roboto',sans-serif;
				color: #9e9e9e;
				font-weight: 500;
				font-size: 12px;

				&:hover
					color white
					transition all 200ms ease-in-out

	.footer_socials
		display flex
		justify-content: center;
		align-items: center;
		margin: 8px 0px;
		margin-bottom: 0;

		.social_icon
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			text-align: center;
			border-radius: 100%;
			transition: all 300ms ease-in-out;
			
			&:hover
				color: white;
				transform: scale(1.2);
			
			&.icon_facebook
				background: #3b5998;
			
			&.icon_instagram
				background: linear-gradient(#400080, rgba(0, 0, 0, 0)), linear-gradient(200deg, #d047d1, #f00, #ff0);

			&.icon_threads
				background white

				img 
					width 16px

			&.icon_bluesky
				background url(../../images/global/icon_bluesky.webp) no-repeat 50% 50%;
				background-size: cover;

				img 
					width 16px
					position: relative;
					top: -1px;

			&.icon_discord
				// background #404eed;
				background #5865f2;

				img 
					width 16px
					position: relative;
					top: -1px;
			
			&.icon_twitter
				// background: #00abdd;
				background: white

				img 
					width 16px

	/*** Menu news ****/
	#footer_news
		font-size: 13px;
		font-family: Roboto;

		h3
			font-family: 'Oswald', Verdana, sans-serif;
			font-size: 20px;
			font-weight: 300;

		.news
			margin: 10px 0;

/**** Pour les devs ****/
#time_to_load
	position: fixed;
	top: 0px;
	right: 30px;
	padding: 10px;
	margin: 0px;
	color: white;
	height: 41px;
	line-height: 20px;
	z-index: 9990000;

	@media screen and (max-width: 890px)
		display: none;

/**** Submit a ticket *****/
#submitTicket
	position: fixed;
	bottom: 0;
	right: 0;
	margin-bottom: 0px;
	padding: 0;
	z-index: 1000;
	
	@media screen and (max-width: 991px)
		right: 15px;

	a
		color: white;
		display: block;
		float: left;
		padding: 10px;
		background: #292929;
		
		
		@media screen and (max-width: 768px)
			background: #292929 !important;
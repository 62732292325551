ul.menu_horizontal.calendar_filter
	margin-right: 10px
	float: left
	padding: 10px 0px

.calendar_filter
	color: white
	
	> li
		background-color: #333
		min-height: 34px
		
		&.current
			background-color: $color_blue
		
		&:first-child
			border-radius: 5px 0px 0px 5px
			
			input[type=text]
				border: none
				width: 30px
				margin: 7px
		
		&.last, &:last-child
			border-radius: 0px 5px 5px 0px
			
			select
				border: none
				width: 130px
				margin: 8px

		.chosen-container
			height: 100%
			padding: 5px

			&.chosen-container-single
				.chosen-single
					display: flex
					align-items: center
					height: 100%
					border: none
					border-radius: 0

			&.chosen-container-multi .chosen-choices
				height: 100%

		.select23
			background-color: #333

			.select2-choices
				background: #333
				border none

				.select2-search-field 
					input
						color white

		.genre_shows.select23
			width 580px

			@media (max-width 620px)
				width 300px
		
		strong
			padding: 8px
			display: inline-block
		
		> a
			padding: 8px
			display: inline-block
			color: white
			
			&:hover
				color: #CACACA
			
			&:focus
				text-decoration: none

.calendar_filter_wrapper
	.btn-group
		height 34px

		.btn-default
			background-color: #333;
			color: white
			font-size: 13px
			border: none
			height: 100%
			display: flex
			justify-content: center
			align-items: center

			&:focus, &.focus
				box-shadow: none
				outline: none

			&.active
				background: $color_blue
				color: white

	.calendar_filter_select
		position relative
		height 34px

		.select2-container 
			.select2-choice
				background: #333
				color: white !important
				border: none
				box-shadow: none
				border-color #333

				.select2-arrow
					background: #333

// .select2-drop.dropdown-black, .dropdown-black .select2-search input
// 	background: #333
// 	color: white

.calendar_nav
	margin: 5px auto
	width: 900px
	
	li
		width: 300px
		text-align: center
		
		&.calendar_nav_previous
			text-align: right

		&.calendar_nav_next
			text-align: left
		
		h2
			font-size: 24px
			font-weight: bold

#calendar
	border-collapse: collapse
	margin-bottom: 0px
	width: 100%
	
	th
		font-weight: bold
		padding: 10px 0px

	td
		width: 14%
		padding: 5px
		font-size: 11px
		min-height: 100px
		vertical-align: top
		
		&.today
			background-color: #B3D7EF
		
		&.past
			background-color: #F2F2F2

p.calendar_day
	text-align: right
	font-size: 14px
	font-weight: bold
	background: #89C1E6
	margin: 0px
	padding: 5px 10px

.seasonpremiere
	color: $color_seasonpremiere

.seasonfinal
	color: $color_seasonfinale

.midseasonpremiere
	color: $color_midseasonpremiere

.midseasonfinale
	color: $color_midseasonfinale

ul.calendar_episodes
	li
		position: relative
		padding: 5px 0px
		border-bottom: 2px solid silver

		.multieps
			position: absolute
			top: 5px
			right: 0
			background: #858585
			color: white !important
			padding: 0
			border-radius: 100%
			width: 20px
			height: 20px
			display: flex
			align-items: center
			justify-content: center
			font-size: 10px
			text-decoration: none
			transition all 200ms ease-out

			&:hover
				background #595959
				text-decoration: none
	
		a.ep_title
			display inline-block
			padding-right: 22px;

			&.ep_sport
				color #48bd8f

		a.chaine
			color #333
			transition all 200ms ease-out

			&:hover
				color #277eb8
		
		&.vu
			&:before
				content: ''
				display: block
				background: #1f1f1f
				width: 100%
				height: 4px
				position: absolute
				top: 20px
				left: 0
				transform: rotate(10deg) skewX(-50deg)
		
		&.seasonpremiere
			a
				color: $color_seasonpremiere
		
		&.seasonfinal
			a
				color: $color_seasonfinale
		
		&.midseasonpremiere
			a
				color: $color_midseasonpremiere
		
		&.midseasonfinale
			a
				color: $color_midseasonfinale

span.calendar_number_episode
	font-size: 9px

span.calendar_title_episode
	font-size: 9px
.nomines_year
	.nomine
		border: 4px solid #EBEBEB;
		font-size: 15px;
		font-weight: bold;
		position:relative;

		&.nomine_vu
			border-color: #3570C2 !important; 
			background: #EDF1F7;

		&.nomine_wish
			border-color: $color_wish !important;
			background: rgba(213, 0, 94, 0.1);

	&.winners_0 
		li.nomine_modulo3
			margin-right: 0;

	&.winners_1 
		li.nomine_modulo_winners_1
			margin-right: 0;

@media (min-width: 767px)
	.nomine_winner
		.nomine_name
			a 
				font-size 30px

.nomine_pic
	display block
	background-repeat no-repeat
	background-position 50% 50%
	background-size cover

	&:after
		content: "";
		display: block;
		padding-bottom: 100%;

	&.nomine_pic_show
		&:after
			padding-bottom: 50%;

	&.nomine_pic_movie
		&:after
			padding-bottom: 130%;

.award_summary
	h2
		margin-bottom:15px;

	li
		padding:5px 0;

	.listing_award_years 
		li
			padding:5px 15px;

.table-striped
	> tbody
		> tr.admin_nomine.nomine_win
			background: yellow;
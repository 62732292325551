.btn-square
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
    overflow hidden

    i.fa 
        font-size: 25px

    span.wiki_lang
        position: absolute;
        bottom: 0;
        right: 3px;
        font-size: 10px;

    &:hover
        box-shadow 0px 0 0 3px black

    &.-square-medium
        width: 70px;
        height: 70px;

        i.fa 
            font-size: 32px

    &.-gray
        background #d6d6d6
        color #333333

    &.-imdb
        background #101010
        color #f5c518

    &.-twitter
        background #1DA1F2
        color white

    &.-instagram
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        color white

    &.-wikipedia
        position relative
        background white
        color black

    &.-website
        background #d2d2d2
        color black

        i.fa    
            font-size 20px

    &.-allocine
        img 
            width 100%

    &.-tmdb
        img 
            width 100%

    &.-tvmaze
        background #3f3f3f

        img 
            width 80%

    &.-tvdb
        background #293a36

        img 
            width 80%

    &.-youtube
        position relative
        background #0f0f0f
        color #ff0000

    &.-justwatch
        position relative
        background #0f0f0f
        color: white

        img 
            width 60%

        span 
            color #eac427

    &.-mydramalist
        position relative
        background #0c619a
        color: white

        img 
            width 80%

    &:hover, &:focus
        text-decoration: none

.btn-link
    &.-gray
        color: #a9a9a9;
        font-size: 12px;
#sbox_report 
	position: relative;
	margin-top:15px;
	margin-bottom:15px;
	
	.col_report_avatar 
		img.avatar 
			width: 100%;
			border-radius: 100%;
	
	.col_report_sections 
		position: relative;
		line-height: 15px;

		h2 
			font-weight: 300;
			font-size: 23px;
			margin-bottom: 5px;
			border-bottom: 1px solid silver;
			padding-bottom: 5px;
			line-height: 24px;
			margin-top: 0px;
			font-family: 'Oswald', sans-serif;

			.firstname 
				font-size: 10px;
				font-weight: normal;

		.sexe 
			margin: 0px 5px;
			width: 16px;
			height: 16px;

		span.level 
			font-weight: bold;
			color: silver;
			position: absolute;
			right: 0;
			top: 4px;
		
		#report_menu 
			margin-bottom: 10px;

			li 
				a 
					font-size: 16px;
					padding: 7px 8px;

		.report_section
			padding: 5px 0px;
			display: none;
			clear: both;

			&.active
				display: block;

		.timelost 
			padding-top: 7px;

			span.num 
				display: inline-block;
				width: 32px;
				text-align: center;
				font-weight: bold;
				font-size: 1.3em;
				background-color: white;
				padding: 5px 0px;
				border-radius: 3px;
			

			span.empty 
				color: silver;

		.infoLevel .arrow 
			background: url(../../images/design/tooltip_arrow_up.png) no-repeat 0% 50%;
			top: -12px;
			right: 20px;

@media only screen and (min-width: 200px) and (max-width: 640px)
	#report_sections .section ul li 
		float: none;
		width: 100%;
		padding: 2% 0px;
    
	#report_sections .section ul li + li 
		border-left: 0px;
		padding-left: 0px;

	#report_sections .section ul li strong 
		display: inline;

	#report_sections .timelost span.num 
		display: inline;
		background-color: transparent;
		line-height: 24px;
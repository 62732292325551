header.navbar.navbar-inverse {
	background: #333333;
	background-image: linear-gradient(to bottom, #333333 0%, #222222 100%);
	border: none;
	min-height: 60px;
	margin-bottom: 10px;
	overflow: visible;

	.container {
		@media screen and (min-width: 768px) and (max-width: 991px) {
			padding: 0;
		}
	}
	
	a {
		color: white;
	}
	
	.navbar-collapse-inner {
		@media (max-width: 767px) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	/**** Mobile Burger *****/
	.navbar-toggle {
		margin: 10px;
		border: none;
		background-color: #3D3D3D;

		&:hover, &:focus {
			background-color: #3D3D3D;
		}

		.icon-bar {
			border-radius: 0;
			height: 3px;
			width: 30px;
		}

		.icon-bar+.icon-bar {
			margin-top: 6px;
		}
	}

	/**** Menu *****/
	.nav.navbar-nav{
		@media (max-width: 767px) {
			margin: 0;
		}
		
		> li {
			> a {
				font-family: 'Oswald',Arial,sans-serif;
				font-size: 14px;
				font-weight: 400;
				color: #b4b4b4;
				text-transform: uppercase;
				letter-spacing: 0.6px;

				@media screen and (max-width: 1200px) {
					padding-left: 10px;
					padding-right: 10px;
				}
				@media screen and (min-width: 768px) {
					padding-top: 20px;
					padding-bottom: 20px;
				}

				&#menu_language {
					line-height: 16px;
					height: 60px;
				}
				
				&#go_connexion {
					background: #00A3FF;
				}
				
				&#dropdown-sbox-user {
					padding: 18px 10px;
					line-height: 20px;
					
					.avatar {
						margin-right: 5px;
					}
				}
				
				.caret, &:hover .caret {
					border-top-color: #FFF;
					border-bottom-color: #FFF;
					
					@media (max-width: 767px) {
						float: right;
						margin-top: 8px;
					}
				}
				
				&.dropdown-toggle {
					&.mp_highlights {
						background-color: #9E0519;
					}
				}

				&:hover, &:focus {
					background: #3A3A3A;
					color: #ffffff;
					text-decoration: none;
				}
			}
			
			&.current {
				&:after {
					content: '';
					position: absolute;
					bottom: 0px;
					left: 0%;
					width: 100%;
					height: 5px;
					margin-left: 0px;
					background-color: $color_darkblue
					width: 100%;
					
					@media (max-width: 767px) {
						display: none;
					}
				}
				
				> a {
					background-color: #222;
					background-color: rgba(0, 0, 0, 0.5);
					color: white;
				}
			}
			
			&.open {
				> .dropdown-menu {
					@media (max-width: 767px) {
						display: block;
					}
				}
			}
			
			&#menu_awards {
				> a {
					color: #bc891b;
				}
				
				&.current {
					&:after {
						background-color: #bc891b;
					}
				}
			}
			
			ul.dropdown-menu {
				background-color: #333;
				box-shadow: none;
				border-radius: 0px;
				border: none;
				
				@media (max-width: 767px) {
					position: static;
					top: auto;
					left: auto;
					display: none;
					float: none;
					max-width: none;
					padding: 0;
					margin: 0 15px;
					background-color: transparent;
					border: none;
					border-radius: 0;
					box-shadow: none;
				}
				
				&:before, &:after {
					@media (max-width: 767px) {
						display: none;
					}
				}
				
				li {
					float: none;
					display: block;
					background-color: transparent;
					
					+ li a {
						@media (max-width: 767px) {
							margin-bottom: 2px;
						}
					}

					&.divider {
						height: 1px;
						margin: 5px 0;
						overflow: hidden;
						background-color: #eee;
						border-bottom: 1px solid #ffffff;
						background-color: #222;
						border-color: #444;
						
						@media (max-width: 767px) {
							display: none;
						}
					}
					
					&.mp_highlights {
						background-color: #9E0519;
					}
					
					a {
						display: block;
						padding: 4px 15px;
						clear: both;
						font-weight: normal;
						line-height: 18px;
						color: #808080;
						text-shadow: 0 1px 0 #ffffff;
						color: #999;
						text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);

						@media (max-width: 767px) {
							padding: 9px 15px;
							font-weight: bold;
							color: #777777;
							border-radius: 3px;
						}
						
						&:hover, &:focus {
							text-decoration: none;
							color: #FFFFFF;
							background-color: #191919;
							background-image: linear-gradient(to bottom, #292929, #191919);
							box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.025), inset 0 -1px rgba(0, 0, 0, 0.025);

							@media (max-width: 767px) {
								background-color: #f2f2f2;
							}
						}
						
						&.btn {
							@media (max-width: 767px) {
								padding: 4px 10px 4px;
								font-weight: normal;
								border-radius: 4px;
							}
						}
					}
					
					&.current a {
						color: #ffffff;
					}
				}
			}
		}
	}

	/**** Logo *****/
	.navbar-brand {
		padding: 0;
		padding-right: 20px;
		padding-top: 0px;
		margin-left: 0px !important;
		
		@media (max-width: 767px) {
			padding-left: 15px;
		}
	}
	
	/**** Research form *****/
	.navbar-form {
		position: relative;
		background: url('../../images/design/icons/toolbar_find2.png') no-repeat 96% 50%;
		padding: 0;
		margin: 0;
		margin-right: 10px;
		margin-top: 14px;
		border: none;
		
		@media screen and (max-width: 991px) {
			width: 100%;
			margin-bottom: 10px;
			margin-right: 0;
			margin-top: 5px;
		}
		
		@media (max-width: 767px) {
			order: -1;
		}
		
		input {
			background: #3D3D3D;
			line-height: 14px;
			padding: 7px 10px;
			color: #F0F0F0;
			border: 1px solid #111;
			height: 36px;
			transition: all 200ms ease-in;
			border-radius: 0;
			
			@media screen and (min-width: 1280px) {
				width: 300px;
			}
			
			@media screen and (max-width: 991px) {
				width: 100%;
				padding: 10px;
			}
			
			::placeholder {
				color: #808080;
				text-transform: uppercase;
			}
			
			&:hover, :focus, .focused {
				outline: 0;
				background-color: #585858;
				color: #FFF;
			}
		}

		button.submit {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: 1px;
			top: 1px;
			border: 0;
			padding: 6px 14px;
			width: 34px;
			height: 34px;
			background: #3D3D3D;
			color: #808080;

			i.fa-search {
				display: block;
			}

			i.fa-circle-o-notch {
				display: none;
				animation: rotating 2s linear infinite;
			}
			
			&:hover, :focus, .focused {
				outline: 0;
				color: #FFF;
			}
		}

		&.easy-autocomplete-loading {
			button.submit {
				i.fa-search {
					display: none;
				}

				i.fa-circle-o-notch {
					display: block;
				}
			}
		}
	}
	
	/**** Menu droite ****/
	
	.navbar-right {
		@media (min-width: 768px) {
			float: right!important;
			margin-right: 0px;
		}
	}
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.easy-autocomplete {
	a.search_auto_link {
		color: #333 !important;
		line-height: 14px;
		font-size: 12px;
		padding: 5px 0;

		.item_type {
			text-transform: uppercase;
			font-size: 10px;
			color: #000;
			font-weight: 600;
			margin-left: 10px;
			float: right;
		}

		.search_auto_pic {
			display: block;
			width: 50px;
			height:50px;
			margin-right: 10px;
			float: left;
			background-position: 50% 50%;
			background-size: cover;
		}

		.surlignage {
			background: transparent;
			font-weight: bold;
		}
	}

	&.eac-dark {
		a.search_auto_link {
			color: #a5a5a5;

			.item_type {
			}

			.surlignage {
				color: #88aec7;
			}
		}
	}
}

.easy-autocomplete-container ul {
	max-height: 550px;
	overflow: auto;
}

.switch
	position relative
	display block
	background gray
	width 20px
	height 10px
	border-radius 10px
	float right

	.on
		display block
		background black
		width 10px
		height 100%
		border-radius 10px
		position absolute
		top 0
		left 0
		transform translateX(0px)
		transition all 300ms ease-in-out

	&.active
		.on
			background green
			transform translateX(10px)

.menu_dark
	padding 4px 15px
	display flex!important
	justify-content space-between
	align-items center
	color #999

	&:hover
		cursor pointer

	.switch
		float right
		background #afafaf

		&.active
			background: #9ec6e8;
			
			.on
				background $color_darkblue



#topMessageContainer
	position: relative;
	padding: 0;
	width: 100%;
	background: #1e9377;
	color: #f0f0f0;
	clear: both;

	a 
		text-decoration: underline
		color: #f0f0f0;
	
	> .container
		position: relative;
	
	#topMessage
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		clear: both;
		padding: 10px;
		// color: #fff;
		border: none;
		padding-left: 10px;
		
		a
			// color: #fff;
			text-decoration: underline;
		
		h3
			margin: 0;
			font-weight: bold;
			font-size: 20px;
			
			@media screen and (max-width: 991px)
				font-size: 16px;
		
		p
			margin-bottom: 0;
			font-size: 14px;
			line-height: 16px;
			
			@media screen and (max-width: 991px)
				font-size: 12px;
				line-height: 13px;
		
		a.close_topmsg
			color: white;
			position: absolute;
			top: 14px;
			right: 0;
			font-size: 30px;

body.darkmode
	#topMessageContainer
		background: #1b5c5d;
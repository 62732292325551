.m0
  margin 0
.mt0
  margin-top 0
.mr0
  margin-right 0
.mb0
  margin-bottom 0
.ml0
  margin-left 0
.mx0
  margin-left 0
  margin-right 0
.my0
  margin-top 0
  margin-bottom 0

.m1
  margin --space-1
.mt1
  margin-top --space-1
.mr1
  margin-right --space-1
.mb1
  margin-bottom --space-1
.ml1
  margin-left --space-1
.mx1
  margin-left --space-1
  margin-right --space-1
.my1
  margin-top --space-1
  margin-bottom --space-1

.m2
  margin --space-2
.mt2
  margin-top --space-2
.mr2
  margin-right --space-2
.mb2
  margin-bottom --space-2
.ml2
  margin-left --space-2
.mx2
  margin-left --space-2
  margin-right --space-2
.my2
  margin-top --space-2
  margin-bottom --space-2

.m3
  margin --space-3
.mt3
  margin-top --space-3
.mr3
  margin-right --space-3
.mb3
  margin-bottom --space-3
.ml3
  margin-left --space-3
.mx3
  margin-left --space-3
  margin-right --space-3
.my3
  margin-top --space-3
  margin-bottom --space-3

.m4
  margin --space-4
.mt4
  margin-top --space-4
.mr4
  margin-right --space-4
.mb4
  margin-bottom --space-4
.ml4
  margin-left --space-4
.mx4
  margin-left --space-4
  margin-right --space-4
.my4
  margin-top --space-4
  margin-bottom --space-4

.mxn1
  margin-left - --space-1
  margin-right - --space-1
.mxn2
  margin-left - --space-2
  margin-right - --space-2
.mxn3
  margin-left - --space-3
  margin-right - --space-3
.mxn4
  margin-left - --space-4
  margin-right - --space-4

.myn1
  margin-top - --space-1
  margin-bottom - --space-1
.myn2
  margin-top - --space-2
  margin-bottom - --space-2
.myn3
  margin-top - --space-3
  margin-bottom - --space-3
.myn4
  margin-top - --space-4
  margin-bottom - --space-4

.mtn4
  margin-top - --space-4

.ml-auto
  margin-left auto
.mr-auto
  margin-right auto
.mx-auto
  margin-left auto
  margin-right auto

.m5
	margin 5px

.my5
	margin-top 5px
	margin-bottom 5px

.mx5
	margin-left 5px
	margin-right 5px

.mt5
	margin-top 5px

.mb5
	margin-bottom 5px

.ml5
	margin-left 5px

.mr5
	margin-right 5px
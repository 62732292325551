.most_watched_show {
    margin: 5px 20px;
    position: relative;
    top: 0px;
    left: 0px;
	
	span.title {
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		color: white;
		background: rgba(0, 0, 0, 0.53);
		font-family: 'Oswald', Verdana, sans-serif;
		font-size: 30px;
	}
}

#sector_encours {
    position: relative;
}
#ancres_encours {
    margin: 20px 0px;
    padding: 0;
    line-height: 18px;
}

.seriesencours {
    position: relative;
    min-height: 75px;
    margin-bottom: 20px;
	
	.episodesrestants, .episodespossedesrestants {
		line-height: 18px;
		padding: 0px 5px;
	}

	span.vu_nbre, span.vu_time, span.poss_nbre, span.poss_time {
		font-weight: bold;
		font-size: 16px;
	}

	img.minibanniere {
		float: left;
		width: 150px;
		height: 75px;
		margin-right: 20px;
	}
	
	h3 {
		background: #f3f3f4;
		font-size: 20px;
		font-weight: bold;
		padding-bottom: 5px;
		margin: 10px 0px;
		padding: 10px;
		border: none;
		
		a {
			color: #333;
			
			.label {
				font-size: 9px;
				vertical-align: middle;
			}
		}
	}
	
	a.extend {
		position: absolute;
		top: 12px;
		right: 10px;
	}
	
	ul.options {
		right: 0px;
		top: 42px;

		li {
			a {
				padding: 5px 10px;
			}
		}
	}
	
	ul.list_zodes {
		clear: left;
		padding: 0;
		margin-top: 15px;
		
		li.episode {
			clear: both;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			padding: 0;
			margin-bottom: 5px;
			
			@media screen and (max-width: 778px) {
				padding: 7px 0;
				min-height: 33px;
			}
			
			/*&:nth-child(odd){
				background: #e4e4e4;
			}
			
			&:nth-child(even){
				background: #efefef;
			}*/

			&.episodecache {
				display: none;
			}

			.title {
				width: 40%;
				font-weight: bold;
				margin-right: 10px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
				&.seasonpremiere {
					color: $color_seasonpremiere;
				}
				&.seasonfinale {
					color: $color_seasonfinale;
				}
				&.midseasonpremiere {
					color: $color_midseasonpremiere;
				}
				&.midseasonfinale {
					color: $color_midseasonfinale;
				}
				
				@media screen and (max-width: 991px) {
					width: 30%;
				}
				
				@media screen and (max-width: 778px) {
					width: calc(100% - 130px);
					margin-bottom: 5px;
				}
				
				.srt {
					@media screen and (max-width: 778px) {
						display: none;
					}
				}
			}
			
			.date {
				width: 70px;
				text-align: center;
				
				@media screen and (max-width: 778px) {
					margin-bottom: 5px;
				}
			}
			
			.audience, .duree {
				width: 100px;
				text-align: center;
				font-style: italic;
				
				@media screen and (max-width: 778px) {
					width: 50px;
					text-align: right;
					margin-bottom: 5px;
				}
				
				@media screen and (max-width: 340px) {
					display: none;
				}
			}
			
			.ep_rate {
				display: flex;
				align-items: center;
				min-width: 172px;
				
				.basic {
					display: inline-block;
					margin-right: 7px;
					width: 115px;
					height: 20px;
				}
				
				.episode_note {
					text-align: center;
				}
			}
			
			.ep_vis {
				text-align: right;
				min-width: 130px;
				
				@media screen and (max-width: 778px) {
					width: calc(100% - 172px);
				}
				
				@media screen and (max-width: 332px) {
					min-width: 100%;
				}
			}
			
			label.btn {
				padding: 4px 10px;
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}
	
	.list_zodes_wrapper {
		height: 0px;
		overflow: hidden;
	}
	
	.loading {
		float: right;
		padding-top: 11px;
		padding-right: 19px;
	}
}

ul.list_series 
	li
		display: block;
		position: relative;
		padding: 5px;
		
		&.odd, &.active
			background-color: #f3f3f4;
			color: #000;
		
		&:hover 
			background-color: #d4edfb;
			color: #000;
			

		&.inactive
			background-color: silver;
			opacity: 0.3;
		
		a
			&.name
				width: 100%;
				display: inline-block;
				padding: 5px 0px;
				line-height: 18px;

				@media (max-width 767px)
					width: 60%;
					line-height: 13px;
			
			&.extend
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 0px;
				right: 0px;
				width: 38px;
				height: 38px;
				margin-left: 5px;
		
		div.epvus
			width: 268px;
			display: inline-block;
			position: absolute;
			top: 5px;
			right: 30px;

			@media (max-width 767px)
				width 90px;

				.advancement_media
					width 80px

				.percent_serie
					display: none
		
		ul.options
			top: 0px;
			right: 30px;

.report_menu_series
	.report_menu_item
		@media (max-width: 768px)
			width 25%
.surlignage
	background-color: #CFE6F5;

.search_form_wide
	margin: 10px 0;

ul.search_results_listing
	> li
		margin: 0;
		margin-bottom: 15px;
		
		.search_type
			display: none
		
		span.year
			color:#2b2b2b;

		.affiche
			flex none

			> a
				display block

				img
					display block

					@media (max-width 778px)
						width 100%
						height auto

			&.affiche_game
				width 75px
				text-align left
				overflow hidden
				text-align center

				@media (max-width 778px)
					order 0

				> a  
					display: block;
					height: 100px;
					background-size: cover;
					background-position: 50% 50%;
					background-repeat: no-repeat;

		h3
			margin: 0;
			font-size: 22px;

			a
				display: flex;
				align-items: center;

				span.dlc
					background: $color_dlc
					color: white;
					padding: 3px 5px;
					display: inline-block;
					font-size: 10px;
					line-height: 12px;
					margin-left: 5px;

		> a
			display:block;
			width:100%;
			height:100%;
			clear:both;
			margin-right:15px;
			transition: all 300ms ease-out;
			
			img
				display: inline-block;

			&.tvshow, &.movie
				display: flex;
				align-items: center;

				img
					margin-right: 15px;

				h3
					margin: 0;
					font-size: 22px;

				@media (max-width: 500px)
					img
						width: 40%;
						height: auto;

					&.movie
						img
							max-width: 75px;

				@media (max-width: 400px)
					// flex-direction: column;

					img
						width: 25%;

					h3
						font-size: 17px;
						line-height: 15px;
			
			&.people
				.people_wrap
					display: flex;
					align-items: center;
					
					.people_name
						.people_firstname
							display: block;
							font-size: 16px;
							line-height: 13px;

						.people_lastname
							display: block;
							font-size: 20px;
							text-transform: uppercase;
							line-height: 16px;
							margin-top: 8px;
			
			&:hover
				background: #e2e2e2;
	
	&.search_listing_perfect
		li
			.search_type
				display: block;
				color: #555;
				text-transform: uppercase;
				font-size: 11px
/*** DARK MODE ***/
body.darkmode
	background $color_dark_background
	color $color_dark_text

	/*a
		color $color_dark_text*/

	optgroup
		color $color_dark_text

	.bg-white
		background $color_dark_btile
	
	.bg-gray-light
		background $color_dark_tile

	.btn
		&.btn-default
			background-color $color_dark_tile
			border-color $color_dark_tile
			color $color_dark_text

			&:hover, &:focus
				background-color black
				color $color_dark_text

		&.btn-primary
			background-color $color_primary

			&:hover
				background-color $color_primary

			&.wish
				background-color: $color_dark_wish
				border-color: #560026;

		&.btn-danger
			background-color #d9534f

			&.pasvu
				background: #611c1c;
				border-color: #400505;
				color $color_dark_text

		&.btn-info
			color $color_dark_tile
		
		&.btn-success
			background-color $color_dark_success
			border-color $color_dark_success

	.label-info
		background-color #456a8e

	.alert-success
		background-color #164c00
		color #3fbb49
		border-color #3d6a17

	.alert-info
		background-color #363d44
		color $color_dark_text

	.h2wide
		background $color_dark_subtitle
		border-color $color_dark_subtitle

		h2
			color $color_dark_text

	ul.nav.nav-pills-small > li > a
		background-color $color_dark_subtitle
		color $color_dark_text

	.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover
		background-color $color_darkblue !important
		color: white !important
		
	.nav>li>a:focus, .nav>li>a:hover
		background-color #3c3c3c !important

	.calendar_filter li.current
		background $color_darkblue

	.well
		background-color $color_dark_tile
		border-color black

	.panel
		background-color $color_dark_tile
		
	.panel-default
		border-color $color_dark_tile
		
		>.panel-heading
			color $color_dark_text
			background-color $color_dark_tile
			border-color $color_dark_subtitle

	.nav>li>a
		color $color_dark_text

	.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover
		background-color $color_dark_subtitle
		color $color_dark_text
		border-color $color_dark_subtitle
		border-bottom-color transparent

	.tabbable-custom > .tab-content
		background-color $color_dark_subtitle
		border-color $color_dark_subtitle
		
	.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th
		border-color $color_dark_tile

	.table>tbody>tr.success>td, .table>tbody>tr.success>th, .table>tbody>tr>td.success, .table>tbody>tr>th.success, .table>tfoot>tr.success>td, .table>tfoot>tr.success>th, .table>tfoot>tr>td.success, .table>tfoot>tr>th.success, .table>thead>tr.success>td, .table>thead>tr.success>th, .table>thead>tr>td.success, .table>thead>tr>th.success
		background-color $color_dark_success

	.table-hover>tbody>tr.success:hover>td, .table-hover>tbody>tr.success:hover>th, .table-hover>tbody>tr:hover>.success, .table-hover>tbody>tr>td.success:hover, .table-hover>tbody>tr>th.success:hover
		background-color $color_dark_hover_success
	
	.table>thead>tr>th
		border-color $color_dark_black

	.table-striped>tbody>tr:nth-of-type(odd)
		// background-color $color_dark_btile
		background-color $color_dark_tile

	.table-hover>tbody>tr:hover
		background-color $color_dark_black

	input, select, textarea, .form-control
		background-color $color_dark_tile
		color $color_dark_text
		border-color black

	.select2-drop, .select2-drop-mask, .select2-container .select2-choice, .select2-container .select2-choice .select2-arrow, .select2-search input, .select2-container-multi .select2-choices
		background $color_dark_tile
		color $color_dark_text
		border-color black

	.select2-container .select2-choice
		box-shadow none

	.select2-results
		&::-webkit-scrollbar
			width 10px

		&::-webkit-scrollbar-track
			background #2b2b2b

		&::-webkit-scrollbar-thumb
			background $color_dark_tile

			&:hover
				background black
	
	.form-control.select2-container.select2-dropdown-open
		border-color black

	.dropdown-menu
		background-color $color_dark_btile

		>li>a
			color $color_dark_text

		>li>a:focus, >li>a:hover
			background-color $color_dark_subtitle

	.pagination
		>li >a, >li>span
			background-color $color_dark_tile
			color $color_dark_text
			border-color black

		>li>span.currentPage
			background $color_darkblue
			


	#header_plus
		background $color_dark_tile
		
	/* Homepage */
	
	#masonry_home .item_masonry
		background $color_dark_tile
		border-color $color_dark_tile
		color $color_dark_text

		a.media
			color $color_dark_text

			&.seasonpremiere
				color $color_dark_seasonpremiere

			&.seasonfinale
				color $color_dark_seasonfinale

			&.midseasonpremiere
				color $color_dark_midseasonpremiere

			&.midseasonfinale
				color $color_dark_midseasonfinale

	#sbox_report .col_report_sections .timelost span.num
		background-color $color_dark_tile

	.infoLevel
		background-color $color_dark_tile

		h4 
			color $color_dark_text

	#moreProfilFlux
		background-color $color_dark_background


	/* Profil */
	#profil_header, .menuProfil, #profile_edit_zone form
		background $color_dark_subtitle

	.profile_menu_burger:focus, .profile_menu_burger:hover
		color $color_dark_text
		
	.profil_element 
		background $color_dark_subtitle
		
		h2
			background $color_dark_tile

	.month_time_wrapper .month_time_line.odd
		background $color_dark_tile

	.bg_white .month_time_body .month_time_line .month.empty.futur .month_link:before, .bg_white .month_time_body .month_time_line .month.empty.futur .month_link:after, .bg_white .month_time_body .month_time_line .month.current .month_link:after
		background $color_dark_btile

	.status_encours
		background $color_dark_subtitle

	.fluxmedia .episode a
		color $color_dark_text

	.fluxmedia .note
		color $color_dark_textlight

	.menuProfil > ul > li.active a, .menuProfil > ul > li.showed a
		color $color_dark_text

	.block_icon
		color $color_dark_text

	.eps_stts .sts_show.odd
		background $color_dark_btile

	.eps_stts .sts_show .sts_eps .row_ep.seasonpremiere .ep_title
		color: $color_dark_seasonpremiere

	.eps_stts .sts_show .sts_eps .row_ep.seasonfinale .ep_title
		color: $color_dark_seasonfinale

	.eps_stts .sts_show .sts_eps .row_ep.midseasonpremiere .ep_title
		color: $color_dark_midseasonpremiere

	.eps_stts .sts_show .sts_eps .row_ep.midseasonfinale .ep_title
		color: $color_dark_midseasonfinale

	.episode
		a
			&.seasonpremiere
				color: $color_dark_seasonpremiere
			
			&.seasonfinale
				color: $color_dark_seasonfinale

			&.midseasonpremiere
				color: $color_dark_midseasonpremiere
			
			&.midseasonfinale
				color: $color_dark_midseasonfinale

	ul.calendar_episodes
		li
			&.seasonpremiere
				a
					color: $color_dark_seasonpremiere;
			
			&.seasonfinal
				a
					color: $color_dark_seasonfinale;

			&.midseasonpremiere
				a
					color: $color_dark_midseasonpremiere;
			
			&.midseasonfinal
				a
					color: $color_dark_midseasonfinale;

	.seasonpremiere
		color: $color_dark_seasonpremiere;
	
	.seasonfinal
		color: $color_dark_seasonfinale;

	.midseasonpremiere
		color: $color_dark_midseasonpremiere;
	
	.midseasonfinal
		color: $color_dark_midseasonfinale;

	.highcharts-container
		svg > rect
			// fill $color_dark_subtitle
			fill #464545

		.highcharts-axis-labels
			text 
				fill $color_dark_text !important
		
		text.highcharts-title, .highcharts-axis text, .highcharts-legend-item text
			fill $color_dark_text !important
			


	

	/* Suivi */
	.seriesencours 
		ul.list_zodes
			li.episode
				.title
					&.seasonpremiere
						color $color_dark_seasonpremiere

					&.seasonfinale
						color $color_dark_seasonfinale

					&.midseasonpremiere
						color $color_dark_midseasonpremiere

					&.midseasonfinale
						color $color_dark_midseasonfinale
		
		h3
			background $color_dark_tile
			
			a 
				color $color_dark_text

				.label
					color $color_dark_tile

	.next_episode
		color #616161
	
	.liste_series_img.liste_series_img_encours .show > a .advancement_media
		background $color_dark_tile

	ul#last_comments li
		border-color $color_dark_subtitle

	ul.list_series 
		li
			&.odd, &.active
				background-color $color_dark_subtitle
				color $color_dark_text

			&:hover 
				background-color $color_dark_tile
				color $color_dark_text


	/* Calendar */
	.calendar_filter li:not(.current)
		background-color $color_dark_subtitle

	/* Stars */
	.episode .basic
		background black

	.jStar
		background url(/images/jrating/stars_dark.png) repeat-x
		
	.jRatingAverage
		background-color $color_blue



	/* Fiche série */
	.item_details
		background-color $color_dark_tile

	.notestars
		background black

	.note_graph.note_graph_big .bar .number
		color $color_dark_text

	.fiche_episode .note_graph 
		border-bottom: 1px solid $color_dark_tile

		.bar .bar_inner
			background-color $color_dark_tile

	.tvshow_season_episodes 
		.table 
			tr.episode
				&.ep_diff
					background-color $color_dark_subtitle

				&.ep_nondiff
					background-color $color_dark_background
				
				&.checkedni
					background-color #444444
				
				&.checkedvu
					background-color #2d3842
				
			>thead>tr>th
				color $color_dark_text

		.part_title_wrap 
			h3.part_title
				background $color_dark_tile

	.row_item_v2 h2:after
		background-color $color_dark_tile

	.advancement_media 
		background #2d2d2d
		box-shadow 0 0 0 1px #000000

		.advancement_progress.advancement_progress_primary
			background $color_dark_black
				
		.advancement_txt
			color $color_dark_text

	.seasons_change.stuck
		background-color $color_dark_tile
		box-shadow none

	.topbar_casting.stuck
		background-color $color_dark_tile
		box-shadow none
		
	.casting li .perso
		color $color_dark_text

	.btnViewing.vu 
		.btn
			color: #fff;
			background-color: $color_darkblue;
			border-color: #2e6da4;
			
			&:hover
				background-color: $color_primary
				border-color: #204d74;


	/* Fiche épisode */
	.fancybox-content
		background $color_dark_btile

		&::-webkit-scrollbar
			width: 1em;
		
		&::-webkit-scrollbar-track
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		
		&::-webkit-scrollbar-thumb
			background-color: $color_dark_tile
			outline: 1px solid $color_dark_tile

	.fiche_episode .row_episode_header
		background #3e3e3e

	.ep_navigation .ep_nav
		background $color_dark_btile

		&:hover
			background #716c6c

	&.ep_nav_disabled:hover
		background $color_dark_tile
		
		&:hover
			background $color_dark_tile

	.container_fiche_episode
		.fancybox-close-small
			background $color_dark_btile
			
	.episode_vision
		background $color_dark_tile		

	.average_circle_wrapper .average_circle_txt
		color $color_dark_text

	ul.comments_episodes > li
		border-color $color_dark_tile

	/* Listing séries */
	#listing 
		> li
			background $color_dark_tile

		.number
			color $color_dark_text

		.nbrevotes
			color #717171

		.moyenne_wrap 
			.moyenne
				color $color_dark_text

	.calendar_filter li.last select, .calendar_filter li:last-child select
		background $color_dark_subtitle
		color $color_dark_text

	.nb_films_search
		color $color_dark_text

	/* Tableau récap */
	.tableau_recap .recap_episodes li
		color black

	.tableau_recap .season_episodes:hover
		background black

	.input-group-addon
		background-color $color_dark_subtitle
		border-color black

	/* Calendrier série */
	#calendar 
		td.past
			background $color_dark_subtitle

		td.today
			background #3c3c3c

	p.calendar_day
		background $color_dark_tile

	ul.calendar_episodes li
		border-color $color_dark_tile

		&.vu:before
			background $color_dark_text

	/* Saison en cours */
	.rentree_network
		a   
			color $color_dark_text
	
	.trserierentree
		background $color_dark_subtitle

		.rentree_titleserie
			a
				color $color_dark_text

		&.newserie
			background #7b7834
			
			&.nextyear
				background #827f47

		&.renouvelee
			background #53612f

		&.annulee
			background #5a2c2c



	/* Fiche film / ciné */
	.movie_vision
		background-color $color_dark_tile
	
	.movie_vision_actions 
		.fa
			color $color_dark_text

	#row_movie_casting
		background-color $color_dark_tile

		h2
			color $color_dark_text

	.casting#castingMovie ul li a
		color $color_dark_text

	.add_acteur .add_icon
		background-color $color_dark_btile

	.comments li
		border-color black

	.spoileralert
		color $color_dark_text

	.spoiler
		color $color_dark_background

	.num_saga
		background $color_dark_tile
		color $color_dark_text 

		&.num_saga_current
			background-color: $color_darkblue;
			color $color_dark_text 

	/* Challenge ciné */
	.challenge_nav
		for year in 2015 2016 2017 2018 2019 2020 2021 2022 2023 2024 2025
			&.challenge_nav_{year}
				.nav
					li
						a
							background $color_dark_tile
							color: #8b8b8b

	.challenge_explications 
		h1 
			color $color_dark_text

		.progress
			background-color $color_dark_tile

		.scoreTotal span.vus
			color $color_dark_text

	#wishChallenge 
		span
			color $color_dark_tilespan

			&#challenge_films_vus
				background:#2d4254

			&#challenge_films_wish
				background: #462534

			&#challenge_films_non_vu
				background:#252525

			&#challenge_series_ongoing
				background: #0b3700

			&#challenge_series_abandon
				background: #0e2f06

	#top100
		tr.wish
			background #462534

			a.linkCine
				color #e24a88

		tr.pasvu
			background #3e3e3e

			a.linkCine
				color $color_dark_textlight

		tr.vu
			background #2d4254

		tr.ongoing
			background #0b3700

		tr.abandon
			background #0e2f06

	/* Fiche jeu */
	ul.trophy_game_list li .trophee_count
		background-color $color_dark_tile
		border-color $color_dark_tile
		
		&:before
			background-color $color_dark_tile
			border-color $color_dark_tile

	.trophies_jv_link .percent
		background-color $color_dark_black

	.bg_gray
		background: $color_dark_tile

	.game_go_stats
		a
			color $color_dark_text

	.game_play
		background-color $color_dark_tile

	.btn-group.btn-group-game-status .btn.dropdown-toggle.etat_0
		border-color: #e4e4e4;
		color: #e4e4e4;

	.btn-group.btn-group-game-status .dropdown-menu > li a.active.etat_0, .btn-group.btn-group-game-status .dropdown-menu > li a:hover.etat_0
		color: #e4e4e4;

	.item_average_wrapper .item_average 
		.average_number
			color $color_dark_text
			
		.nb_votes
			color $color_dark_text
	
	.game_play_actions 
		.fa
			color $color_dark_text
	
	.game_players
		background: $color_dark_tile

	#buttonTags
		background: $color_dark_tile

	#listTags li:hover
		background $color_darkblue

	.row_trophy_header
		background-color $color_dark_background

	.ongoing_games .game.even
		background #3c3c3c
		// background $color_dark_subtitle
	
	/* Forum */
	.pun
		color $color_dark_text

	strong.friend
		color #ffbe2f

	#chatbox
		background-color $color_dark_tile
		border-color black
		color $color_dark_text

	.forum_category table tr:hover
		background-color $color_dark_tile

	div.postright, div.postfootright
		border-color $color_dark_tile


	/* Tickets */
	.ticket_category
		background $color_dark_tile
		color $color_dark_text

	.table_tickets
		.ligne_ticket
			background-color $color_dark_tile
			color: #bdbdbd;

			&.odd
				background-color $color_dark_btile

			&:hover
				background-color #2a2a2a

			&.modo
				background-color #363e4d

				&:hover
					background-color #3d4552

				a 
					color #a8c2f1


			&.mine
				background-color #493a01

				a 
					color #d6a023

				&:hover
					background-color #544301
		
		&.table_creations
			.ligne_ticket.mine.treated
				background-color #275029

				a 
					color #7ab87e

				&:hover
					background-color #2b522d

	.ticket_comment_text
		background-color $color_dark_tile
		color $color_dark_text


	/* Fiche people */
	.fiche_header_people
		.fiche_v2_topbar
			@media (max-width: 767px)
				background $color_dark_background
				color $color_dark_text
			
		.fiche_v2_header_title 
			h1, h2
				@media (max-width: 767px)
					color $color_dark_text

	.cast_tables tbody tr.vu
		background: #35383c;

	.legendView
		background-color $color_dark_tile

	.award_icon_wrapper a
		background: $color_dark_tile
		color: white;

	.cast_tables table tbody tr.not-available
		background: linear-gradient(-45deg,#35383c 20%,#434343 20%,#434343 30%,#35383c 30%,#35383c 70%,#434343 70%,#434343 80%,#35383c 80%);
		background-size: 20px 20px;
		background-position: 25px 25px;

	/* Recherche */
	.surlignage
		background-color #2b3e4e

	ul.search_results_listing li a:hover
		background $color_dark_background

	ul.search_results_listing li span.year
		color $color_dark_text


	/* FAQ */
	.faq_section .faq_explication
		background-color $color_dark_tile

	/* Trophées */
	.nomines_year .nomine
		background $color_dark_tile
		border-color $color_dark_tile

	.table-striped>tbody>tr.admin_nomine.nomine_win
		background $color_primary

	.search_texte.found
		background $color_primary

	/* Easyautocomplete */
	.easy-autocomplete 
		a.search_auto_link
			color white !important

			.item_type
				color #b9b9b9
	
	.easy-autocomplete-container ul
		background $color_dark_tile
		color $color_dark_text
		border-color black

		li 
			border-color black

			&.selected 
				background $color_primary

	/* Select2 */
	.select2-results .select2-no-results, .select2-results .select2-searching, .select2-results .select2-ajax-error, .select2-results .select2-selection-limit
		background $color_dark_tile

	/* Sweet Alert */
	.swal2-popup
		background $color_dark_tile

	.swal2-title
		color $color_dark_text

	/* Chosen */
	.chosen-container-single .chosen-single
		background $color_dark_subtitle
		color: $color_dark_text;
		box-shadow: none;

	.chosen-container .chosen-drop
		background $color_dark_subtitle
		color: $color_dark_text;
		box-shadow: none;
		border: none;

	.chosen-container .chosen-results
		color: $color_dark_text;

	.unchecked
		background: linear-gradient(-45deg,#35383c 20%,#434343 20%,#434343 30%,#35383c 30%,#35383c 70%,#434343 70%,#434343 80%,#35383c 80%);
		background-size: 20px 20px;
		background-position: 25px 25px;
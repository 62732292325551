/******************************************************************************/
/**********      Spécifique aux studios         ***********/
/******************************************************************************/

.studio_logo
	background: #fff;
	width: 200px;
	height: 200px;
	float: left;
	margin-top: 10px;
	margin-bottom: -30px;
	margin-right: 30px;
	padding: 10px;
	border: 10px solid #3c3d3e;
	box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.4);
	
	.studio_logo_inner
		display: block;
		width: 100%;
		height: 100%;
		background-size: contain;
		background-position: 50% 50%;
		background-repeat: no-repeat;

#listing > li
	.studio_logo
		width: 100px;
		height: 100px;
		border: none
		margin: 0;
		box-shadow: none;

.header_studio
	position: relative;
	z-index: 10;
	background: #2e2e2d;
	color: #fff;
	
	.uptitle
		text-transform:uppercase;
		margin-top: 80px;
		margin-bottom: 5px;
		font-size: 10px;
		letter-spacing: 0.05em;
	
	h1
		color: #fff;
		font-size: 30px;
		font-weight: 600;
		line-height: 28px;
		padding-top: 0px;
		margin: 0;
	
	.studio_desc
		width: 50%;
		float: right;

.container_studio
	padding-top: 30px;

.filters_studio
	&:after
		content:'';
		display:block;
		clear:both;
	
	h3.subtitle
		letter-spacing: 0.05em;
		text-transform: uppercase;
		font-family: 'Roboto', sans-serif;
		text-align: left;
		font-weight: 300;
		margin: 0;
		margin-bottom: 2px;
	
	ul.menu_horizontal.calendar_filter
		padding-top: 0;

.studio_animes_wrapper
	position: relative;
	
	.anime_box
		float: left;
		width: 262.5px;
		margin-bottom: 30px;
		opacity: 0;
		
		a
			position: relative;
			display: block;
			height: 260px;
			background: #fff;
			background: #e8e8e8;
			color: #313232;
			text-decoration: none;
			transition: all 300ms ease-in-out;
			
			&:before
				content: '';
				display: block;
				position: absolute;
				top: 10px;
				right: 10px;
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background: black;
			
			.year
				font-size: 16px;
				font-weight: 800;
				margin: 0;
				padding: 0 10px;
				padding-top: 10px;
				color: #a9a9a9;
				color: #7d7d7d;
			
			h3.title
				font-size: 16px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-top: 0;
				padding: 0 10px;
				font-size: 18px;

			.pic_wrapper
				display: block;
				width: 100%;
				height: 131.25px;
				background: gray;
				
				.pic
					display: block;
					width:100%;
					height:100%;
			
			.anime_box_footer
				padding: 10px;
				margin: 0;
				
				.anime_box_footer_inner
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					
					&:nth-child(1)
						margin-bottom: 10px;
				
				.viewers_wrap
					.viewers
						font-weight: bold;
				
				.nbre_episodes_wrap
					.nbre_episodes
						font-weight: bold;
			
			.advancement_media
				width: 100%;
				height: 8px;
				border: none;
				box-shadow: none;
				background: #d4d4d4;
			
			form.add_serie
				position: absolute;
				z-index: 100;
			
			&:hover
				transform: translate3d(0,-2px,0);
				box-shadow: 0px 1em 0.5em -0.4em #c3c3c3;
		
		&.encours
			a
				&:before
					background: #3cc73c;
		
		&.termines
			a
				&:before
					background: #de2929;
		
		&.projets
			a
				&:before
					background: #29abde;
	
	&.showed
		.anime_box
			opacity: 1;
.people_fan_zone
    position absolute
    bottom 0;

.people_block_awards
    border-right: 2px solid $color_awards;

.fiche_header_people
    .fiche_v2_topbar
        color white

        @media (max-width: 767px)
            min-height: 0
            background white
            color #333

    .fiche_v2_header_title 
        h1, h2
            @media (max-width: 767px)
                color #333

    .btn-group-fiche-admin
        @media (max-width: 767px)
            display: flex;
            position: relative;    
            top: auto;
            right: auto;
            margin: 10px 0;
            justify-content: flex-end;

.cast_tables
    h2
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom: 1px solid #666;
        margin: 10px 0px;

    table
        margin-bottom: 40px;

        tbody 
            tr 
                &.nonvu
                    border-left: 10px solid black;

                &.vu
                    border-left: 10px solid #3570C2;
                    background: #EDF1F7;

                &.wish
                    border-left: 10px solid $color_wish;

                &.not-available
                    background: linear-gradient(-45deg, #eee 20%, #fff 20%, #fff 30%, #eee 30%, #eee 70%, #fff 70%, #fff 80%, #eee 80%);
                    background-size: 20px 20px;
                    background-position: 25px 25px;

                &.hidePeopleInfos 
                    td, th 
                        border-top: 0;

                    .hiddenInfo
                        opacity: 0;



        .people_role_cast_serie 
            tbody 
                tr
                    border-left:none;


.cast_creation_img, .cast_creation_img img
    width: 200px;
    padding: 0px 10px;

.cast_list_year
    width: 70px;
    text-align: left;

.cast_year
    font-weight: bold

.cast_list_media
    width: 300px;

.cast_list_role, .cast_list_status
    width: auto;

.cast_list_note
    width: 60px;

.cast_list_moy
    width: 230px;

td.cast_moyenne
    width: 180px;

td.cast_nbvotes
    width: 50px;
    color: #B0B0B0; 

.cast_list_edit
    width: 35px;

.cast_list_moyenne
    border-top: 1px solid #999;
    color: #666;

    td
        padding-top: 10px;

.cast_imp
    text-transform: uppercase
    color: #B0B0B0; 
    font-size: 10px;

    &.-principal
        color #3570c2
        font-weight: 700;

    // &.-secondaire
    //     color #32c772

    // &.-tertiaire
    //     color #32c772

    // &.-petitrole
    //     color blue

    // &.-apparition
    //     color blue

.cast_list_moyenne_1
    text-align: right;
    font-weight: bold;
    padding-right: 10px;
    padding-bottom: 10px;

.cast_list_moyenne_2
    font-size: 18px;

.award_icon_wrapper
    float: left;
    text-align: center;

    a
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 70px;
        color: #000;
        font-size: 16px;

        img
            display: block;
            margin-bottom: 8px;
            max-width: 100%;
            max-height: 69px;

.avancement_film_casting { 
	float:right;
	
	.advancement_media { 
		height: 20px; 
		
		.advancement_txt { 
			line-height: 20px;
		}
	}
	
	.percent_serie { font-size: 15px; line-height: 20px; width: 40px; }
}

.table.table_cast
    th, td 
        vertical-align: middle

    a.media_title 
        display: inline-block;
        line-height: 17px;
        font-size: 16px;
        font-weight: 700;

.table.stupidTable {
	th[data-sort]{
		position:relative;
		cursor: pointer;
		padding-right: 10px;
		
		&:after {
			content:'\f0d7';
			font-family: FontAwesome;
			display:inline-block;
			padding-left: 5px;
			opacity:0.3;
		}

		&.sorting-asc, &.sorting-desc {
			&:after {
				opacity: 1;
			}
			
			&.sorting-asc {
				&:after {
					content:'\f0d7';
				}
			}
			&.sorting-desc {
				&:after {
					content:'\f0d8 ';
				}
			}
		}
	}
}

.people_external .btn-square
    margin-bottom 10px

.birthday_title
    font-size 26px
    font-weight: bold
    margin-bottom: 20px;

.birthday_main
    margin-bottom 15px

    @media (min-width: 992px)
        height: 280px;

    @media (min-width: 1200px)
        height: 330px;

    h2 
        font-size: 18px
        text-align: center
        margin-bottom: 0;

        span.lastname
            font-size: 21px

    img.portrait
        width 100%

    p.birth_age
        text-align: center
        margin: 0;
        margin-top: 2px;
/******* 
		Nouvelles fiches
								**********/

.fiche_v2_header
	position: relative;
	min-height:420px;
	padding-top:140px; 
	background-color: #000; 
	background-color: #0A1A27; 
	background-repeat: no-repeat; 
	background-position: 50% 20%;  
	background-size: cover;
	overflow: hidden;
	
	@media screen and (max-width: 991px)
		background-size: cover;
	
	@media screen and (max-width: 767px)
		padding-top: 0;
		height: auto;
		min-height: auto;
	
	.fiche_v2_header_bg
		position: absolute;
		top:0;
		display: block;
		width:100%;
		height:100%;
		background-position: 50% 50%;
		background-attachment: fixed;
		background-size: 2100px;
		background-repeat: no-repeat;
		background-position: 50% 60px;
		opacity:0;
		transition: opacity 1000ms ease-in-out;

		@media screen and (max-width: 1920px)
			background-size: 1920px;

		@media screen and (max-width: 991px)
			background-size: cover;
			background-attachment: initial;
			background-position: 50% 50%;
		
		@media screen and (max-width: 767px)
			display: none;
		
		&.display
			opacity:1;

		&.fiche_v2_header_blur
			filter: blur(15px);
			overflow: hidden;
			background-position: 50% 50%;
			
			@media screen and (max-width: 767px)
				display: none;

	// &.fiche_header_tvshow
	// 	.fiche_v2_header_bg
	// 		@media screen and (max-width: 767px)
	// 			display: block;

.fiche_v2_topbar
	position: relative; 
	min-height: 202px; 
	background:black;  
	background: rgba(0, 0, 0, 0.75);

	.container 
		position:relative; 

	.item_cover_wrapper 
		float: left;
		width: 100%;
		margin: 0;

#item_travel
	position: absolute;
	top: -30px;
	left: 15px;
	z-index: 100;
	
	@media screen and (max-width: 767px)
		display: none;

	a, a:focus, a:hover
		color: #4A4A4A;

.fiche_v2_header_title
	padding-top: 20px;
	
	@media screen and (max-width: 767px)
		padding-top: 0px;
	
	p.item_year
		margin: 0;
		font-size: 18px;
		line-height: 14px;
		font-weight: 800;
		color: #A2A2A2;

		a
			color: #A2A2A2;

			&, &:focus, &:hover
				text-decoration: none;
	
	h1
		display flex
		align-items: center
		color: white;
		font-size: 30px;
		font-weight: 600;
		line-height: 28px;
		padding-top: 0px;
		margin: 10px 0;
		margin-top: 5px;
		margin-bottom: 0;
		
		@media (max-width: 1200px)
			font-size: 26px;
		
		@media (max-width: 767px)
			font-size: 22px;
			margin-top: 0;
			margin-bottom: 0;
		
		.movie_title
			@media (max-width: 767px)
				display: block;
		
		.glyphicon, .fa
			font-size: 16px;
			color: #4C4C4C;

		span.dlc
			background: $color_dlc
			color: white;
			padding: 4px 7px;
			display: inline-block;
			font-size: 12px;
			line-height: 12px;
			margin-left: 10px;
	
	h2
		color: #DADADA;
		font-size: 16px;
		font-weight: 300;
		line-height: 20px;
		
		@media (max-width: 767px)
			font-size: 14px;
			line-height: 18px;
		
		&#movie_original_title
			font-style: italic;
			color: #737373;

	.header_line_infos
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;

		h3
			font-size: 15px;
			line-height: 15px;
			padding-right: 30px;
			font-weight: 400;
			margin-bottom: 5px;
			color: #E2E2E2;
			
			@media (max-width: 767px)
				font-size: 14px;
				margin-bottom: 10px;
				padding-right: 15px;
			
			&#movie_time
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: -1px;
				color: #FFF;
			
			&#movie_flags
				float: left;
				
				.movie_flag
					float: left;
					padding-right: 10px;

					img
						height: 16px;
						display: inline-block;
			
			a
				color: #E2E2E2;
	
		img.flag
			height: 19px;
			display: inline-block;

.note_graph
	clear: both;
	margin: 0;
	width: 100%;
	height: 60px;
	margin-top: 10px;
	border-bottom: 1px solid #636363;

	.bar
		float: left;
		position: relative;
		line-height: 20px;
		color: #626262;
		font-size: 10px;
		text-align: center;
		background: none;
		padding-top: 0;
		background: #2B2B2B;
		background: rgba(45, 45, 45, 0.32);

		width: 3.76%;
		margin-right: 1%;
		height: 100%;
		
		a
			display: block;
			width: 100%;
			height: 100%;

		.number
			display: none;
			position: absolute;
			bottom: -20px;
			left: 50%;
			font-size: 8px;
			color: #626262;
			width: 20px;
			margin-left: -10px;

			@media screen and (max-width: 991px)
				display: inline-block;

		&:nth-child(even)
			.number
				bottom: -27px;

		.bar_inner
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			color: white;
			background: white;

		&:hover .bar_inner
			 background: #9a9a9a;

		&.bar_average
			&.bar_note_excellent
				.bar_inner 
					background: #47DA2E;
				.number
					color: #47DA2E;

			&.bar_note_good
				.bar_inner 
					background: #83F954;
				.number
					color: #83F954;

			&.bar_note_medium
				.bar_inner
					background: #ECE832;
				.number
					color: #ECE832;

			&.bar_note_bad
				.bar_inner
					background: #C57116;
				.number
					color: #C57116;

			&.bar_note_crap
				.bar_inner
					background: #F50000;
				.number
					color: #F50000;

#fiche_v2_header_average
	height: 200px;
	
	@media screen and (max-width: 767px)
		position: absolute;
		top: calc(50vw - 31px);
		right: 0;
		height: auto;
	
	#header_average
		.note_graph_wrapper
			@media screen and (max-width: 991px)
				display: none;
			
			.note_graph
				@media screen and (max-width: 991px)
					height: 86px;

		.item_average_wrapper
			float: right;

#note_graph_fancybox
	h2
		font-size: 18px;

	.item_average
		color black;

	.game_average_support
		h3
			font-size: 16px;
			margin-bottom: 0;

.note_graph.note_graph_big
	min-width: 600px;
	height: 300px;
	margin-bottom: 30px;

	@media screen and (max-width: 767px)
		min-width: 300px;

	.bar
		min-width: 10px;
		max-width: 30px;
		height: 300px;
		padding-top: 300px;

		.number
			display: block;
			color: #333;
			font-size: 11px;

		.bar_inner
			background: $color_primary;
			// background: #439943;
			
			.count
				width: 100%;
				text-align: center;
					
				&.count_top
					position: absolute;
					top: -20px;
					left: 0;

	.tooltip
		z-index: 12000;

.item_average_wrapper
	position: relative;
	width: 104px;
	margin-top: 10px;
	
	.item_average
		position: absolute;
		top: 0;
		right: 0;
		font-size: 20px;
		color: #FFF;
		font-weight: 300;
		padding: 22px 0;
		width: 100px;
		text-align: center;

		.glyphicon
			display: none;

		.average_number
			display: inline-block;
			font-size: 30px;
			font-weight: 600;

		.average_on
			font-size: 10px;
			line-height: 23px;
			display: none;

		.nb_votes
			font-size: 12px;
			display: block;
	
	.average_circle
		width: 100px;
		margin: auto;
		float: right;

#item_note
	margin-bottom: 15px;

#item_fiche_completed
	color: #4A4A4A;
	font-style: italic;

#col_item_cover
	position: relative;
	margin-top: -290px;
	min-height: 400px;
	
	@media screen and (max-width: 991px)
		margin-top: -387px;
	
	@media screen and (max-width: 767px) 
		margin-top: 0;
		min-height: auto;

.item_cover_wrapper
	min-height: 250px;
   
	@media screen and (max-width: 767px)
		min-height: auto;
   
	.fiche_header_tvshow .fiche_v2_topbar &
		min-height: 20px;
		margin: 20px 0;
		float: none;

#item_cover
	display: block;
	margin-bottom: 15px;
	position: relative;
	
	&.hasSlider
		@media screen and (min-width: 768px)
			display: none;
	
	img
		display: block;
		width: 100%;
		height: auto;
		
		.fiche_header_tvshow &
			min-height: auto;
	
	&:after
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgba(0, 0, 0, 0.29);
		transition: all 300ms ease-in-out;
		
		.fiche_header_tvshow &
			display: none;
	
	.fa-search-plus
		position: absolute;
		bottom: 10px;
		right: 10px;
		opacity: 0;
		font-size: 20px;
		color: white;
		transition: all 300ms ease-in-out;
		transform: scale(0);
	
	&:hover .fa-search-plus
		opacity: 1;
		transform: scale(1);
	
	&:hover:after
		opacity: 1;

#item_cover_slider
	height: 180px;
	margin-bottom: 20px;
	overflow: hidden;
	
	@media screen and (max-width: 767px)
		height: auto;
		display: none;
	
	> img
		display: block;
		width: 100%;
		height: auto;
	
	&.slick-initialized
		overflow:inherit;
	
	.slick-dots
		bottom: -21px;
		margin: 0;
		
		@media screen and (max-width: 767px)
			bottom: 0;
		
		li
			margin: 0;
			
			button:before
				color: #CCC8C7;

.row_item_v2
	line-height: 20px;
	
	&#fiche_v2_informations
		margin: 20px 0;
		
		@media screen and (max-width: 767px)
			margin: 0;
		
		&.fiche_v2_informations_serie
			margin-bottom: -110px;
		
		#control_game_etat
			width: 100%;
			
			a
				text-align: left;
		
		#control_game_poss
			width: 100%;
			
			a
				text-align: left;
		
		.item_cover_wrapper
			@media screen and (max-width: 767px)
				display: none;
	
	h2
		margin-top: 0;
		position: relative;
		padding-bottom: 5px;
		font-weight: 600;
		font-size: 16px;
		margin-bottom: 15px;
		text-transform: uppercase;

		&.-normal
			text-transform: none;

		&.mb0 
			margin-bottom: 0px;
		
		&.mb1
			margin-bottom: 10px;
		
		&.mb5
			margin-bottom: 5px;
		
		&.pb0
			padding-bottom: 0px;

		/*&:after
			content: '';
			display: block;
			position: absolute;
			bottom: 0px;
			width: 100px;
			height: 2px;
			background: $color_darkblue
			border-radius: 4px;*/

.row_item_v2_details
	margin-bottom: 10px;

	&.mb0
		margin-bottom: 0px;
	
	.col_right
		text-align: right;
		
		ul
			margin: 0;

.item_details
	background: $color_gray;
	padding: 20px;
	
	&.item_synopsis
		overflow-wrap: break-word;
		
		&.item_synopsis_tvshow
			margin-top: 0;
			margin-bottom: 0;

			@media (max-width: 767px)
				margin-bottom: 10px;
		
		&.item_synopsis_movie
			.adaptation
				margin-top: 15px;
	
	p
		margin: 0;

		&.adaptation
			margin-top: 7px;
		
		&.movie_tags
			margin-top:10px;
			display: flex;
			flex-wrap: wrap;

			.label
				font-size: 13px;
				margin-top: 5px;
				margin-right: 15px;
				border-radius: 0;
				font-weight: 400;
	
	h3
		color: #6D6D6D;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		margin: 0;
	
	ul
		line-height: 16px;

	.fa
		font-size: 11px;
	
	.glyphicon 
		font-size: 11px;
	
	.flag
		width: 12px;
	
	.row_item_v2_details .col_right
		font-size: 13px;
		line-height: 16px;


#item_share
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
	
	.btn
		position: relative;
		color: #333;
		text-transform: uppercase;
		display: block;
		font-size: 12px;
		font-weight: 300;
		border-radius: 0;
		text-align: right;
		background: #D6D6D6;
		transition: all 200ms ease-in-out;
		
		.fa
			font-size: 22px;
			padding-right: 5px;
			position: absolute;
			top: 4px;
			left: 10px;
	
	.btn-movie-facebook:hover
		background: #3B5998;
		color: white;
	
	.btn-movie-twitter:hover
		background: #55ACEE;
		color: white;


.list-users
	display: grid;
	grid-template-columns: repeat(auto-fill, 50px);
	gap: 5px;
	justify-content: space-between;
	padding-left: 0
	list-style: none 
	margin-left: -2px;
	margin-right: -2px;

	&:after
		content: "";
		flex: auto;
		width 50px

	li
		position relative
		top 0px
		left 0px
		width 50px
		height 50px

		a
			position relative
			display block
			width 100%
			height 100%

			img
				display: block
				width 100%
				height 100%

			.note, .nombre
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				background: black;
				background: rgba(0, 0, 0, 0.5);
				color: white;
				font-size: 30px;
				text-align: center;
				font-family: 'Oswald', Verdana, sans-serif;

			.note
				top 0
				height 100%
				width: 100%;
				height: 50px;
				line-height: 50px;
				font-weight: 300;
				font-size: 25px;

				&.-small
					top: 40px;
					height: 35px;
					line-height: 35px;
					font-size: 19px;
					color: #333;

					height: 25px !important;
					line-height: 25px !important;
					top: 25px !important;
					width: 50px;

					.hasNote
						background-image: url('../../images/design/star_series.png');
						background-repeat: no-repeat;
						background-position: 50% 50%;
						display: block;
						height: 35px;
						line-height: 40px;

						height: 25px
						line-height: 25px
						background-size: 50% 100%;
						font-size: 10px;

			.nombre
				top: 0px;
				height: 25px !important;
				line-height: 25px !important;
				font-size: 15px;
				width: 100%;
				height: 40px;
				line-height: 45px;

			
			.count_movie_seen
				position: absolute;
				bottom: -2px;
				right: -4px;
				background: #a00303;
				color: #fff;
				width: 20px;
				text-align: center;
				height: 20px;
				border-radius: 100%;
				z-index: 100;

.thanks_to
	font-size: 11px;
	text-align: left;
	padding: 15px 0px;

.btn-group-fiche-admin
	position: absolute;
	top: -130px;
	right: 0;

	@media (max-width: 767px)
		top: 5px;
		right: 15px;
		z-index: 100;

	.dropdown-menu
		@media (max-width: 767px)
			max-width: 300px;

.fiche_header_wrapper
	position: relative;
	z-index: 2000;

.container_btn_admin
	position: relative;
	z-index: 2000;

	.btn-group-fiche-admin
		top 20px

#basic_note, .jStar, .jRatingAverage, .jRatingColor
	cursor:pointer;

.num_saga
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 5px;
	border: 1px solid #333;
	margin-right: 5px;
	margin-bottom: 5px;
	border-radius: 100%;
	width: 30px;
	height: 30px;
	text-transform: none;
	transition: all 200ms ease-in-out;

	&.-small
		font-size: 10px;

	&:hover
		background: $color_darkgray;
		color: white;
		text-decoration: none;

	&:focus
		text-decoration: none;
		outline: none;

	&.num_saga_current
		background-color: $color_darkblue;
		color: white;

.row_saga
	display: flex;
	align-items: center;

	h3 
		font-size 18px
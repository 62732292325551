.ticket_category
	font-size: 30px
	background: #356ec1;
	color: #fff;
	padding: 5px 30px;

.table_tickets 
	td.p0
		padding 0

	tr.ligne_ticket
		background-color: #E0EAFD;

		a 
			color #337ab7

		th, td 
			vertical-align:middle;

		&.odd
			background-color: #F1F1F1;

		&.modo 
			background-color: #FFD680;

			&:hover 
				background-color: #FFD680;

		&.mine
			background-color: #fffdb0;

			&:hover 
				background-color: #fffdb0;
		
	&.table_creations
		.ligne_ticket.mine.treated, .ligne_ticket.mine.treated:hover
			background-color: #c3efc5;

.ticket_success
	text-align: center;
	font-size: 22px;
	margin: 20px 0px;

.before_ticket_wrapper
	font-size: 13px;
	margin-bottom: 10px;
	padding: 10px;
	line-height: 18px;
	
	h4
		font-size: 20px;
		
	ul
		list-style: disc;
		padding-left: 15px;
		
		li
			padding-bottom: 5px;
			ul 
				li 
					list-style: circle;
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import './layout/_variables'
@import './lib/basscss/basscss'

@import './layout/_background';
@import './layout/_border';
@import './layout/_btn';
@import './layout/_global';
@import './layout/_text';
@import './layout/_title';
@import './layout/_cookie_consent';

@import './layout/_header';
@import './layout/_footer';

/***** Wrapper ********/

#wrapper {
	position: relative;
    z-index: 2;
    padding-top: 60px;
	min-height: 90vh;
	
	@media screen and (max-width: 991px) {
		padding-top: 0;
		padding-bottom: 0;
	}
	@media screen and (min-width: 768px) and (max-width: 991px) {
		margin-top: 105px;
	}
	@media screen and (min-width: 200px) and (max-width: 767px) {
		margin-top: 60px;
	}
}

#wrapper_content {
    width: 960px;
    margin: auto;
}
.wide {
    width: 100%;
}
section {
    margin: 0px auto;
    max-width: 960px;
    position: relative;
    top: 0px;
    left: 0px;
}
.h2wide {
    background: #F3F3F4;
    padding: 5px 0px;
    border: 1px solid #E1E1E4;
    border-left: 0px;
    border-right: 0px;
    margin: 10px 0px;
	
	@media screen and (min-width: 200px) and (max-width: 960px) {
		text-indent: 5px;
	}

	h2 {
		font-family: 'Oswald', Verdana, sans-serif;
		font-size: 23px;
		color: #333;
		font-weight: 300;
		padding-top: 5px;
		padding-bottom: 5px;
		margin: 0 auto;

		.btn {
    		font-family: 'Roboto',sans-serif;
		}
	}
	
	&.blackH2 {
		margin-top: 0px;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		color: white;
		background: #292929;
		
		h2 {
			color: white;
		}
	}
}

.w100 {
	width: 100%;
}

/* Multipages */

#user_infos a,
a.linka {
    color: $color_blue;
}
.loading {
    display: none;
    padding: 5px;
}
.loading_black {
    width: 16px;
    height: 16px;
    background: url(../../images/design/loading/loading-circle-black-16px.gif) no-repeat;
}
.boxError {
    padding: 5px 35px 5px 14px;
    margin: 10px 0px;
    color: #C09853;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #FCF8E3;
    border: 1px solid #FBEED5;
    border-radius: 4px;
    color: #B94A48;
    background-color: #F2DEDE;
    border-color: #EED3D7;
    font-weight: bold;
	
	&.boxSuccess {
		background: #CCE667;
		color: #383838;
		border-color: #8EC714;
	}
	
	&.boxSuggestion {
		background: #FFFABD;
		color: black;
		border-color: #F1EB4E;
	}
}

/* Menu horizontal */

ul.menu_horizontal {
    position: relative;
    list-style: none outside none;
    padding-left: 0px;
	
	> li {
		display: block;
		float: left;
		position: relative;
		// text-align: center;
		
		&.clear {
			padding: 0;
			margin: 0;
			border: none;
			width: 0;
			height: 0;
		}
		
		a {
			display: block;
		}
	}
}

@import './layout/_feed';

@import './layout/_global_bilan';

@import './pages/_home';

@import './pages/_fiche_media';

@import './pages/_fiche_serie';

@import './pages/_fiche_episode';

@import './pages/_fiche_film';

@import './pages/_fiche_jv';

@import './pages/_fiche_anime_studio';

@import './pages/_fiche_people';

@import './pages/_profil';

@import './pages/_forum';

@import './pages/_forum_admin';

@import './pages/_awards';

@import './pages/_challenge';

@import './pages/_decouvrir';

@import './pages/_listes';

@import './pages/_serie_suivi';

@import './pages/_serie_saison';

@import './pages/_calendar';

@import './pages/_listing';

@import './layout/_search';

@import './pages/_jv_suivi';

@import './pages/_trophy';

@import './pages/_retro';

@import './pages/_admin';

@import './pages/_tickets';

/* Profil */

#user_infos {
	p {
		padding-bottom: 5px;
	}
	
	.desc_profil {
		span {
			font-size: 10px;
			display: inline-block;
			padding-left: 15px;
			padding-right: 10px;
			
			&.age {
				background: url(../../images/design/icons/calendar_8px.png) no-repeat 0% 50%;
			}
			&.location {
				background: url(../../images/design/icons/home_8px.png) no-repeat 0% 50%;
			}
		}
	}
	
	#friend_button {
		position: absolute;
		top: 24px;
		right: 0px;
	}
}

.mybutton {
    border: 0;
    height: 26px;
    padding-left: 20px;
    font-size: 11px;
    font-family: Arial, verdana, sans-serif;
    font-weight: bold;
    border-radius: 4px;
	
	&:hover {
		cursor: pointer;
	}
}

.button-add {
    width: 170px;
    padding-left: 28px;
    background: #3671C3 url(../../images/design/icons/plus_16px.png) no-repeat 7px 50%;
    border: 1px solid #3F6A88;
    color: #FFF;
	
	&:hover {
		background-color: #59BDFF;
		border: 1px solid #53BAFF;
	}
}
.button-wait {
    width: 170px;
    padding-left: 25px;
    background: #EFEFEF url(../../images/design/icons/wait_16px.png) no-repeat 7px 50%;
    border: 1px solid #A0A0A0;
    color: #A0A0A0;
	
	&:hover {
		cursor: progress;
	}
}

.info_show .mybutton {
    width: 160px;
}
/* page show_serie */

#list_fan_de {
	padding: 15px 30px;
	
	li {
		float: left;
		
		a {
			display: block;
			position:relative;
			transition: all 250ms ease-in-out;
			
			&:before {
				content:'';
				display:block;
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background: $color_blue;
				opacity : 0;
			}
			
			img {
				position: relative;
				opacity: 1;
				transition: all 250ms ease-in-out;
			}
			
			&:hover {
				&:before {
					opacity: 1;
				}
				
				img {
					opacity: 0.3;
				}
			}
			
			&.active {
				opacity: 1;
			}
		}
	}
	
	&.filtered {
		li {
			a {
				opacity: 0.3;
				
				&.active {
					opacity : 1;
				}
			}
		}
	}
}

#stats_fan_de {
    padding: 15px 30px;
}

#profil_infos {
    min-height: 40px;
}
.exportexcel {
    position: relative;
    top: -3px;
}

/* Accueil série*/

ul.list_series {
    padding: 0;
}
.list_zodes_form {
    margin-bottom: 30px;
}
.list_zodes_view {
    float: right;
    /*mini résolution p-e position: absolute;right: 0px;top: 29px; */
}
.list_zodes_show {
    display: inline;
}
.list_zodes_hide {
    display: none;
}

.episode {
	.basic {
		display: inline-block;
		float: left;
	}
	
	a {
		color: #277EB8;
		
		&.seasonpremiere {
			color: $color_seasonpremiere;
		}
		&.seasonfinale {
			color: $color_seasonfinale;
		}
		&.midseasonpremiere {
			color: $color_midseasonpremiere;
		}
		&.midseasonfinale {
			color: $color_midseasonfinale;
		}
	}
}

.next_episode {
    color: silver;
}
.affichcache {
    clear: both;
}
.success_epvus {
    line-height: 18px;
    clear: both;
	
	form {
		clear: both;
	}
	
	.episodeSeen {
		display: block;
		float: left;
		width: 520px;
		text-decoration: line-through;
	}
	
	/*.twitter-share-button {
		float: right;
		padding-right: 17px;
		padding-top: 7px;
		width: 90px !important;
		height: 30px !important;
	}*/

	.seecomments {
		display: block;
		float: left;
		padding-top: 8px;
	}
}

.program_link {
	ul {
		margin: 0px;
		padding: 0px;
		border: 0px;
		height: 15px;

		li {
			display: inline;

			a {
				text-decoration: none;
				font-weight: bold;
				padding: 5px 10px 0;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.program_link li.ico_calendar a,
.program_link li.ico_rss a {
    padding: 0px;
}
.program_link img {
    border: 0;
    width: 16px;
    height: 16px;
}
.clic_program a.current {
    background-color: white;
}
.program_text {
    background-color: white;
    padding: 10px;
    margin: 0px;
    display: none;
	
	.channel {
		font-size: 0.7em;
		color: silver;
	}
	
	a {
		&.nameshow {
			font-weight: bold;
			color: black;
		}

		&.suivie {
			color: #ec0000;
		}

		&.attente, &.abandon {
			color: #750000;
		}
	}
}


.calendar1 {
    display: block;
}
.ico_calendar {
    padding: 0px;
    margin-left: 0px;
}
.ico_rss {
    padding: 0px;
}
.filtercountry {
    padding-bottom: 5px;
    border-bottom: 1px solid gray;
}

/* listes séries */

.control_h2_wide a.serielist_hide {
    display: none;
}
.wrapper_list_series {
    height: 0px;
    overflow: hidden;
}
.wrapper_list_ceremony {
    height: 0px;
    overflow: hidden;
    width: 33%;
    padding-right: 20px;
    height: 160px;
    float: left;
}
#ceremonie_top {
    margin: 10px 0px;
	
	img {
		width: 300px;
		height: 181px;
	}
}
.filter_button:hover {
    color: white;
}

.advancement_media {
    position: relative;
    top: 0px;
    height: 28px;
    line-height: 28px;
    background-color: white;
    width: 200px;
    float: left;
    // border: 1px solid #c0c0c0;
	box-shadow: 0px 0px 0 1px #C0C0C0;
	
	.advancement_progress {
		position: absolute;
		top: 0px;
		left: 0px;
		padding: 0;
		height: 100%;
		z-index: 3;
		
		&.advancement_progress_primary {
			background: #3d9bdc;
		}
		
		&.advancement_progress_secondary {
			background: #2274ad;
		}
	}
	
	.advancement_txt {
		position: relative;
		z-index: 4;
		color: black;
		text-align: center;
		line-height: 28px;
		margin: 0px;
	}
}

.percent_serie {
    float: left;
    width: 50px;
    font-size: 20px;
    font-weight: bold;
    color: silver;
    padding-left: 10px;
    text-align: right;
    margin: 0px;
    height: 28px;
    line-height: 28px;
}
ul.options {
    display: none;
    width: 150px;
    position: absolute;
    top: 26px;
    right: -120px;
    z-index: 102;
    padding-top: 0px;
    z-index: 102;
    list-style: none;
    padding-left: 0px;
	
	li {
		width: 150px;
		background-color: white;
		border-bottom: 1px solid #E0E0E0;
		margin: 0px;
		padding: 0px;
		z-index: 102;
		text-indent: 5px;
		color: black;
		
		a {
			display: block;
			padding: 5px 0px;
			color: black;
			
			&.current {
				background-color: #3570C2;
				color: white;
			}
		}
	}
}

a.extend {
    position: relative;
    display: inline-block;
    z-index: 100;
	text-indent: 0;
	background: none;
	font-size: 16px;
	color: #7d7d7d;
}
/* Liste séries avec img */

.liste_series_img {
	position: relative;
    height: 107px;
    padding: 0;
	margin: 10px -10px;
	overflow: hidden;
	
	&.slick-initialized {
		overflow: visible;
	}
	
	.show {
		float: left;
		position: relative;
		height: 100%;
		
		> a {
			position: relative;
			display: block;
			width: 170px;
			height: 100%;
			margin: 0 10px;
			transition: all 300ms ease-in-out;
			
			h3 {
				width: 100%;
				height: 22px;
				overflow: hidden;
				padding-top: 2px;
				margin: 0;
				padding-bottom: 3px;
				background-color: black;
				color: white;
				font-weight: bold;
				font-size: 1em;
				text-indent: 5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			img.pic {
				display: block;
				width: 100%;
				height: 85px;
				background: #c5c5c5;
			}

			span.next_ep {
				position: absolute;
				top: 27px;
				left: 10px;
				font-size: 35px;
				color: black;
				color: #fafafa;
				font-weight: 800;
				/* font-family: 'Roboto',sans-serif; */
				// text-shadow: 0px 0 11px #f3f3f4;
				letter-spacing: -1.5px;
				font-family: 'Oswald',Verdana,sans-serif;
				opacity: 0.4;
				transition: all 300ms ease-in-out;
			}

			span.jminus {
				position: absolute;
				bottom: 0px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				height: 27px;
				left: 0px;
				background-color: rgba(1, 1, 1, 0.5);
				color: #FFF;
				padding: 0 5px;
				
				span.when {
					font-family: 'Oswald', Verdana, sans-serif;
					font-size: 20px;
					font-weight: 300;
					text-align: right;
					line-height: 28px;
				}
				
				span.date {
					font-family: Arial, serif;
					font-size: 11px;
					font-weight: 300;
					line-height: 28px;
				}
			}
			
			&:hover {
				/*box-shadow: 0px 3px 13px 2px #b3b3b3;*/

				span.next_ep {
					opacity: 1;
					// text-shadow: 0px 0 11px #f3f3f4;
					text-shadow: 0 0 11px #000000;
				}
			}
		}
	}
	
	&.liste_series_img_encours {
		height: 125px;
		
		.show {
			> a {
				.advancement_media {
					float: none;
					width: 100%;
					border: none;
					box-shadow: none;
					background: #c5c5c5;
					height: 18px;
					line-height: 18px;
					
					.advancement_txt {
						font-size: 12px;
						line-height: inherit;
					}
				}
				
				span.jminus {
					bottom: 18px;
					
					.label {
						display: block;
						margin: auto;
						padding: 0px 8px;
						font-weight: 400;
						font-size: 12px;
						line-height: 18px;
					}
				}
			}
		}
	}
	
	.slick-track {
		margin: 0;
		padding-bottom: 15px;
	}
	
	.slick-prev, .slick-next {
        @media (max-width: 767px) {
            display: none !important;
        }

        &:before {
            color: black;
        }
	}
}

.encours_toview {
	font-size: 16px;
    margin: 20px 0;
}

/* Fiche série */

#header_plus {
	position: relative;
    background: #356EC1;
    color: white;
    margin: 0;
	
	h1, h2 {
		font-size: 22px;
		font-family: 'Oswald', Verdana, sans-serif;
		font-weight: 200;
		z-index: 10;
		position: relative;
		bottom: 0px;
		margin: 0px auto;
		padding-top: 20px;
		padding-bottom: 20px;
		
		.small {
			font-size: 14px;
			font-weight: 200;
			color: white;
		}
	}
	
	.flag, .channel, .titrefr, .year_begin, .year_end, .sexe {
		position: absolute;
		bottom: 6px;
		z-index: 10;
	}
	
	.flag, .channel {
		height: 40px;
	}
	
	.titrefr {
		color: white;
		bottom: 4px;
		font-size: 12px;
		margin: 0px;
	}

	.flag {
		right: 15px;
		bottom: 9px;
		margin: 0px;
	}
	.sexe {
		right: 60px;
		bottom: 10px;
	}
	.channel {
		right: 60px;
		bottom: 13px;
		height: 30px;
	}
	.channel img {
		height: 30px;
	}
	.year_begin, .year_end {
		right: 60px;
		color: #FFF;
		font-weight: bold;
	}
	.year_begin {
		bottom: 28px;
	}
	.year_end {
		bottom: 12px;
	}
}



.affich_serie {
    float: left;
    /* width:320px; height:160px; */
    
    width: 100%;
    max-width: 320px;
    /* max-height:160px; */
}
#slides.affich_serie {
    max-width: 630px;
}
.big_affich_serie {
    float: left;
    width: 630px;
    height: 315px;
}
.affich_award {
    float: right;
    width: 300px;
    margin-top: 10px;
}
.info_show {
    float: left;
    width: 310px;
    padding: 0 10px;
    padding-bottom: 10px;
}
.info_show h3 {
    font-weight: bold;
    padding: 5px 0px;
    margin: 0;
}
.info_show a.seemore {
    color: #B3D7EF;
}
#info_serie {
    padding-top: 10px;
}
#show_controls {
    background-color: #E3F0F9;
    color: black;
}
#show_controls .info_show {
    height: 85px;
    width: 290px;
}
#show_controls .info_show.firstblock {
    width: 300px;
}
#menu_fiche_serie {
    margin-top: 25px;
    margin-bottom: 15px;
    border-bottom: 4px solid #3671C3;
    height: 23px;
}
#menu_fiche_serie li a {
    display: block;
    float: left;
    padding: 5px 20px;
}
#menu_fiche_serie li a.current {
    font-weight: bold;
    background-color: #3671C3;
    color: white;
}
#synopsis_serie,
#synopsis,
#infos_serie {
    line-height: 20px;
    font-size: 15px;
}
#social_toolbar p {
    margin: 0px;
}
#seriemoyenne h4 {
    clear: left;
    padding: 10px 0px;
    margin: 0px;
    font-size: 13px;
}
.notestars,
.noteserie,
.noteepisode {
    float: left;
}
.notestars {
    display: block;
    width: 230px;
    height: 20px;
    overflow: hidden;
}
.noteserie,
.noteepisode {
    font-size: 12px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    width: 40px;
    text-align: center;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    float: left;
    margin-left: 10px;
    padding: 2px 0px;
}

/* breadcrumbs     thx http://blog.jaysalvat.com/articles/breadcrumbs-en-css.php  */

.breadcrumbs {
    font: 11px Arial, sans-serif;
    color: #111;
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    overflow: hidden;
	
	li {
		float: left;
		padding: 0 20px 0 10px;
		height: 28px;
		line-height: 28px;
	}
	
	a {
		font-size: 15px;
		font-weight: bold;
		text-decoration: none;
		color: #999;
		
		&.selectionned {
			color: #A7342F;
		}
		
		&:hover {
			color: #A7342F;
			text-decoration: none;
		}
	}
}
/* end breadcrumbs  */

.saison-content {
    position: relative;
	
	h2 {
		padding: 0px 10px;
		padding-bottom: 5px;
		font-size: 30px;
		position: absolute;
		display: none;
	}
	
	p.deroulante {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		text-align: center;
		
		a {
			display: block;
			padding: 7px 0px;
			background: url(../../images/serie/season_hover.png) no-repeat 50% 100%;
			background-position: 50% 100px;
			
			&:hover {
				background-position: 50% 100%;
			}
		}
	}
	
	li {
		padding: 0px 10px;
		position: relative;
		top: 0px;
		left: 0px;
		height: 50px;
		border-bottom: 1px solid #eee;
		padding-top: 5px;
		
		span {
			display: block;
			float: left;
			min-height: 10px;
			padding-top: 5px;
		}
	}
}

.episode_number {
    width: 35px;
    text-align: right;
    padding-right: 5px;
}
.episode_title, .episode_titlevf {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}
.episode_title {
    font-weight: bold;
    position: relative;
}
.episode_title_inconnu {
    font-weight: normal;
    font-style: italic;
}
.episode_titlevf {
    position: absolute;
    top: 20px;
    left: 50px;
}
.episode_title .edit, .episode_title .delete {
    position: absolute;
    z-index: 100;
}
.episode_title .edit {
    right: 40px;
}
.episode_title .delete {
    right: 20px;
}
.episode_diffusion {
    width: 90px;
    text-align: center;
}
.episode_audience {
    width: 90px;
}
.special_runtime {
    position: absolute;
    left: 650px;
    top: 22px;
    font-size: 11px;
    width: 90px;
    text-align: center;
}
.episode_duree {
    width: 35px;
    text-align: right;
}
.episode_comments {
    width: 110px;
    height: 32px;
    padding-top: 6px;
    text-align: left;
    font-size: 10px;
    display: block;
    float: left;
    color: #277EB8;
    position: relative;
    top: 0px;
    left: 0px;
}
.nbre_votes {
    position: absolute;
    top: 25px;
    left: 797px;
    font-size: 10px;
}
.episode_note,
.episode_moyenne {
    width: 50px;
    font-weight: bold;
    font-size: 18px;
}
.episode.legende {
    font-weight: normal;
    color: black;
    height: auto;
    font-weight: bold;
    padding: 5px 10px;
}
.episode.legende .episode_number {
    width: 545px;
}
/*.episode.legende label { font-weight: normal; font-size: 10px; }*/

.episode.legende .episode_comments,
.episode.total .episode_comments {
    height: auto;
    background: none;
}
.episode.legende.total {
    border-bottom: 0px;
}
a.deroule {
    width: 795px;
    height: 100%;
    display: block;
    text-indent: -9000px;
    position: absolute;
    top: 0px;
    left: 47px;
	
	&.btn {
		width: auto;
		height: auto;
		display: inline;
		text-indent: 0px;
		position: relative;
		top: auto;
		left: auto;
	}
}

.ep_nondiff a.deroule {
    cursor: default;
    width: 940px;
}
.episode a.comments_link {
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 27px;
    line-height: 27px;
    text-decoration: none;
}
.episode a.comments_link.comments_link_empty {
    color: rgba(39, 126, 184, 0.31);
}
.ep_vu {
    background: #d2e2ff;
}
.ep_nondiff,
#corps .ep_nondiff a {
    background: #FFF;
    color: gray;
}
#loadingnote2 {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    padding-top: 50px;
    background: url(../../images/loading2.gif) no-repeat center top;
}
#systeme_notation {
    border: 1px solid #CCCCCC;
    padding: 5px 20px;
    margin: 20px 5px 5px;
    border-radius: 5px;
	
	legend {
		padding: 5px 0px;
		font-weight: bold;
	}
	
	.color_note {
		float: left;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		margin: auto;
	}
	
	p {
		float: left;
		padding-left: 10px;
		width: 150px;
		
		span {
			font-size: 0.8em;
		}
	}
}

#source_seriemania {
    margin-top: 20px;
    padding: 10px 20px;
	
	a {
		color: #2874ff;
		
		&:hover {
			text-decoration: underline;
		}
	}
}

.update_episode {
    margin: 0px 5px;
    width: 90px;
    padding: 5px;
}
.admin_bar {
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
	
	input[type=text] {
		width: 30px;
		text-align: center;
		padding: 2px;
		margin: 5px;
		
		&.part_name {
			text-align: left;
			width: 200px;
		}
	}
		
	input[type=submit] {
		width: 50px;
		text-align: center;
		padding: 2px;
		margin: 5px;
	}
}

.table .comments_zone_line td {
    height: 0px;
    padding: 0px;
    border: none;
}
.btn-primary.btn-notinterested {
    background-color: #8e8e8e;
    border-color: #757575;
}

/* Options */

.myTabSection {
    background: white;
    padding: 10px 20px;
    border: 1px solid #E0E0E0;
}
#optionsSeriebox {
	.formField {
		clear: both;
		padding: 5px 0px;
		
		label {
			display: block;
			float: left;
			clear: left;
			width: 140px;
			text-align: right;
			margin-right: 20px;
			padding-top: 9px;
		}
		
		input.text, textarea {
			float: left;
			border: 1px solid #E0E0E0;
			border-radius: 3px;
			padding: 6px 10px;
			outline-color: #3D99DB;
			border: 1px solid #cccccc;
			border-radius: 3px;
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
			transition: border linear 0.2s, box-shadow linear 0.2s;
		}
		
		input.text {
			width: 170px;
			margin-right: 10px;
			
			&:focus {
				border-color: rgba(82, 168, 236, 0.8);
				outline: 0;
				box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
			}
		}
		
		textarea {
			width: auto;
		}
		
		select {
			float: left;
			border: 1px solid #E0E0E0;
			padding: 4px 5px;
		}
		
		.listRadio {
			float: left;
			
			.radio {
				float: left;
				clear: left;
				margin-top: 8px;
			}
			
			label {
				clear: none;
				width: auto;
				text-align: left;
			}
		}
	}
	
	.checkboxLeft {
		label {
			width: auto;
			text-align: left;
			clear: none;
		}
		
		input {
			float: left;
			margin-right: 20px;
			margin-left: 130px;
		}
	} 
	
	.checkboxList {
		label {
			clear: none;
			text-align: left;
			width: auto;
		}

		input {
			float: left;
			clear: left;
		}
	}
	
}

#redimensionner {
    position: relative;
    top: 0px;
    left: 0px;
    float: left;
}
#avatar_carre_temp {
    overflow: hidden;
    float: left;
    width: 150px;
    height: 150px;
    position: relative;
	
	img {
		position: absolute;
		top: 0px;
		left: 0px;
	}
}

.opt_infos_supp {
    color: silver;
    margin: 5px 0px;
    clear: both;
}

/* accueil films */

.last_movie_comments {
	li {
		position: relative;
		min-height: 110px;
		margin-bottom: 10px;
		border-bottom: 1px solid #EAF4FB;
		padding-bottom: 5px;
	}
	
	img.mincouv {
		width: 75px;
		height: 100px;
		margin: 5px;
		position: absolute;
		top: 0px;
		left: 0px;
		border: 1px solid gray;
	}
	
	h3, .comment {
		margin-right: 65px;
	}
	
	h3 {
		margin-top: 5px;
		margin-bottom: 5px;
		font-weight: bold;
		font-size: 18px;
	}
	
	.username {
		font-weight: bold;
	}
	
	.note_user {
		color: #A7A7A7;
	}
	
	.comment {
		background-color: #eaf4fb;
		padding: 5px;
		line-height: 17px;
		font-size: 11px;
		border-radius: 5px;
	}
	
	.date_comment {
		font-size: 10px;
		text-align: center;
		padding-right: 5px;
		color: #999999;
	}
} 



ul#last_comments {
	li {
		position: relative;
		min-height: 110px;
		margin-bottom: 10px;
		border-bottom: 1px solid #EAF4FB;
		padding-bottom: 5px;
	}
	
	img.mincouv {
		width: 75px;
		height: 100px;
		margin: 5px;
		position: absolute;
		top: 0px;
		left: 0px;
		border: 1px solid gray;
	}
	
	h3, .comment {
		margin-right: 65px;
	}
	
	h3 {
		margin-top: 5px;
		margin-bottom: 5px;
		font-weight: bold;
		font-size: 18px;
	}
	
	.username {
		font-weight: bold;
	}
	.comment {
		background-color: #eaf4fb;
		padding: 5px;
		line-height: 17px;
		font-size: 11px;
		border-radius: 5px;
	}
	.date_comment {
		font-size: 10px;
		text-align: center;
		padding-right: 5px;
		color: #999999;
	}
}

.last_movies {
    display: inline-block;
    margin: 5px;
    padding: 3px;
    border: 1px solid silver;
    background: white;
	
	img {
		width: 75px;
		height: 100px;
		float: left;
	}
	
	span {
		position: absolute;
		bottom: 3px;
		left: 3px;
		height: 20px;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		width: 75px;
		text-align: center;
		padding-top: 3px;
	}
}

/* sorties semaine */

#sorties_semaines {
    position: relative;
	
	#move_in_time {
		position: absolute;
		right: 10px;
		top: -47px;
		font-weight: bold;

		.sorties_nav {
			position: relative;
			z-index: 10;
			display: block;
			float: left;
			visibility: hidden;
			width: 30px;
			height: 30px;
			text-align: center;
			font-size: 15px;
			text-indent: -9000px;

			&.inactive {
				opacity: 0.3;
			}

			&.previous {
				background: url(../../images/fancy_nav_left.png) no-repeat 50% 50%;
				left: 0px;
			}

			&.next {
				background: url(../../images/fancy_nav_right.png) no-repeat 50% 50%;
				right: 0px;
			}
		}
		
		.sorties_dates {
			float: left;
			line-height: 30px;
			padding: 0 30px;
		}
	}
}

#movie_poster_wrapper {
    position: relative;
	
	#movie_poster {
		text-align: center;
		font-weight: bold;
		text-align: center;
		z-index: 20;
		padding-top: 5px;

		img {
			width: 160px;
			height: 210px;
			background-color: white;
			padding: 5px;
			border: 1px solid silver;
		}

		.week_movie {
			float: left;
			padding: 0px 10px;
			position: relative;
			margin-bottom: 20px;
			height: 245px;
			
			&.iwishthatmovie, &.isawthatmovie {
				.icon {
					position: absolute;
					top: -4px;
					right: -4px;
					display: block;
					width: 60px;
					height: 60px;
				}
			}
			
			&.iwishthatmovie .icon {
				background: url(../../images/cine/ribban_wish.png) no-repeat;
			}
			
			&.isawthatmovie .icon {
				background: url(../../images/cine/ribban_vu.png) no-repeat;
			}
			
			.picture {
				position: relative;
				display: block;
				width: 160px;
			}
			
			.btn {
				padding: 4px 8px;
				margin: 2px;
			}
			
			.menu_horizontal {
				margin-left: 2px;
				margin-top: 4px;
				position: absolute;
				top: 150px;
			}
		}
		
		.sorties_wish {
			position: absolute;
			bottom: 6px;
			right: 6px;
			width: 40px;
			height: 40px;
			background: $color_blue;
			color: white;
			line-height: 40px;
			border-top: 1px solid #C0C0C0;
			border-left: 1px solid #C0C0C0;
			background: #FFF;
			color: #424242;
		}
		.sorties_moyenne {
			position: absolute;
			bottom: -1px;
			left: -14px;
			background: url(../../images/design/icons/star_big_moy_50px.png) no-repeat 50% 50%;
			color: #424242;
			width: 50px;
			height: 50px;
			line-height: 50px;
		}
		div.current {
			display: block;
		}
		
		p.infos_movies {
			font-size: 10px;
			padding: 5px 15px;
		}
	}
}

#movie_list {
    height: 100%;
    list-style-type: none;
    list-style-position: inside;
    margin: 0;
    padding: 10px 2px 10px 10px;
    margin-left: 180px;
	
	> li {
		position: relative;
		margin: 1px 0px;
		padding: 0px 5px;
		
		&.current, &:hover {
			background-color: #E2EBF1;
			border-radius: 10px;
		}
	}
	
	li {
		a.title_movie {
			text-decoration: none;
			color: black;
			font-weight: bold;
			display: block;
			margin: 0;
			padding: 5px 25px;
			
			&:hover {
				text-decoration: none;
			}
		}
		
		a span.nbrewishmovie {
			font-weight: normal;
		}
		
		&.nbrewishmovie {
			font-size: 0.8em;
			padding-top: 20px;
			
			
			&:hover {
				background-color: #E2EBF1;
			}
		}
		
		a.extend {
			position: absolute;
			right: 10px;
			top: 2px;
		}
	}
}

#movie_list ul.options {
    top: 0px;
    right: -145px;
}

#move_in_world {
    position: absolute;
    right: 321px;
    top: -49px;
}

/* Fiche film */
#informationsMovie {
	h3 {
		font-size: 14px;
		margin: 10px 0px;
	}
	
	div.visioMovie {
		margin-bottom: 10px;
		height: 70px;
	}
	
	div.noteMoyenne {
		margin-bottom: 20px;
	}
}

#nominations_awards {
    position: absolute;
    top: 10px;
    right: 200px;
    z-index: 10;
    background-color: white;
    padding: 5px;
}
#troisd {
    position: absolute;
    top: 1px;
    right: 166px;
    z-index: 10;
}
#date_sorties {
    clear: left;
    padding: 5px 0px;
	
	img {
		width: 22px;
		vertical-align: middle;
	}
	
	.date {
		display: inline-block;
		text-align: left;
		padding-left: 10px;
	}
	
	.diff_date {
		color: #A7A7A7;
	}
}

#add_movie label.error {
    color: red;
}
.moyenne_display {
    background: #000;
    height: 20px;
    text-align: right;
    padding: 2px 5px;
    color: white;
	
	&.vu {
		background: #356DC0;
	}
	
	&.wish {
		background: $color_wish;
	}
}

h3.one_ligne {
    float: left;
    width: 110px;
}
p.one_ligne {
    float: left;
    padding: 5px 0px;
    margin: 0px;
}

.visionnage {
    position: relative;
	
	.twitter-share-button, .shareFacebook {
		display: none;
		position: absolute;
		top: 4px;
		left: 220px;
	}
	
	.shareFacebook {
		top: 30px;
		font-weight: bold;
		padding: 1px 3px 1px 18px;
		text-align: right;
		width: 71px;
		
		.icon {
			display: block;
			position: absolute;
			top: 4px;
			left: 4px;
			width: 12px;
			height: 12px;
			background: url('../../images/design/icons/facebook-logo-12x12.jpg') no-repeat 0 0;
		}
	}
	
	&.current {
		.twitter-share-button, .shareFacebook {
			display: block;
		}
	}
}

a.btn#nothing {
    display: block;
    width: 60px;
    height: 30px;
    text-indent: -9000px;
    float: left;
    background: url('../../images/cine/bouton_delete_off.png') no-repeat;
    width: 35px;
    display: none;
    padding: 0px;
    margin: 0px;
    border: 0;
    position: relative;
    top: -1px;
	
	&.affich {
		display: block;
	}
	&:hover {
		background: url('../../images/cine/bouton_delete_on.png') no-repeat;
	}
}

.wrapper_onglet {
    clear: left;
}
.wrapper_onglet_movie {
    height: 170px;
    max-height: 170px;
    background-color: #EEEEEE;
    overflow: hidden;
	
	.onglet {
		height: 170px;
		max-height: 170px;
	}
}

ul.nav#nav_filtres {
	> li > a {
		padding: 4px 5px;
		background: #ECECEC;
		color: #747474;
	}
}

.casting {
	li {
		height: 80px;
		float: left;
		line-height: 2em;
		margin-bottom: 15px;
		
		&.serie {
			width: 220px;
			margin-right: 4px;
		}
		&.casting_film {
			width: 220px;
			margin-right: 4px;
		}
		
		img.miniature {
			float: left;
			margin-right: 5px;
			border-radius: 5px;
		}
		
		h4 {
			font-size: 16px;
			line-height: 16px;
			font-weight: bold;
			width: 130px;
			margin: 0;
			float: left;
			word-wrap: break-word;
		}
		
		p {
			font-size: 12px;
			line-height: 12px;
			float: left;
			word-wrap: break-word;
			width: 130px;
		}
	}
}

#castingMovie.casting li h4 {
    width: auto;
    float: none;
}
#castingMovie.casting li p {
    width: auto;
    float: none;
}
.casting li .perso {
    color: #000;
}
.castingserie h3 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
    padding-bottom: 5px;
    margin: 0;
    line-height: 33px;
}
.casting li p.movieRole {
    text-transform: uppercase;
    font-size: 11px;
    color: #B1B1B1;
}
#castingMovie ul.actor {
    float: left;
    margin: 0;
}

.comments_list {
	> li {
		padding: 5px 0px 10px 60px;
		position: relative;
		min-height: 50px;
		border-bottom: 1px solid #EEE;
		height: auto;
		
		.avatar_member {
			position: absolute;
			top: 5px;
			left: 5px;
			margin-right: 5px;
			border-radius: 100%;
			border: 3px solid #bbbdbb;
			
			&.connected {
				border-color: #34ad13;
			}
			
			img {
				display:block;
				border-radius: 100%;
			}
		}
		
		.date {
			display: block;
			font-size: 10px;
			color: #999999;
			float: none;
			
			.seconds {
				display: none;
			}
		}
		
		.commentateur, .chronique {
			font-size: 14px;
			font-weight: bold;
			padding: 5px 0px;
			display: block;
		}
		
		.commentateur {
			font-family: 'Oswald', Arial, sans-serif;
			font-size: 22px;
			font-weight: normal;
			line-height: 20px;
			padding-bottom: 10px;
		}
	}
}

.commentaire {
    font-size: 14px;
    line-height: 20px;
    padding-right: 10px;
    padding-bottom: 5px;
    text-align: justify;
	word-wrap: break-word;
}

.comments li:hover .comment_admin,
.comments li:hover .comment_admin a {
    color: $color_blue;
}

input#chronique {
    width: 386px;
}

/* derniers comms épisodes j'ai vu */

ul#last_comments.last_comm_eps img {
    width: 150px;
    height: 75px;
    margin: 0px;
}
ul#last_comments.last_comm_eps li {
    padding-left: 170px;
}
.spoileralert {
    color: black;
    font-size: 10px;
    font-weight: bold;
}
.spoiler {
    color: #FAFAFA;
}
/* Meilleurs films */

#percent_vus {
    font-weight: bold;
    font-size: 1.4em;
    margin: auto;
    text-align: center;
    background-color: #E2EBF1;
    padding: 5px 0px;
    border-radius: 8px;
}

.legendView {
    margin: 20px 0px;
    background: #E2E2E2;
    padding: 5px 10px;
	
	span {
		display: inline-block;
		margin-left: 10px;
		margin-right: 5px;
		width: 10px;
		height: 10px;
		vertical-align: middle;
	}
	.nonvu {
		background: black;
	}
	.vu {
		background: #3570C2;
	}
	.wish {
		background: $color_wish;
	}
}


.nb_films_search {
    text-align: center;
    line-height: 34px;
    font-size: 17px;
    font-family: 'Oswald', Verdana, sans-serif;
    color: #3D3D3D;
}
#trier_par {
    font-size: 1.1em;
    border-bottom: 2px solid silver;
    padding: 4px;
}
.triage {
    padding: 5px 0px;
}
.triage > form > ul.menu_horizontal {
    margin: 0;
}
.triage select {
    width: 100%;
}

.btn-primary
	background-color $color_primary

	&.wish
		background-color $color_wish
		border-color $color_wish

.btn-danger
	&.pasvu
		background #000
		border-color #000

/* Membres.php */

#search_user {
    background-color: #333333;
    color: white;
    margin: auto;
    padding: 5px;
}
/* Rate episode */

.votons {
    display: inline-block;
    height: 20px;
    width: 110px;
    position: relative;
    top: 5px;
	
	&:hover {
		opacity: 1;
	}
	
	a:hover {
		cursor: pointer;
	}
}
.casevote {
    float: left;
    display: block;
    width: 5px;
    height: 20px;
    background-color: silver;
}
.casesuppr {
    background-color: white;
    border-radius: 0px;
}
.crossblack {
    background: url("images/chk_off2.png") no-repeat 50% 50%;
}
.textvote {
    display: none;
}
.bgvide {
    background-color: #F1F1F1;
}
.vote_0 {
    color: #7F0000;
}
.vote_1 {
    color: #7D0A01;
}
.vote_2 {
    color: #7A1602;
}
.vote_3 {
    color: #772003;
}
.vote_4 {
    color: #742E04;
}
.vote_5 {
    color: #723605;
}
.vote_6 {
    color: #734204;
}
.vote_7 {
    color: #755103;
}
.vote_8 {
    color: #775E02;
}
.vote_9 {
    color: #796B01;
}
.vote_10 {
    color: #7B7800;
}
.vote_11 {
    color: #717400;
}
.vote_12 {
    color: #697100;
}
.vote_13 {
    color: #5E6D00;
}
.vote_14 {
    color: #546900;
}
.vote_15 {
    color: #4C6600;
}
.vote_16 {
    color: #456A00;
}
.vote_17 {
    color: #3B6F00;
}
.vote_18 {
    color: #307400;
}
.vote_19 {
    color: #257900;
}
.vote_20 {
    color: #1B7E00;
}
/* fiche saga */

#tablesaga {
    border-collapse: collapse;
    width: 100%;
	
	tr {
		border-bottom: 1px solid #DFDFDF;
		
		&.vu {
			background-color: #C1D7FF;
		}
		
		&.wish {
			background-color: #FFF5FA;
		}
	}
	
	th {
		font-weight: bold;
		text-align: left;
		padding: 5px 0px;
	}
	
	td {
		vertical-align: top;
		padding-top: 10px;
		line-height: 25px;
		
		&.td_number {
			font-weight: bold;
			text-align: center;
			padding: 5px;
			font-size: 18px;
			vertical-align: middle;
			width: 30px;
			vertical-align: top;
		}
		
		&.td_affiche {
			width: 80px;
			text-align: center;
		}
		&.td_title {
			padding: 10px 27px 5px 5px;
			position: relative;
			
			a.extend {
				position: absolute;
				top: 10px;
				right: 5px;
			}
		}
		&.td_real {
			padding-left: 5px;
		}
		&.td_annee {
			width: 50px;
		}
		&.td_moyenne {
			width: 200px;
			max-width: 200px;
		}
	}
}

/* Page Who Watch = qui regarde cette série */

#who_watch {
    width: 850px;
    padding: 0px;
    margin: 0px;
    margin-left: 50px;
    margin-top: 10px;
	
	li {
		width: 850px;
		font-size: 10px;
		
		&:hover {
			background-color: #e0e0e0;
		}
	}
	
	.member, span {
		display: inline-block;
		vertical-align: middle;
	}
	
	.member {
		width: 200px;
	}
	.nbre_zode_vus {
		width: 300px;
	}
	.tps_passe {
		width: 150px;
		font-size: 10px;
	}
	.note {
		width: 200px;
	}
	.avancement_serie {
		border: 0px;
	}
	.total {
		list-style: none;
		font-weight: bold;
	}
}

/* tooltip */

.infoLevel {
    display: none;
    position: absolute;
    top: 33px;
    right: -25px;
    padding: 10px;
    z-index: 1000;
    overflow: visible;
    background: #b1e0ee;

	h4 {
		margin: 0px;
		font-size: 18px;
		color: #015992;
	}

	table th {
		font-weight: bold;
		text-align: left;
	}
	
	.arrow {
		background: url(../../images/design/tooltip_arrow.png) no-repeat 0% 50%;
		width: 25px;
		height: 12px;
		display: block;
		position: absolute;
		z-index: 1009;
	}
	#column1 {
		width: 150px;
	}
	#column2 {
		width: 160px;
	}
}

#quicklinks {
    display: none;
    position: fixed;
    right: 5%;
    top: 80%;
    z-index: 100;
	
	a {
		display: block;
		text-indent: -9999px;
		width: 48px;
		height: 48px;
		padding: 10px;
		background: black;
		border-radius: 10px;
		opacity: 0.5;
	}
	
	#return_up {
		background: black url(../../images/design/icons/up_48px.png) no-repeat 50% 50%;
	}
	
	@media (max-width: 767px) {
		display: none !important;
	}
}

/* jRating */

.jRatingAverage {
    background-color: #00152c;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
}
.jRatingColor {
    background-color: #1B89FF;
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
}
.jStar {
    position: relative;
    left: 0;
    z-index: 3;
	background: url(/images/jrating/starsf5f9fd.png) repeat-x;
}
p.jRatingInfos {
    position: absolute;
    z-index: 99999;
    background: transparent url('../../images/jrating/bg_jRatingInfos.png') no-repeat;
    color: #FFF;
    display: block;
    width: 91px;
    height: 28px;
    font-size: 20px;
    text-align: center;
    padding-top: 6px;
}
p.jRatingInfos span.maxRate {
    color: #c9c9c9;
    font-size: 14px;
}

/*********************************************/
/* FAQ */

#faq_nav a {
    padding: 10px;
}
.faq_section {
    padding: 20px 10px;
}
.faq_section h2 {
    font-size: 30px;
    font-weight: normal;
    display: none;
    margin: 10px 0px 30px;
}
.faq_section .faq_question {
    font-size: 16px;
    font-family: 'Roboto', Verdana, sans-serif;
    font-weight: 500;
    margin: 10px 0px;
}
.faq_section .faq_explication {
    display: none;
    font-size: 14px;
    background: #e7ecf3;
    padding: 15px;
}
/*********************************************/
/* Admin */

.form_admin label {
    width: 200px;
    display: block;
    float: left;
    clear: left;
    padding: 6px;
}
.form_admin label.floatleft {
    float: left;
    clear: none;
}
.form_admin input,
.form_admin select,
.form_admin textarea {
    float: left;
}
.form_admin span.infos {
    float: left;
}
.form_admin .submit {
    clear: both;
}
.form_admin #choice {
    margin: 10px 0px;
}
.form_admin #choice label {
    display: inline;
    float: none;
}
.form_admin #choice input {
    float: none;
}
.form_admin #choice p {
    margin-left: 5px;
}
.form_admin .specials {
    margin: 10px 0px;
    display: none;
}
.form_admin #serie_choice {
    display: block;
}
.form_error {
    color: red;
}
.formElement {
    clear: both;
}

/***** Beautiful Tables *****/

.table tr.odd:hover,
.table tr.even:hover {
    background: #f9f9f9;
}
.table.specialBorderLeft tr {
    border-left: 3px solid white;
}
.table.specialBorderLeft tr.odd:hover,
.table.specialBorderLeft tr.even:hover {
    border-left: 3px solid #2ab1ff;
    transition: all 0.5s ease;
}
/*** Systèmes de listes ***/

.addToList {
    margin: 10px 0px;
}
.addToList .btn-group {
    float: left;
}
.addToList .loadingList {
    float: left;
    margin: 5px 10px;
    visibility: hidden;
}
.addToList .mediaInDaList {
    float: left;
    margin-top: 10px;
    line-height: 20px;
}
.addToList .mediaInDaList .delete img {
    vertical-align: middle;
}
.inputNewListName {
    display: none;
    padding: 5px 10px;
    margin: 5px 20px;
    width: 75%;
}
ul.nav.nav-pills-small {
    margin: 10px 0;
	
	> li > a {
		font-family: Arial, sans-serif;
		font-size: 11px;
		padding: 5px;
		background: #EFEFEF;
		margin-right: 5px;
	}
}

.nav-pills.nav-pills-small>li.active>a,
.nav-pills.nav-pills-small>li.active>a:hover,
.nav-pills.nav-pills-small>li.active>a:focus {
    background-color: #356EC1;
}

ul.nav.nav-pills.nav-pills-narrow {
	a {
		padding: 6px 12px;
	}
}

/***** Tabbable ******/

.tabbable:before,
.tabbable:after {
    content: " ";
    display: table;
}
.tabbable:after {
    clear: both;
}
.tabbable-custom {
    margin: 15px 0;
    padding: 0;
    overflow: hidden;
}
.tabbable-custom > .nav-tabs {
    border: none;
    margin: 0;
	padding: 0;
}
.tabbable-custom > .nav-tabs > li {
    margin-right: 2px;
    border-top: 2px solid transparent;
}
.tabbable-custom > .nav-tabs > li > a {
    margin-right: 0;
    border-radius: 0;
}
.tabbable-custom > .nav-tabs > li > a:hover {
    background: none;
    border-color: transparent;
}
.tabbable-custom > .nav-tabs > li.active {
    border-top: 3px solid #356EC1;
    margin-top: 0;
    position: relative;
}
.tabbable-custom > .nav-tabs > li.active > a {
    border-top: none !important;
    font-weight: 400;
    border-radius: 0;
}
.tabbable-custom > .nav-tabs > li.active > a:hover {
    border-radius: 0;
    border-top: none;
    background: #fff;
    border-color: #d4d4d4 #d4d4d4 transparent;
}
.tabbable-custom > .tab-content {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 0 0 4px 4px;
}
.tabbable-custom.nav-justified > .tab-content {
    margin-top: -1px;
}
.tabbable-custom.boxless > .tab-content {
    padding: 15px 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
}
.tabbable-custom.tabs-below.nav-justified .tab-content {
    margin-top: 0px;
    margin-bottom: -2px;
    border-radius: 4px 4px 0 0;
}
.tabbable-custom.tabbable-full-width > .nav-tabs > li > a {
    color: #424242;
    font-size: 15px;
    padding: 9px 15px;
}
.tabbable-custom.tabbable-full-width > .tab-content {
    padding: 15px 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li > a {
    border-top: none;
    border-bottom: 2px solid transparent;
    margin-top: -1px;
}
.tabbable-custom.tabs-below .nav-tabs > li.active {
    border-top: none;
    border-bottom: 3px solid #d12610;
    margin-bottom: 0;
    position: relative;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a {
    border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a:hover {
    background: #fff;
    border-color: #d4d4d4 #d4d4d4 transparent;
}
.tabbable-custom.tabbable-noborder > .nav-tabs > li > a {
    border: 0;
}
.tabbable-custom.tabbable-noborder .tab-content {
    border: 0;
}
.pagination>li>span.currentPage {
    background: #DBEAFF;
	background: $color_darkblue;
    color: #fff;
}
.ticket_item_header {
    width: 120px;
}
.ticket_comment_text {
    background: #ECECEC;
    padding: 10px;
}

.information_tickets {
    margin: 15px 0;
	
	.panel-heading {
		background-color: #d9edf7;
	}
	
	.panel-body {
		h2 {
			margin-bottom: 15px;
			font-size: 17px;
		}

		ul {
			list-style: disc;
			padding-left: 20px;
		}
	}
}

/* Media Queries */

@media (max-width: 767px) {
    ul#last_comments.last_comm_eps li {
        padding-left: 0px;
    }
}
@media screen and (min-width: 200px) and (max-width: 960px) {
    .hidemobile {
        display: none;
    }
}
@media only screen and (min-width: 200px) and (max-width: 640px) {
    /* accueil site */
    .showlistmedia li {
        width: 200px;
    }
    /* accueil séries */
    .saison-content li {
        height: auto;
    }
    .episode_number {
        width: 20%;
        padding-right: 0px;
    }
    .episode_title,
    .episode_titlevf {
        width: 80%;
        min-width: auto;
        max-width: auto;
    }
    .saison-content li span.episode_note,
    .saison-content li span.episode_moyenne,
    .saison-content li span.nbre_votes {
        display: none;
    }
}
/* Print Page */

@media print {
    * {
		background: transparent !important;
        color: #444 !important;
        text-shadow: none;
    }
    a,
    a:visited {
        color: #444 !important;
        text-decoration: underline;
    }
    a:after {
        content: " (" attr(href) ")";
    }
    abbr:after {
        content: " (" attr(title) ")";
    }
    .ir a:after {
        content: "";
    }
    /* Don't show links for images */
    
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    img {
        page-break-inside: avoid;
    }
    @page {
        margin: 0.5cm;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}
@media only screen and (min-width: 200px) and (max-width: 640px) {
    #header_plus h2 {
        font-size: 24px;
        padding: 10px 0px;
    }
}

// test gradient home
.blend-gradient {
  background: linear-gradient(-61deg, rgb(114, 30, 102), rgb(66, 139, 202));
}

/* Index admin */
.chart {    
	position: relative;
	display: inline-block;
	width: 110px;
	height: 110px; 
	margin: 10px; 
	text-align: center; 
	
	a { 
		position:relative;
		z-index:10;
		
		span.percentage {
			display: inline-block;
			z-index: 2;
			margin-top: 34px;
			font-size: 18px;
		}
	}
	
	canvas {
		position: absolute;
		top: 0;
		left: 0;
	}
}

/* Classement ciné */
#modal_filter_managing .modal-body {
	height: 60vh;
	overflow: auto;
}


/* Dark mode */
@import './mode/_dark';
.hide
  position absolute
  height 1px
  width 1px
  overflow hidden
  clip rect(1px, 1px, 1px, 1px)

@media screen and (max-width: --breakpoint-sm-min)
  .xs-hide
    display none!important

@media screen and (max-width: --breakpoint-md-max) and (min-width: --breakpoint-sm-min)
  .sm-hide 
    display none!important

@media screen and (max-width: --breakpoint-lg-max) and (min-width: --breakpoint-md-min)
  .md-hide 
    display none!important

@media (min-width: --breakpoint-lg-min)
  .lg-hide
    display none!important

.display-none
  display none!important


@media screen and (min-width: --breakpoint-sm-max)
  .xs-show
    display none!important
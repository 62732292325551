.discover_big_title {
	background: #356EC1;
    color: #fff;
    margin-top: 0;
    text-align: center;
    padding: 30px 0px;
}

.container_discover_items {
	margin-top: 30px;
	margin-bottom: 30px;
	
	.btn-d {
		display: block;
		background: #fff;
		padding: 20px;
		text-align: center;
		box-shadow: 0px 5px 20px #c1c1c1;
		border-radius: 0px;
		font-size: 22px;
		font-weight: bold;
		transition: all 300ms ease-in-out;
		
		&:hover {
			background: #e6e6e6;
		}
	}


	.row2 {
		margin-top: 30px;
		
		.btn-d {
			font-size: 18px;
		}
	}
}


.menu_service_vod
	> a 
		display:flex;
		align-items: center
		justify-content: center
		width:200px;
		height:100px;
		background:black;
		border: 2px solid #4a4a4a;

		img 
			width 150px

		&:hover 
			background: #303030

h1.service_vod_title
	span 
		text-transform: uppercase;
		color: white
		font-size: 26px;
		letter-spacing: 0.05em;

.service_vod_day
	h2
		color:white;
		font-size:30px;
		font-style:italic;

	.service_vod_items
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.vod_item
			margin 0 20px
			margin-bottom 30px
			text-align: center;

			&.vod_movie
				width 200px

			&.vod_show
				width 300px
				color white
				text-transform: uppercase
				font-size 16px

				img 
					width 100%
					margin-bottom 15px
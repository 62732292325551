// Jeux en cours
.ongoing_games
	margin-top: 15px;

	@media (max-width 967px)
		padding-left: 0;
		padding-right: 0;

	h2 
		font-size: 18px;

		&.secondary
			margin-top: 30px;

		@media (max-width 967px)
			padding-left: 10px;
			padding-right: 10px;

	.ongoing_head
		position: sticky;
		top: 60px;
		background: white;
		width: 100%;

		p 
			font-weight: bold

	.game
		display flex
		padding 5px 0
		min-height: 80px;

		@media (max-width 991px)
			padding 10px 0

		@media (max-width 767px)
			padding 20px 0

		&.even
			background #efefef

		.col-cover
			img 
				width 50px

		.col-infos
			position relative

			@media (max-width 767px)
				flex-direction: column;

		.game_info_link
			@media (max-width 991px)
				width 100%

			div.game_info
				padding 0

				span.year
					margin-bottom: 3px
				
				span.title
					line-height: 15px

		.col-user
			width 50%

			@media (max-width 991px)
				width 60%

			@media (max-width 767px)
				width 100%

			@media (max-width 380px)
				flex-direction: column
				margin-top: 5px
				align-items: end
				padding-right: 10px;

		.col-status-trophies
			display: flex
			justify-content: space-between

			@media (min-width 1200px)
				width: 50%

			@media (max-width 380px)
				width: 100%
			
			.trophies_jv_link
				span.progr
					@media (max-width 520px)
						display: none

					@media (max-width 380px)
						display: inline-block
		
		.col-hours
			width: 210px

			@media (max-width 380px)
				width 50%
				margin-top: 10px

			.time_edit_col
				@media (max-width 380px)
					padding-right: 0

				.label_time
					@media (max-width 470px)
						font-size: 9px

				.time_game
					white-space: nowrap

					@media (max-width 470px)
						font-size: 16px

		.col-trophies
			width: 100px

			@media (max-width 1200px)
				margin-left 20px

			@media (max-width 520px)
				width: 35px

			@media (max-width 380px)
				width auto

		.time_edit_col
			text-align: right
			padding-left: 15px;
			padding-right: 15px;

			.label_time
				text-transform: uppercase;
				font-size: 10px;
				white-space: nowrap;
				color: #a19f9f;
				font-weight: 500;
				
			.time_edit
				display block
				text-align: right

				&.empty
					color black
					color #828282

					&:hover
						color #1e618e

// Suivi - change status
.btn-group.btn-group-game-status
	.btn.dropdown-toggle
		// border-radius: 0;
		border: 1px solid $color_darkgray
		border-left: 8px solid $color_darkgray

		&.etat_0
			border-color: $color_darkgray
			color: $color_darkgray

		&.etat_1
			border-color: $color_game_termine
			color: $color_game_termine

		&.etat_2
			border-color: $color_game_joue
			color: $color_game_joue

		&.etat_3
			border-color: $color_game_encours
			color: $color_game_encours

		&.etat_4
			border-color: $color_wish
			color: $color_wish

		&.etat_5
			border-color: $color_game_pause
			color: $color_game_pause

		.caret
			margin-left 10px

	.dropdown-menu
		border-radius: 0;
		box-shadow: none;
		width: 100%;
		margin: 0
		padding 0

		> li 
			> a 
				position relative
				padding: 6px 20px;
				// border-left: 5px solid transparent
				transition all 250ms ease-in-out

				&:before
					content ''
					display block
					width 0px
					height 100%
					position absolute
					top 0
					left 0
					transition all 250ms ease-in-out

			a.active, a:hover
				&:before
					width 8px

				&.active
					color $color_primary
				
				&.etat_0
					// border-left: 5px solid $color_darkgray
					color: $color_darkgray

					&:before
						background $color_darkgray

				&.etat_1
					// border-left: 5px solid $color_game_termine
					color: $color_game_termine

					&:before
						background $color_game_termine

				&.etat_2
					// border-left: 5px solid $color_game_joue
					color: $color_game_joue

					&:before
						background $color_game_joue

				&.etat_3
					// border-left: 5px solid $color_game_encours
					color: $color_game_encours

					&:before
						background $color_game_encours

				&.etat_4
					// border-left: 5px solid $color_wish
					color: $color_wish

					&:before
						background $color_wish

				&.etat_5
					// border-left: 5px solid $color_game_pause
					color: $color_game_pause

					&:before
						background $color_game_pause

	.btn:active
		box-shadow none

	&.w100
		width: 100%;

		.btn.dropdown-toggle
			width: 100%;
			text-align: left;
			display: flex;
			justify-content: space-between;
			align-items: center;
		
	&.open 
		box-shadow none

		.btn.dropdown-toggle
			box-shadow none

// Trophées
.trophies_jv_link
	position relative
	border: 1px solid $color_primary
	// border-left: 8px solid $color_darkgray
	width 100%
	overflow: hidden;

	&:hover
		border: 1px solid $color_primary

	.percent
		position absolute
		bottom 0
		left 0
		width 100%
		height 4px
		background $color_gray
		// border-bottom-right-radius: 4px
		// border-bottom-left-radius: 4px
		overflow: hidden;

		.bar
			width 100%
			height 100%
			background $color_primary
	
	> span
		position relative
		top: -2px;
		z-index 10
		font-size: 13px;

.fancy_trophies
	width: 75%;
	max-width: 1300px;
	padding: 15px;

	@media (max-width 1200px)
		width 90%

	@media (max-width 991px)
		width 100%

	.row_trophy_header
		position relative
		top 0px
		margin-bottom: 15px;

// Calendrier - sorties du mois
.jeuxMois
	height: 200px
	
	li
		padding-right: 10px;

		img.jaquette
			height: 180px;

		a
			margin: 5px;
			padding: 3px;
			border: 1px solid silver;
			background: white;

// Calendrier - Sorties du mois
.tooltipGame
	display: none;
	position: absolute;
	top: 0px;
	left: 0px;

// Derniers jeux ajoutés
.last_games
	position: relative;
	top: 0px;
	left: 0px;
	display: inline-block;

	span
		position: absolute;
		bottom: 3px;
		left: 3px;
		height: 20px;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		width: 75px;
		text-align: center;
		padding-top: 3px;

.view_game_stats
	display: flex;
	/* width: 100%; */
	height: 100%;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 0;
	margin-left: 20px;

	span 
		text-align: left;
		display: block;
		line-height: 9px;
		margin-left: 10px;
		font-size: 12px;
		font-weight: 500;

.game_month_new
	margin: 0;
	font-size: 12px;
	color: #0eaa0e;
	font-weight: bold;

a.game_info_link.-new
	color: #0eaa0e;
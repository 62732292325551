#liste_header { 
    position:relative;
    background:white;
    width:100%;
    margin:20px 0px; 

    .list_cover { 
        width: 100%; 
        height: 0px; 
        padding: 0px; 
    }

    .list_cover_img { 
        width: 100%; 
        height: 100%;  
    }

    .list_header_content { 
        text-align: center; 
        font-family: 'Oswald',Arial,sans-serif;  
        color: #333; 
        width: 100%; 
        margin: auto; 
        padding: 10px; 
        background: #FFF; 
        font-weight: 200; 
        font-size: 14px; 

        h1 { 
            display:block; 
            margin:auto; 
            max-width:600px;  
            padding: 10px; 
            font-size: 25px; 
            font-weight: 300; 
            font-weight: 600;
            line-height: 28px;
            font-family: 'Roboto',sans-serif;
        }
    }
}

#liste_header .list_dates { color: #B9B9B9; float:right; margin: 0; padding-bottom: 10px; padding-right: 10px; padding-top: 10px; }
#liste_header .list_description { max-width: 600px; margin: auto; font-family: Arial; }

#list_listing { padding: 10px; padding-top: 0px; }
.listItem { padding: 10px; margin: 10px 0px; clear:both; }

.listItem h3, .listItem h4, .listItem .listItemPosition { 
    font-weight: 600;
    line-height: 28px;
    font-family: 'Roboto',sans-serif;
}

.listItem h3 { 
    font-size: 27px;    
    margin-top: 0; 
}

.listItem h4 { 
    font-size: 16px;
}

.listItem .listItemPosition { 
    float:right; 
    font-family: 'Roboto',sans-serif;
    font-size: 30px; 
    padding:10px 20px; 
    margin-left: 10px; 
    margin-bottom: 10px;
    background: #f1f1f1;
}

.listItem .listItemSynopsis a { color:#333;  }
.listItem .listItemMiniature { float: left; margin-right: 20px; width: 200px; }
.listItem .listItemMiniature .listButtonsVisio { margin-top:10px; padding-left: 8px; }
.listItem  .listItemContent { margin-left:220px; }

.list_infos_public { background:#F1F1F1; padding:10px; }

.list_element img.list_cover { width:100%; }
.list_element .list_listing { margin: 0; }
.list_element .list_listing li { padding: 10px 30px; font-size: 16px; font-weight: bold; }


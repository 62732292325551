/* Homepage */

.homepage_guest {
	background: #323232;
	background: #cacaca;
	// height:100vh;
	height: calc(100vh - 400px);
	min-height: 500px;

	@media(max-width 967px){
		height: auto;
	}
}

.home_guest_random {
	position: relative;
	height: 400px;

	.home_random_box {
		position: absolute;
		right: 0;
		bottom: 20px;
		background: white;
		text-align: right;
		padding: 8px 10px 8px 70px;

		p{
			line-height: 12px;

			&.title_show {
				margin: 0;
				margin-top: 5px;
				font-size: 15px;
				font-style: italic;
			}
		}
	}
}

.homepage_guest_content {
	width: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	padding-top: 15px;
	color: #333333;

	> .md-flex {
		width: 100%;
	}

	p.subtitle {
		font-size: 30px;
		font-weight: normal;
	}

	.home_ctas {
		margin-top: 30px;
	}
}

.homepage_guest_left {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 40px;

	@media(max-width 967px){
		padding-right: 0;
		align-items: center;
	}
	
	h1 {
		img {
			width:240px;
			margin: 0;

			@media(max-width 967px){
				width:180px;
			}
		}
	}

	h2 {
		font-family: 'Oswald', Verdana, sans-serif;
		// color: white;
		color: $color_text;
		font-size: 30px;
		font-weight: normal;
		margin: auto;
		margin-top: 20px;
		margin-bottom: 0;
		text-align: right;

		@media(max-width 967px){
			text-align: center;
			margin-bottom: 30px;
		}
	}
}

.homepage_guest_right {
	@media(max-width 967px){
		margin-bottom: 30px;
	}
}

.home_login_form {
	width: 320px;
	background: white;
	box-shadow: 0px 0px 20px 6px #9d9d9d;
	padding: 30px;

	label {
		width: 100%;
		text-align: center;
	}
}
/******************************************************************************/
/**********      Spécifique aux awards         ***********/
/******************************************************************************/

$bg_awards = #0f111e;
$awards_orange = #ed7344;
$awards_pink = #fe3cac;
$awards_2018 = #cb00a7;
$awards_2018_link = #a80db1;

#menu_awards
	a 
		padding 5px 0

		img 
			height 50px

/*** Awards 2017 ***/

body#awards2017, body#awards2018
	#submitTicket
		display: none;

header.navbar.navbar-inverse .nav.navbar-nav>li.menu_awards>a
	color: $awards_2018;

header.navbar.navbar-inverse .nav.navbar-nav>li.current.menu_awards:after
	background-color: $awards_2018;

.awardsSerieboxContainer
	background: $bg_awards;
	color: #fff;
	margin-bottom: -30px;
	overflow: hidden;
	padding-bottom: 60px;

	@media (max-width: 991px)
		background: #21232f;

	h1
		a
			display: flex
			justify-content: center

		img
			max-width:100%;

.table.table_listing_sboxawards 
	a 
		display: block;
		font-size: 1.5em;
		color: $color_awards;
		transition: all 200ms ease-out;
		
		@media (max-width:768px)
			font-size: 1.2em;
		
		&:hover 
			color: darken($color_awards, 10%);
	
	
	tr.vote-finished 
		background: $color_awards;
		color: white;
		
		a 
			color: white;


.container_seriebox_vote 
	position: relative;
	background: #21232f;
	margin-bottom: 30px;
	
	.back_listing 
		position: absolute;
		top: -35px;
		right: 15px;
		font-weight: 300;
		letter-spacing: 0.03em;
		text-transform: uppercase;
		
		@media (max-width:768px)
			position: relative;
			top: 0;
			right: auto;
			left: 0;
			text-align: center;
			width: 100%;
			display: block;
			padding: 10px 0;
	
	
	.alert-danger
		margin-bottom: 0;
		border-radius: 0;
		background: #ed7344;
		border: none;
		color: #fff;
		font-size: 16px;
		text-align: center;
	
	
	h2 
		font-size: 3em;
		
		@media (max-width: 767px)
			font-size: 1.8em;
	
	
	.vote_explication 
		font-size: 15px;
		margin-top: 20px;
	

	.row_listing_nomines 
		margin-top: 15px;
		margin-bottom: 15px;
	
	
	td.ptsRestants 
		font-size: 20px;
		font-weight: 800;
		text-align: center;
	
	
	&.vote_2017 
		border-top: 7px solid $awards_orange;
		border-bottom: 7px solid $awards_pink;
		
		.back_listing 
			color: $awards_orange;
	
	
	&.vote_2018 
		border-top: 7px solid $awards_2018;
		border-bottom: 7px solid $awards_2018;
		
		.back_listing 
			color: $awards_2018_link;

.seriebox_nomine 
	margin-bottom: 30px;
	background: $bg_awards;
	background: #161317;
	color: white;
	transition: all 300ms ease-out;
	
	&.voted 
		box-shadow: 6px 6px 0px 0px #414350;
	
	
	h3 
		text-align: center;
		font-size: 17px;
		padding: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 0;
	
	.points_row 
		padding: 10px;
		
		.point 
			float:left;
			width: 20%;
			font-size:30px;
			text-align: center;
			color: #2b2b2b;
			
			&.active 
				color: $color_awards;
			
			
			&.point_trophy 
				cursor: pointer;
			
		
		&.vote_disabled 
			.point 
				&.point_trophy 
					cursor: default;
	
	
	img.nomine_pic 
		margin: auto;
	
	button 
		font-size: 22px;
		border: none;
		font-weight: 600;
		height: 28px;
		line-height: 0;
		
		&, &:hover, &:focus, &:active:focus 
			color: #fff;
			background: #1a1a1a;
			outline:0;
		
		&[disabled]
			color: rgba(0, 0, 0, 0);
			display: none;
	


.bottom_points 
	position: fixed;
	bottom: 0;
	z-index: 100;
	width: 100%;
	left: 0;
	background: $bg_awards;
	background: #1e252f;
	border-top: 2px solid $color_awards;
	padding: 0;
	text-align: center;
	display: flex;
	justify-content: space-between;
	
	.points_left 
		position: relative;
		width: 100px;
		margin: auto;
		font-size:30px;
		color: $color_awards;
		/*border: 1px solid $color_awards;*/
		padding: 5px 10px;
		padding-left: 40px;
		border-radius: 7px;
		text-align: center;
		
		.fa-trophy 
			position: absolute;
			top: 12px;
			left: 12px;

	.bottom_nav
		display flex 
		flex-direction: column;
		justify-content: center
		align-content: center 

		.bottom_nav_arrow
			font-size: 30px;
			color: $color_awards;

.btn-yellow
	display: inline-block;
	padding: 15px 50px;
	color: $color_awards;
	border:2px solid $color_awards;
	text-transform: uppercase;
	font-size: 20px;
	transition: all 200ms ease-out;
	
	@media (max-width:1200px)
		font-size: 17px;
		padding: 15px 30px;
	
	@media (max-width:991px)
		font-size: 15px;
		padding: 15px 20px;

	&:hover, &:focus
		background: $color_awards;
		color: white;
		box-shadow: 6px 6px 0px 0px #414350;
	
	&:focus 
		outline:none;
		text-decoration:none;

.awards_nav_wrapper 
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	margin-bottom: 60px;
	
	@media (max-width:500px)
		display: block;
	
	
	.col  
		flex-basis: 100%;
		
		@media (max-width:500px)
			width:100%;
			margin-bottom:10px;
		
		&.col_center 
			text-align: center;
	
	
	.awards_nav 
		display: inline-block;
		padding: 15px 50px;
		color: $color_awards;
		border:2px solid $color_awards;
		text-transform: uppercase;
		font-size: 20px;
		transition: all 200ms ease-out;
		
		@media (max-width:1200px)
			font-size: 17px;
			padding: 15px 30px;
		
		@media (max-width:991px)
			font-size: 15px;
			padding: 15px 20px;
		
		@media (max-width:670px)
			font-size: 12px;
			padding: 15px 10px;

		&:hover
			background: $color_awards;
			color: white;
			box-shadow: 6px 6px 0px 0px #414350;
		
		&:focus 
			outline:none;
			text-decoration:none;

		&.awards_cat_prev 
			float: left;

		&.awards_cat_next 
			float: right;
		
		&.awards_listing 
			margin:auto;
	


.vote_cloture_announce 
	text-align: center;
	font-size: 18px;
	font-weight: 300;



.seriebox_awards_results 
	h1 
		text-align: center;
		margin: 15px 0;
		color: $color_awards;
	
	
	.awards_new_edition 
		font-size: 1.3em;
		text-align: center;
		color: white;
		
		a 
			color: $color_awards;
	
	
	.result_each_day 
		font-size: 22px;
		text-align: center;
		color: $awards_orange;
	
	
	.awards_result 
		margin: 30px 0;
		background: #161317;
		box-shadow: 6px 6px 0px 0px #414350;
		color: white;
		padding: 20px;
		
		h2 
			margin: 0;
			font-size: 23px;
			text-align: center;
			margin-bottom: 30px;
		
		.nbre_votants 
			font-size: 17px;
			text-align: center;

		img 
			max-width: 100%
		
		.winnerDetails 
			text-align: center;
			
			p.and_the_winner_is 
				font-style: italic;
			
			
			h3 
				color: $color_awards;
				font-size: 20px;
				margin: 10px 0;
		
		table.table 
			font-size: 16px;
			
			@media (max-width: 480px)
				font-size: 13px;
			
			
			thead 
				th 
					font-size: 13px;
					font-weight: 100;
					
					@media (max-width: 480px)
						font-size: 11px;
					

					&.pts 
						width: 20%;
					
					&.you 
						width: 80px;
					
				
			
			
			tr.winner 
				color: $color_awards;
				font-weight: bold;
			
			
			td.pts 
				font-size: 16px;
				text-align: right;
			
		
		.awards_your_vote 
			display: block;
			margin-top:6px;
			font-size: 14px;
			font-weight: normal;
			color: $awards_orange;
			text-align: center;
			
			.fa-trophy 
				font-size: 20px;
			
		
		.nomine_pic 
			width: 60px;
	
	
	.btn_collapse_wrapper 
		text-align: center;
		margin-top: 20px;
	
	
	.btn-awards 
		background: $color_awards;
		color: white;
		border-radius: 0;
		border: none;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 300;
	



.awards_old_results 
	font-size: 1.3em;
	
	a, a:hover 
		color: $awards_2018_link;
	



/*** Awards 2010 ***/


#titre_cat  
	font-size:1.5em;
	font-style:italic;
	vertical-align:middle;
	background-color:black;
	color:white; 


#table_vote
	font-weight:bold;
	clear:left;
	margin:auto; 
	
	td 
		width:400px;
		min-width:400px;
		max-width:400px;
		padding:0px;
		background-color:black;
		color:white;
	
	
	a  
		text-decoration: none;
		display:block;
		opacity:1; 
		background-color:black;
		color:white;
		
		&:hover 
			background-color:#333;
			opacity:0.6;
	
	
	img  
		background-color:white;
	
	#favori 
		height:100px;
		background: black url("../../../images/othervote.jpg") repeat-x bottom;
		vertical-align:top;
		padding:20px;
		padding-top:10px;
		font-weight:none;
		width:760px;
		min-width:760px;
		max-width:760px;

#desc  
	color:gray;
	font-size:0.8em; 

#other
	width:700px;
	margin:auto;
	text-align:justify;
	font-size:0.8em;

#finishtable
	clear:left;
	margin:50px;
	margin-top:10px;
	margin-bottom:10px;
	padding:10px;
	background-color:white;
	color:black;
	border-collapse:collapse;
	border:1px solid black; 
	
	tr:hover  
		background-color:#C1D7FF;
		color:black; 
	
	tr:hover 
		a  
			color:black; 
	
	a  
		color:black;
		display:block; 
	
		&:hover  
			text-decoration:none;
	


#confirma  
	padding-top: 20px; 
	
	a:hover 
		text-decoration:none;
		font-weight:bold; 

.result_num  
	font-size: 1.6em;
	padding-left: 50px;
	padding-right: 20px;
	text-align: right;
	width: 40px; 

.result_percent  
	width:300px;
	font-size:2.1em;
	padding-left:20px; 

.result_win  
	font-weight:bold; 

#winner  
	background-color:yellow; 

#table_win  
	margin-top:50px;
	border-collapse:collapse;
	width: 100%; 

#speech  
	margin:20px;
	text-align:justify;
	font-style:italic; 

#speech .evidence  
	font-weight:bold;
	font-size:1.2em; 

#speech  
	a  
		color:blue; 

#table_resultats  
	border-collapse:collapse; 
	margin:5px 10px; 
	width: 970px; 

.results_numero  
	background-color:#57859F; 
	color:white;
	width:50px;
	font-size: 1.3em;
	font-weight: bold;
	text-align: center;

.results_categories  
	background-color:black;
	color:white;
	font-style:italic;
	font-size: 1.3em;
	font-weight: bold;
	padding-left:20px;

.winners_img  
	float:right;
	margin: 0px 10px; 

.details_cache  
	display:none;

.results_detail  
	background-color:#3f3f3f;
	color:white; 

.and_the_winner_is  
	background: #444;
	padding: 5px 10px;
	color: #FFF; 
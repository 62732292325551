#masonry_home {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
	
	.item_masonry {
		background: #FFF;
		border: 1px solid #ECECEC;
		float: left;
		width: 270px;

		@media (max-width: 640px){
			width: 100%;
			max-width: 400px;
		}
		
		.date {
			font-size: 10px;
			text-transform: uppercase;
			color: #949494;
		}
		
		.avatar_member {
			width: 40px;
			height: 40px;
			border-radius: 100%;
		}
		
		.tag {
			display: block;
			float: right;
			padding: 2px 3px;
			width: 45px;
			text-align: center;
			border-radius: 2px;
			color: #FFF;
			
			&.tag_serie {
				background-color: #3674C4;
				color: white;
			}
			&.tag_drama {
				background-color: #b92c80;
			}
			&.tag_anime {
				background-color: #64a5f1;
			}
			&.tag_emission {
				background-color: #2367b5;
			}
			&.tag_sport {
				background-color: #48bd8f
			}
			&.tag_film {
				background-color: #0c1117;
				color: white;
			}
			&.tag_jeux {
				background-color: #439943;
				color: white;
			}
		}
		
		.time {
			width: 70px;
		}
		
		.seconds {
			display: none;
		}
		
		.title {
			width: 350px;
		}
		
		.note {
			width: 120px;
			color: black;
			font-size: 9px;
			text-indent: 5px;
		}
		
		a.media {
			color: #333;
			
			&.seasonpremiere {
				color: $color_seasonpremiere;
			}
			&.seasonfinale {
				color: $color_seasonfinale;
			}
			&.midseasonpremiere {
				color: $color_midseasonpremiere;
			}
			&.midseasonfinale {
				color: $color_midseasonfinale;
			}
		}
		
		.img_media {
			width: 100%;
		}
	}
}
/*********************************************/
/* Profil */

.container#profilMember {
	@media (max-width: 767px) {
		padding: 0px;

		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.menuProfil {
	position: relative;
	display: flex;
	justify-content: center;
	background: white;
	padding: 0px;
	margin-bottom: 0px;
	width: 100%;
	
	@media (max-width: 767px) {
	    background: #f3f3f4;
		margin-top: 0;
	}
	
	> ul {
		@media (min-width: 768px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
		}
		margin: 0;
		max-width: 1000px;
		
		@media (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			align-items: center;
		}

		> li {
			display: flex;

			> a {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 10px 15px;
				text-align: center;
				color: lighten(#333,30%);
				font-family: Roboto, sans-serif;
				font-size: 14px;
				font-weight: 600;
				transition: all 250ms ease-in-out;
				
				@media (min-width: 768px) {
					padding-top: 0px;
				}
				
				@media (min-width: 991px) {
					min-width: 80px;
				}

				&:focus, &:hover {
					text-decoration: none;
					outline: none;
				}

				&:hover {
					color: $color_blue;
					
					@media (min-width: 768px) {
						box-shadow: inset 0px -3px 0px 0px $color_blue;
					}
				}
				
				/*&.bl{
					border-left: 1px solid #afafaf;
				}*/
				
				&.sub{
					color: #afafaf;
				}

				span.count {
					display: block;
					font-size: 16px;
				}
			}
			
			&.dropdown {
				> a {
					justify-content: center;
					font-size: 28px;
						
					&.selected, &:hover {
						box-shadow: none;
						color: lighten(#333,10%);
					}
					
					@media (max-width: 767px) {
						display: none;
					}
				}
				
				.dropdown-menu {
					margin: 0;
					margin-top: -5px;
					margin-right: 20px;
					z-index: 2000;
					
					@media (max-width: 767px) {
						margin: 0;
						display: block;
						position: relative;
						box-shadow: none;
						border: none;
						background: none;
						width: 100%;
						text-align: left;
						float: none;
						padding: 0;
					}
	
					li {
						display: block;
						
						@media (max-width: 767px) {
							display: none;
							
							&.showed, &.activa {
								display: block;
							}
						}
						
						a {
							@media (max-width: 767px) {
								font-weight: 600;
								padding: 8px 30px;
							}
						}
					}
				}
			}
			
			@media (max-width: 767px) {
				display: none;
				
				&.dropdown {
					height: auto;
					
					.dropdown-menu {
						li {
							height: 40px;
						}
					}
				}
			}
			
			&.active {
				@media (max-width: 767px) {
					display: block;
					width: 100%;
				}
				
				> a {
					color: $color_blue;
					
					@media (min-width: 768px) {
						box-shadow: inset 0px -3px 0px 0px $color_blue;
					}
				}
			}
			
			@media (max-width: 767px) {
				&.active, &.showed {
					display: block;
					width: 100%;

					a {
						flex-direction: row;
						justify-content: space-between;
						color: #333;
						font-size: 20px;
						padding: 8px 30px;
						
						i.fa {
							display: none;
						}
					}
				}
				
				
			}
		}
		
		&.open {
			> li.active {
				> a {
					span.title {
						color: $color_blue;
					}
				}
				
				.dropdown-menu {
					li.activa {
						a {
							color: $color_blue;
						}
					}
				}
			}
		}
	}

	&.-submenu {
		background: whitesmoke;
		padding-top: 10px;

		> ul {

			> li {
				> a {
					padding: 7px 10px;
					font-size: 14px;

					@media (max-width: 767px) {
						padding: 8px 30px;
					}

					&.-wish {
						color: $color_wish;
					}
				}

				&.active > a.-wish, > a.-wish:hover {
					box-shadow: inset 0 -3px 0 0 $color_wish;
					color: $color_wish;
				}

				&.sub-title {
					align-items: center;
					
					span {
						font-size 14px
						font-weight: bold
						text-transform: uppercase
						display: inline-block;
						padding: 4px 5px 4px 0px;
					
						&.bl{
							/* border-left: 1px solid #afafaf; */
							padding-left: 40px;
						}
					}
				}
			}
		}
	}
}

body.darkmode
	.menuProfil
		&.-submenu
			> ul
				> li
					> a
						&.-wish
							color: $color_dark_wish;

					&.active > a.-wish, > a.-wish:hover
						box-shadow: inset 0 -3px 0 0 $color_wish;
						color: $color_wish;

.profile_menu_burger
	display: none;
	padding: 0px;
	padding-left: 10px;
	padding-right: 30px;
	color: #333;
	font-size: 28px;
	
	&:focus, &:hover
		color: #333;
	
	@media (max-width: 767px)
		display: block;
		padding-top: 2px;

.part_home_hidden
	margin-top: 30px;
	
	p
		padding: 10px 30px 20px;

#profile_edit_zone {
	form {
		background: white;
		padding: 10px;
		
		input.text, select {
			width: 300px;
		}
	}
}

#edit_profile {
    position: relative;
    margin: 10px 0px;
	
	span {
		@media (max-width: 767px) {
			display: none;
		}
	}
}

#title_block {
    font-family: 'Oswald', Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    background: #F3F3F4;
    
    width: 260px;
    outline: none;
}
.formAddElement .profile_submit {
    float: right;
	clear: left;
    padding: 10px;
}
#addElement {
    padding: 20px;
    line-height: 50px;
}
#divColumns {
    padding: 10px 20px;
}

.block_icon {
    opacity: 0.5;
    position: absolute;
    width: 16px;
    height: 16px;
	font-size: 16px;
    z-index: 10;
	color: #333;
	display: flex;
    align-items: center;
    justify-content: center;

	&:hover {
		opacity: 1;
		color: #333;
	}

	&.delete_element {
		right: 10px;
		top: 5px;
	}

	&.edit_element {
		right: 10px;
		top: 25px;
	}

	&.down_element {
		right: 30px;
		top: 25px;
	}

	&.up_element {
		right: 30px;
		top: 5px;
	}
}


.profil_element .hidden_link {
    cursor: default;
    opacity: 0.1;
}
.profil_element .hidden_link:hover {
    opacity: 0.1;
}

#profil_header {
    position: relative;
    top: 0px;
    left: 0px;
    min-height: 120px;
	margin-top: 15px;
	background: white;
	
	@media (max-width: 767px) {
		padding: 0px;
		margin-top: 0;
	}
}

.profil_background {
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: cover;
	height: 250px;

	@media (max-width: 767px) {
		height: 120px;
	}
}

.profile_header_row {
	@media (min-width: 768px) {
		display: flex;
		padding: 15px;
		
		&.phr_up {
			margin-top: -28px;
			padding-bottom: 0;
		}
	}
	
	@media (max-width: 767px) {
	    padding: 10px 30px;
	}
}

#profil_login {
	display: flex;
	align-items: center;
	
	.avatar {
		width: 75px;
		height: 75px;
		border-radius: 100%;
		margin-right: 15px;
		
		@media (max-width: 767px) {
			width: 50px;
			height: 50px;
			margin-right: 10px;
		}
	}
	
	h1 {
		font-size: 22px;
		padding: 0;
		margin: 0;
		
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}
}

#profil_about {
	padding: 15px 0;
	
	@media (max-width: 767px) {
		/*padding: 10px 0;*/
		display: none;
	}
	
	p {
		color: #474747;
		font-size: 11px;
		line-height: 14px;
	}
}

#profil_infos {
	display: flex;
	flex-direction: row;
    align-items: center;
	flex-wrap: wrap;
	float: right;
	height: 100%;
	
	.btn {
		border-radius: 20px;
		margin-left: 10px;
		
		&.btn-default {
			border-color: lighten(#333,30%);
			color: lighten(#333,30%);
		}
	}
	
	.profile_user_demande_ami {
		padding-bottom: 36px;
		
		.btn.btn-success {
			margin-left: 0;
		}
	}
}

.profil_element {
	position: relative;
    background: white;
	
	h2 {
		font-family: 'Oswald', Arial, sans-serif;
		font-size: 20px;
		font-weight: 300;
		background: #F3F3F4;
		padding: 10px 30px;
		margin: 0;
	}
	
	.alert {
		border-radius: 0;
		border: 0;
	}
	
	.profil_element_content {
		padding: 10px;
	}
}

.fluxmedia {
    width: 50%;
    padding: 30px 30px 10px;
    float: left;
    position: relative;
	
	@media (max-width: 767px) {
		width: 100%;
	}
	
	&.bigflux {
		width: 100%;
	}
	
	.date {
		color: #666666;
		font-size: 12px;
		padding: 0px;
		margin: 0px;
		float: left;
	}
	
	.extend {
		float: right;
	}
	
	.options {
		right: 30px;
		top: 50px;
		
		a span {
			padding-right: 10px;
		}
	}
	
	h3 {
		padding: 10px 0px;
		font-size: 16px;
		clear: both;
	}
	
	.episode {
		color: #333333;
		font-weight: bold;
		font-size: 14px;
		margin: 10px 0px 5px;
		
		a {
			color: #333333;
			
			&.seasonpremiere {
				color: $color_seasonpremiere;
			}
			&.seasonfinale {
				color: $color_seasonfinale;
			}
			&.midseasonpremiere {
				color: $color_midseasonpremiere;
			}
			&.midseasonfinale {
				color: $color_midseasonfinale;
			}
		}

		.minidate {
			color: #BDBDBD;
    		font-size: 9px;
		}
	}
	
	.note {
		color: #333333;
		font-size: 12px;
		margin: 5px 0px 5px;
	}
	
	.affich {
		width: 100%;
		display: block;
	}
	
	.affich.affichbig {
		@media (min-width: 768px) {
			float: right;
			width: 50%;
			padding-left: 30px;
		}
	}
	
	.flux_img {
		display: block;
	}
}

.profile_trophees_wrapper {
	.profile_game_trophee {
		display:inline-block;
		width: 52.5px;
		height: 52.5px;
		line-height: 52.5px;
		text-align: center;
		font-size:20px;
		background-color:#c4c4c4;
		color:white;
		background-size: cover;
		float: left;
		transition: all 300ms ease-in-out;
		
		&:hover {
			background-color:#282828;
		}
		&:focus {
			text-decoration: none;
		}
	}
}

#moreProfilFlux
	padding: 11px 30px;
	font-size: 24px;
	display: block;
	margin: 30px;
	background: white;
	border: 4px solid $color_darkblue
	border-radius: 0;
	color: $color_darkblue
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.5px;
	transition all 300ms ease-in-out

	&:hover
		background $color_darkblue
		color white

.container_home {
	#moreProfilFlux {
		margin: 30px 0;
	}
}

#listing_friends {
    padding: 10px;
}

hr.stylehr {
    border: 0;
    height: 1px;
    width: 80%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
	
	@media (max-width: 767px) {
		display: none;
	}
}

#listing_jv {
	padding: 10px;
	
	hr.stylehr {
		margin-top: 20px;
	}
	.game_title {
		max-width: 25%;
	}
	.game_status {
		width: 100px;
	}
	
	h3 {
		font-size: 20px;
		padding-top: 30px;
	}
	.ingame_bar_wrapper {
		width: 200px;
		height: 20px;
		
		.ingame_bar {
			height: 10px;
			background: $color_blue;
		}
	}
}

.list_profil_1 {
	img {
		width: 100%;
	}
}
.list_profil_2 {
	li {
		float: left;
		width: 50%;
		
		&:nth-child(2n+1) {
			clear: both;
		}
		
		img {
			width: 100%;
		}
	}
}

.list_profil_3 {
	display: flex
	flex-wrap wrap

	li {
		padding: 3px;
		width: calc(100% / 3);
		
		img {
			display block
			width: 100%;
		}
	}
}

.profil_element_title_wide {
    text-align: center;
    padding: 10px;
    margin: 0px;
}
.add_profil_element {
    padding: 20px 5px;
    margin: 0px;
    font-size: 14px;
    background: url(../../images/design/icons/box_add.png) no-repeat 10px 50%;
    padding-left: 50px;
}

form {
	.element {
		padding: 10px 20px;

		&.element_odd {
			background: #E0E0E0;
		}
		&.element_even {
			background: #000000;
		}
	}
}

.table.table_profil_list {
	h3 {
		padding: 5px 0px;
		font-size: 17px;
	}

	>tbody {
		> tr {
			> td {
				vertical-align: middle;

				&.avoir {
					text-align: center;
					font-size: 16px;
				}

				&.note {
					font-size:20px;
					font-weight: bold;
					text-align: center;
				}
			}
		}
	}
} 

.profilListFilms {
	display: flex;
	flex-wrap: wrap;
	
	li {
		float: left;
		// width: 80px;
		// width: calc(100% / 10);
		width: calc(100% / 11);
		// max-width: 80px;
		// height: 100px;
		text-align: center;
		// padding: 0px;
		// margin: 10px;
		padding: 10px;
		margin: 0;
		// overflow: hidden;

		@media (max-width: 1200px){
			width: calc(100% / 9);
		}

		@media (max-width: 991px){
			width: calc(100% / 7);
		}

		@media (max-width: 700px){
			width: calc(100% / 6);
		}

		@media (max-width: 600px){
			width: calc(100% / 5);
		}

		@media (max-width: 520px){
			width: calc(100% / 4);
		}

		@media (max-width: 380px){
			width: calc(100% / 3);
		}

		@media (max-width: 280px){
			width: calc(100% / 2);
		}

		&.separator {
			width: 100%;
			clear: both;
			// margin-top: 30px;
			border-top: 1px solid #dedede;
		}
		
		> a {
			display: block;
			position: relative;
			top: 0px;
			left: 0px;
			color: #333;
			box-shadow: 0 0 0px 0px #aaaaaa;
			transform: scale(1);
			transition: all 450ms ease-out;

			img.affiche {
				display: block;
				width: 100%;
				// height: 110px;
				object-fit: cover;
				aspect-ratio: 75 / 100;
			}
			
			.fa-star {
				position: absolute;
				// bottom: -10px;
				// right: -10px;
				bottom: -12px;
				right: 0;
				font-size: 34px;
				color: #3a3a3a
				width: 100%;
			}

			span.note {
				position: absolute;
				// bottom: -6px;
				// right: -6.5px;
				bottom: -8px;
				right: 0;
				padding: 0;
				font-size: 12px;
				font-weight: 500;
				// width: 24px;
				width: 100%;
				height: 24px;
				line-height: 24px;
				text-align: center;
				// background: #45454
				color: #fff;
			}

			&:hover {
				box-shadow: 0 0 10px 2px #aaaaaa;
				transform: scale(1.1);
				transition: all 650ms cubic-bezier(0.03, 1.03, 1, 1);

				.fa-star {
					color: #d1ea00;
					transition: all 450ms ease-out;
				}

				span.note {
					color: #333;
					transition: all 250ms ease-out;
				}
			}
		}

		.cinema {
			position: absolute;
			top: 0;
			left: 0px;
			width: 30px;
			height: 30px;
			// background: linear-gradient(to bottom right, #b1b1b1 0%, #c4c4c4 50%, #fff0 50%, #fff0 100%);
			// color: #000;
			background: linear-gradient(to bottom right,#932cbd 0%,#932cbd 50%,#fff0 50%,#fff0 100%);
			// color: #f4d9ff;
			color: white;
			// background: linear-gradient(to bottom right,#fff 0%,#fff 50%,#fff0 50%,#fff0 100%);
			// color: #932cbd;
			
			i.fa-ticket{
				position: absolute;
				top: 3px;
				left: 3px;
				font-size: 14px;
				// top: -1px;
				// left: -1px;
				// font-size: 20px;
			}
		}
		
		&.profilListFilmsCount {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			font-size: 18px;
			font-family: 'Oswald', Arial, sans-serif;
			font-weight: 300;
			
			.count {
				font-size: 30px;
				display: block;
				padding-top: 13px;
				font-weight: 400;
			}
			
			&.-cinema {
				.count {
					color: #932cbd;
				}
			}
		}
	}
	
	h3 {
		padding: 5px 0px;
		height: 10px;
		overflow: hidden;
		display: none;
	}
}

body.darkmode {
	.profilListFilms {
		li {
			span.note {
				background: $color_gray
				color: $color_dark_tile 
			}
		}
	}
}

.year-menu-container {
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	cursor: grab;
	// padding: 10px;
	padding: 5px;
	user-select: none; /* Désactive la sélection de texte pendant le glissement */

	&.active {
		background: rgba(255,255,255,0.3);
		cursor: grabbing;
		cursor: -webkit-grabbing;
		transform: scale(1);
	}

	.year-menu {
		display: flex;
		position: relative;
		top: 0px;
		left: 0px;
		width:100%;
		// margin: 10px 25px;
		margin: 10px;
	}
}

/* Styles de la scrollbar */
.year-menu-container::-webkit-scrollbar {
    height: 10px; /* Taille de la scrollbar horizontale */
}

.year-menu-container::-webkit-scrollbar-track {
    background: #f0f0f0; /* Couleur de fond de la scrollbar */
}

.year-menu-container::-webkit-scrollbar-thumb {
    background-color: #333; /* Couleur du curseur de la scrollbar */
    // border-radius: 4px;
}

.year-menu-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Couleur au survol */
}

.listFilmYear,
.listFilmNote,
.listFilmMoyenne,
.listFilmAlpha {
    display: block;
    padding: 0px 5px;
    font-size: 25px;
    color: gray;
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 300;
    text-align: center;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none;
	}

	&.selected {
		color: #1E618E;
		font-weight: bold;
	}
}
.listFilmYear {
    width: 60px;
    text-align: center;
}
#listNotes .listFilmNote, #listNotes .listFilmMoyenne {
    width: 20px;
}
#listAlphas .listFilmAlpha {
    width: auto;
}
span.listFilmNote, span.listFilmMoyenne {
    color: silver;
}
#flashForward,
#flashBack {
    width: 30px;
    height: 50px;
    padding: 10px 0px;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: $color_darkblue

	&.inactive {
    	color: #F5F5F5;
	}
}
#flashForward {
    left: 0px;
}
#flashBack {
    right: 0px;
}

.films_communs_wrapper {
	position: absolute;
	top: 7px;
	right: 7px;

	a.btn {
		border-radius: 20px;
		box-shadow: none;
		transition: all 300ms ease-in-out;

		&.active {
			background: #3bb532;
			color: white;	
		}
	}
}

#optionsListFilms {
	margin: 10px 20px;
}

.filtreFilms {
    padding-bottom: 10px;
	display: none;
}
.filtreFilms .loading {
    text-align: center;
}

#listNotes,
#listAlphas {
    margin: 5px 33px;
}
#listNotes li {
    padding: 5px;
    font-size: 20px;
}
#listAlphas li {
    padding: 5px 4px;
    font-size: 20px;
}

/* Stats - séries */
.items_by_month
	h2
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		font-size:18px;
		padding: 10px 25px;
	
	.nav_months
		display: flex;
		justify-content: space-between;
		padding: 20px 25px;

.eps_stts
	.ep_stts_head, .ep_stts_foot
		font-weight: bold;
		padding-top: 5px;
		padding-bottom: 5px;
	
	.sts_show
		.row_title
			padding-top: 10px;
			padding-bottom: 10px;
			transition: all 300ms ease-in-out;
			
			h3
				font-size: 17px;
				transition: all 300ms ease-in-out;
				
				@media (max-width: 768px)
					font-size: 15px;

			.fa
				font-size: 20px;
				transform: rotate(-90deg);
				transition: all 300ms ease-in-out;
			
			&:hover
				@media (min-width: 768px)
					cursor: pointer;

					h3
						padding-left: 5px;
			
			&.opened
				.fa
					transform: rotate(0deg);
	
		.sts_eps
			display:none;
			
			&.showed
				display: block;
				padding: 10px 0;
			
			.row_head
				font-weight: bold;
			
			.row_ep
				padding-top: 5px;
				padding-bottom: 5px;
				
				&.seasonpremiere
					.ep_title
						font-weight: bold;
						color: $color_seasonpremiere;

				&.seasonfinale
					.ep_title
						font-weight: bold;
						color: $color_seasonfinale;

				&.midseasonpremiere
					.ep_title
						font-weight: bold;
						color: $color_midseasonpremiere;

				&.midseasonfinale
					.ep_title
						font-weight: bold;
						color: $color_midseasonfinale;
		
		&.odd
			background: #efefef;
		
		.time
			@media (max-width: 768px)
				font-size: 11px;
.status_encours
	background: #DBEAFF;

.responsive-profil-table
	overflow-x:auto;

	.table
		@media (max-width: 991px)
			width: 1000px;
			max-width: 1000px;
			
.table.table_movies_month
	>thead
		abbr[title]
			text-decoration: underline dotted

	>tbody>tr>td
		vertical-align: middle;

		&.col_num
			font-size 18px
			font-weight bold

		&.col_num2
			font-size: 16px;
			font-weight: bold;
			color: #939393;

		&.col_date_vu
			font-weight: bold

		&.col_title
			font-size 18px
			font-weight bold

a.game_info_link
	display: flex;
	align-items: center;

	img 
		width 50px

	&.game_status_1
		color $color_game_termine

	&.game_status_2
		color $color_game_joue
		
	&.game_status_3
		color $color_game_encours

	&.game_status_4
		color $color_wish

	&.game_status_5
		color $color_game_pause

	div.game_info
		padding 15px 0
		display block

		span.year 
			display block
			font-size: 14px;
			line-height: 14px;
			font-weight: 800;
			color: #a2a2a2;

		span.title
			font-size 18px
			font-weight bold

		span.plateforme
			font-weight: bold;
			
.table.table_games_month
	>tbody>tr>td
		vertical-align: middle;

	.col_hours
		text-align: right;
		padding-right: 15px;

#films_random
	position relative

	.loading 
		position absolute
		top 50px
		left 0
		width 100%

#box_film_random
	min-height: 150px;

#films_wish_random
	display: inline-block;
	background: #ebeff9;
	font-size: 16px;
	font-weight: bold;
	color: #337ab7;
	transition all 300ms ease-in-out
	text-decoration: none
	padding: 10px 15px;
	float: right;

	i
		margin-right: 10px

	&:hover
		background #337ab7;
		color: #ebeff9

.modal_global_wrap
	width 150px
	font-weight: bold;
	align-items: center;
	text-align: right;

	.modal_global_time
		font-size: 18px;
		margin-left: 5px;

.table.table_friends_players >tbody >tr
	td
		vertical-align: middle;

		.pseudo
			font-size 16px
			font-weight: 600;
.-gold
    color $color_awards

.-gray
    color #a2a2a2

.-white
    color white

.-light
    font-weight 300

.-regular
    font-weight 400

.-bold
    font-weight 600

.-bolder
    font-weight 800

.-smaller
    font-size 12px

.-small
    font-size 14px

.-normal
    font-size 16px

.-medium
    font-size 18px

.-big
    font-size 30px
/******************************************************************************/
/**********                 Spécifique aux fiches jeux              ***********/
/******************************************************************************/

// --- Plateformes disponibles pour ce jeu
.game_supports {
	display: block;
	clear:both;
	width 100%;
	margin-top: 10px;

	a {
		position: relative;
		display: inline-block;

		&.game_add_new_support {
			padding-left: 20px;
		}
		
		&.active:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -3px;
			width: 100%;
			height: 2px;
			background: $color_darkblue
			border-radius: 4px;
			
			@media screen and (max-width: 767px) {
				bottom: 0;
			}
		}
	}
}

.item_dlc_game
	background $color_dlc
	color white
	margin-top: -20px;
	padding: 2px 20px;

	a
		color white

		&:hover
			color white
			text-decoration: underline

.item_dlcs
	background $color_dlc
	color white
	margin-top: -20px;
	padding: 0;

	h2
		margin 0
		padding: 12px 20px

		a
			display: flex
			align-content: center
			justify-content: space-between
			text-decoration: none
			color white
		
			i.fa 
				position relative
				transform rotate(90deg)
				transition all 200ms ease-out

			&:focus, &:hover
				color white
				text-decoration: none
				outline none

				i.fa 
					transform rotate(90deg)

			&.collapsed
				i.fa 
					transform rotate(0)
					transition all 200ms ease-out

	ul
		padding 0 20px 20px 20px

		li
			a
				color white

				&:hover
					color white
					text-decoration: underline
					transition all 200ms ease-out
				

// --- Liste des trophées steam - playstation - xbox
h3.trophy_section
	font-size: 16px;
	margin-left: 15px;

ul.trophy_game_list {
	display: flex;
    align-items: center;
    flex-flow: column;
    align-content: center;
    flex-flow: wrap;

	li {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex: 0 0 50%;
		width: 50%;
		margin-bottom: 5px;
		
		@media (max-width: 768px){
			flex: 0 0 100%;
		}

		&.odd {
			background: #DBEDFF;
		}

		.trophee_logo {
			float:left;
			margin: 0;
			overflow:hidden;
			position:relative;
			top:0px;
			left:0px;
			width: 52px;
			height: 52px;
			margin-right: 10px;

			.trophee_logo_position {
				position:absolute;
				top:0px;
				left:0px;
				width: 114px;
				height:52px;
				transform: translateX(0px);
				transition: all 500ms ease-in-out;

				img.trophee_logo_pic {
					display: block;
					float:left;
					width: 52px;
					height:52px;

					&.trophee_logo_empty {
						opacity: 0.2;
					}

					&.trophee_logo_real {
						background: $color_darkgray
					}
				}
			}
		}

		&.haveTrophy {
			.trophee_logo .trophee_logo_position {
				transform: translateX(-52px);
			}
		}

		.trophee_level {
			vertical-align: middle;
			padding-right: 10px;
		}

		h4 {
			margin: 0;
			padding: 5px 0;
			font-size: 14px;
			font-weight: 800;
		}

		p {
			margin: 0;
			font-size: 12px;
			line-height: 14px;
		}

		.trophee_title {
			float: left;
			width: 60%;
		}

		.trophee_count {
			position: relative;
			width: 30px;
			padding: 5px 6px;
			margin: 0 11px;
			background: white;
			border: 1px solid #DEDEDE;
			text-align: center;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: -4px;
				margin-top: -2.5px;
				width: 5px;
				height: 5px;
				background: #FFF;
				border-left: 1px solid #DEDEDE;
				border-top: 1px solid #DEDEDE;
				transform: rotate(-45deg);
			}
		}

		.trophee_btn {
			display: flex
			justify-content: center
			align-items: center
		}
	}
}

.row_critic_game {
	margin-top: 30px;
}

.critic {
	text-align: center;
	
	.critic_note {
		text-align: center;
		font-weight: bold;
		font-size: 2.8125rem;
		line-height: 1em;
		margin-bottom: 8px;
	}
	
	.critic_test{
		display: inline-block;
		line-height: 14px;
		color: #333;
		font-weight: bold;
		text-decoration: none;
		transition: all 300ms ease-in-out;
	}
	
	&.critic_jv_com {
		.critic_note {
			width: 76px;
			height: 76px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			flex-direction: column;
			
			span.note {
				color: #c00;
				margin-top: 8px;
				margin-bottom: 4px;
			}
			
			span.span_20 {
				border-top: 0.0625rem solid #757575;
				color: #757575;
				display: inline-block;
				font-size: 20px;
				line-height: 1em;
				padding: 0 0.4375rem;
				padding-top: 0.125rem;
				margin-top: 0.125rem;
			}
		}
	}
	
	> a {
		&:hover {
			.critic_test{
				color: #c00;
			}
		}
	}
}

// Note GK
.gk__staff-note {
	border-radius: 50%;
	background-color: #181818;
	margin: auto;
}

.gk__staff-note__md, .gk__staff-note__md__selec--hardcore, .gk__staff-note__md__selec--casual, .gk__staff-note__md__selec--indispensables, .gk__staff-note__md__selec--multi {
    width: 76px;
    height: 76px;
}
.gk__staff-note__md .gk__staff-note__circle, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle, .gk__staff-note__md__selec--casual .gk__staff-note__circle, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle, .gk__staff-note__md__selec--multi .gk__staff-note__circle, .gk__staff-note__md .gk__staff-note__circle .mask, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle .mask, .gk__staff-note__md__selec--casual .gk__staff-note__circle .mask, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle .mask, .gk__staff-note__md__selec--multi .gk__staff-note__circle .mask, .gk__staff-note__md .gk__staff-note__circle .fill, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle .fill, .gk__staff-note__md__selec--casual .gk__staff-note__circle .fill, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle .fill, .gk__staff-note__md__selec--multi .gk__staff-note__circle .fill {
    width: 76px;
    height: 76px;
}

.gk__staff-note__md .gk__staff-note__circle, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle, .gk__staff-note__md__selec--casual .gk__staff-note__circle, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle, .gk__staff-note__md__selec--multi .gk__staff-note__circle {
    overflow: hidden;
}

.gk__staff-note__circle {
    position: absolute;
    border-radius: 50%;
}

.gk__staff-note__circle .mask, .gk__staff-note__circle .fill {
    position: absolute;
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
}

.gk__staff-note__md .gk__staff-note__circle .mask, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle .mask, .gk__staff-note__md__selec--casual .gk__staff-note__circle .mask, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle .mask, .gk__staff-note__md__selec--multi .gk__staff-note__circle .mask {
    clip: rect(0px, 76px, 76px, 37px);
}

.gk__staff-note__md .gk__staff-note__circle .mask .fill, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle .mask .fill, .gk__staff-note__md__selec--casual .gk__staff-note__circle .mask .fill, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle .mask .fill, .gk__staff-note__md__selec--multi .gk__staff-note__circle .mask .fill {
    clip: rect(0px, 38px, 76px, 0px);
}

.gk__staff-note__md .gk__staff-note__circle .mask, .gk__staff-note__md__selec--hardcore .gk__staff-note__circle .mask, .gk__staff-note__md__selec--casual .gk__staff-note__circle .mask, .gk__staff-note__md__selec--indispensables .gk__staff-note__circle .mask, .gk__staff-note__md__selec--multi .gk__staff-note__circle .mask {
    clip: rect(0px, 76px, 76px, 37px);
}

.gk__staff-note__inset {
    position: absolute;
    border-radius: 50%;
    background-color: #181818;
    // background-image: url(//d3isma7snj3lcx.cloudfront.net/assets/front/img/note/background-logo.svg);
    background-image: url(../../images/jeux-video/gamekult-background-logo.svg);
    background-position: center center;
    -o-background-size: 80%;
    background-size: 80%;
    background-repeat: no-repeat;
}

.gk__staff-note__md .gk__staff-note__inset, .gk__staff-note__md__selec--hardcore .gk__staff-note__inset, .gk__staff-note__md__selec--casual .gk__staff-note__inset, .gk__staff-note__md__selec--indispensables .gk__staff-note__inset, .gk__staff-note__md__selec--multi .gk__staff-note__inset {
    width: 64px;
    height: 64px;
    margin-top: 6px;
    margin-left: 6px;
}

.gk__staff-note__inset .note {
    line-height: 1;
    position: absolute;
    overflow: hidden;
    width: 44px;
    -webkit-transition: width 1s;
    -o-transition: width 1s;
    transition: width 1s;
}

.gk__staff-note__md .gk__staff-note__inset .note, .gk__staff-note__md__selec--hardcore .gk__staff-note__inset .note, .gk__staff-note__md__selec--casual .gk__staff-note__inset .note, .gk__staff-note__md__selec--indispensables .gk__staff-note__inset .note, .gk__staff-note__md__selec--multi .gk__staff-note__inset .note {
    height: 30px;
    top: 17px;
    left: 10px;
}

.gk__staff-note__inset .note .numbers span {
    font-weight: bold;
    display: inline-block;
    width: 44px;
    text-align: center;
    vertical-align: top;
    color: #fbfcfc;
}

.gk__staff-note__md .gk__staff-note__inset .note .numbers span, .gk__staff-note__md__selec--hardcore .gk__staff-note__inset .note .numbers span, .gk__staff-note__md__selec--casual .gk__staff-note__inset .note .numbers span, .gk__staff-note__md__selec--indispensables .gk__staff-note__inset .note .numbers span, .gk__staff-note__md__selec--multi .gk__staff-note__inset .note .numbers span {
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0rem;
    font-family: "Open Sans",sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
}

.gk__staff-note[data-progress='2'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='2'] .gk__staff-note__circle .fill {
    transform: rotate(36deg);
}
.gk__staff-note[data-progress='2'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 20%);
}
.gk__staff-note[data-progress='2'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 160%, #ff6448 260%);
}

.gk__staff-note[data-progress='3'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='3'] .gk__staff-note__circle .fill {
    transform: rotate(54deg);
}
.gk__staff-note[data-progress='3'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 30%);
}
.gk__staff-note[data-progress='3'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 140%, #ff6448 240%);
}

.gk__staff-note[data-progress='4'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='4'] .gk__staff-note__circle .fill {
    transform: rotate(72deg);
}
.gk__staff-note[data-progress='4'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 40%);
}
.gk__staff-note[data-progress='4'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 120%, #ff6448 220%);
}

.gk__staff-note[data-progress='5'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='5'] .gk__staff-note__circle .fill {
    transform: rotate(90deg);
}
.gk__staff-note[data-progress='5'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 50%);
}
.gk__staff-note[data-progress='5'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 100%, #ff6448 200%);
}

.gk__staff-note[data-progress='6'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='6'] .gk__staff-note__circle .fill {
    transform: rotate(108deg);
}
.gk__staff-note[data-progress='6'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 60%);
}
.gk__staff-note[data-progress='6'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 80%, #ff6448 180%);
}

.gk__staff-note[data-progress='7'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='7'] .gk__staff-note__circle .fill {
    transform: rotate(126deg);
}
.gk__staff-note[data-progress='7'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 70%);
}
.gk__staff-note[data-progress='7'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 60%, #ff6448 160%);
}

.gk__staff-note[data-progress='8'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='8'] .gk__staff-note__circle .fill {
    transform: rotate(144deg);
}
.gk__staff-note[data-progress='8'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 80%);
}
.gk__staff-note[data-progress='8'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 40%, #ff6448 140%);
}

.gk__staff-note[data-progress='9'] .gk__staff-note__circle .mask.full, .gk__staff-note[data-progress='9'] .gk__staff-note__circle .fill {
    transform: rotate(162deg);
}
.gk__staff-note[data-progress='9'] .gk__staff-note__circle .mask.half .fill {
    background-image: linear-gradient(to top, #7b1101 0, #e02200 90%);
}
.gk__staff-note[data-progress='9'] .gk__staff-note__circle .mask.full .fill {
    background-image: linear-gradient(to top, #e02200 0, #e02200 20%, #ff6448 120%);
}

.trophies_wrapper
	min-height: 70vh;
	// padding-bottom: 150px;

.trophies_section
	padding-bottom: 50px;

.row_trophy_header
	position: sticky;
	top: 60px;
	background: #fafafa;
	// background: #eeeeee;
	z-index: 100;


// $color_month_time_empty = #c5c5c5
$color_month_time_empty = #909090

.container_time_by_month
	display: flex
	justify-content: center 

.game_time_wrapper
	padding: 30px 40px;
	// width: 300px;
	// max-width: 300px;
	width: 400px;
	max-width: 400px;

	input.hours_game
		width: 142px;
		font-size: 20px;
		text-align: right;

.game_time_error
	display: none
	background: #de4e4e;
	color: #fff;
	padding: 10px 13px;
	margin-bottom: 10px;
	line-height: 14px;

.game_time_warning
	background: #4093a9
	color: #fff;
	padding: 10px 13px;
	margin-bottom: 10px;
	line-height: 14px;

.bg_gray
	background: #e0e0e0;

.add_month_time
	background: #286090;
	color: #fff;
	position: absolute;
	top: -15px;
	right: -15px;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 26px;
	transition all 300ms ease-in-out

	.fa 
		height 22px

	&:hover, &:focus
		background: #204e76
		color: #fff;


.month_time_wrapper
	clear both 

	.month_time_line
		display flex
		justify-content: space-between;

		&.odd
			background #f6f6f6

		@media (max-width 1200px)
			flex-wrap: wrap

		.year
			width: 50px;

			@media (max-width 1200px)
				order: 0
				width 100%
				text-align: center;
				margin-bottom: 5px;

		> div
			padding 10px 0;

		.game_num
			font-size: 14px;
			font-weight: bold;
			width: 40px;
			padding-left: 10px;

			.num
				display: none;

			@media (max-width 1200px)
				width 100%
				padding-left: 0;
				padding-bottom 0
				text-align: center;

				.num
					display: inline;

		.game_title
			width: 300px;
			padding-left: 10px;

			.game_link
				display: block;
				font-size: 14px;
				line-height: 14px;
				font-weight: bold;
				
				&.game_status_1
					color $color_game_termine
					border-left: 5px solid $color_game_termine
					padding-left: 5px;

				&.game_status_2
					color $color_game_joue
					border-left: 5px solid $color_game_joue
					padding-left: 5px;
					
				&.game_status_3
					color $color_game_encours
					border-left: 5px solid $color_game_encours
					padding-left: 5px;

				&.game_status_4
					color $color_wish
					border-left: 5px solid $color_wish
					padding-left: 5px;

				&.game_status_5
					color $color_game_pause
					border-left: 5px solid $color_game_pause
					padding-left: 5px;

			@media (max-width 1200px)
				order: 0
				width 100%
				text-align: center;
				margin-bottom: 5px;
				padding-left: 0;
				padding-top: 0;
		
		.month
			position relative
			display: flex;
			width: calc(calc(100% - 40px - 50px) / 12);
			text-align: center
			justify-content: center;

			.month_link
				position relative
				display flex
				justify-content: center;
				align-items: center;

			@media (max-width 1200px)
				width 16.66%
				flex-direction: column

			// @media (max-width 500px)
			// 	width 25%

			.month_name
				display none

				@media (max-width 1200px)
					display block

		.total
			width: 70px;
			text-align: right

			@media (max-width 1200px)
				width 100%
				text-align: center
				margin-top: 10px
				order: 1

.bg_white 
	.month_time_head
		.month_time_line
			.total
				padding-right 10px
				width: 100px;

	.month_time_body 
		.month_time_line
			.month.empty.futur 
				.month_link
					&:before, &:after
						background #ececec

			.month.current 
				.month_link:after
					background #ececec

			.total
				padding-right 10px
				width: 100px;

.month_time_head
	margin-bottom: 5px;

	@media (max-width 1200px)
		display none

	.month_time_line
		> div
			font-weight: 500;
			text-transform: uppercase;
			padding 0

			&.game_title, &.game_num
				padding-left 10px

.month_time_body
	.month_time_line
		display: flex;
		align-items: center;
		justify-content: center;
		
		.year 
			font-size: 16px;
			font-weight: bold

			@media (max-width 1200px)
				line-height: 13px
				color: #5d5d5d;

		.month
			.month_link
				width: 100%
				min-height: 24px

				@media (max-width 1200px)
					min-height: 44px

				&:before 
					content ''
					display: block
					position absolute
					top 11px
					left 0
					width 100%
					height 2px 
					background $color_month_time_empty

					@media (max-width 1200px)
						top 22px

			&.empty
				.month_link
					&:after
						content ''
						display: block
						position absolute
						top 50%
						left 50%
						background: $color_month_time_empty
						width: 10px
						height: 10px
						margin-left: -5px
						margin-top: -5px
						border-radius: 100%

				&.futur
					.month_link
						&:before 
							background: #ffffff

					.month_link
						&:after 
							background: #ffffff

			&.current 
				.month_link
					&:before
						width 50%
				
				.month_link
					&:after
						content ''
						display: block
						position absolute
						top 11px
						left 50%
						width 50%
						height 2px 
						background #ffffff
						margin 0

						@media (max-width 1200px)
							top 22px

			&.month_1
				.month_link
					&:before
						left 50%
						width 50%

			&.month_12
				.month_link
					&:before
						left 0
						width 50%

				&.current
					.month_link
						&:after 
							display none

		.total
			font-size: 16px;
			font-weight: bold

.month_time_footer
	.month_time_line
		.game_title
			font-weight: bold

		.month 
			font-size: 13px;
			font-weight: bold

		.total
			width: 100px;
			font-size: 16px;
			font-weight: bold
			padding-right 10px

#month_time_spent
	position relative
	padding: 20px;
	width: 100%;
	max-width: 900px

	@media (max-width 1200px)
		.month_time_body
			.month_time_line
				padding-top: 0
				padding-bottom: 30px

.mtbullet
	position relative
	display: block;
	width: 24px;
	height: 24px;
	line-height: 24px;

	span
		position relative
		color white
		color #ebebeb
		z-index 2
		font-size: 12px;

	&:before
		content ''
		display block
		position absolute
		top 50%
		left 50%
		z-index 1
		background: #6ea8d9;
		width: 24px;
		height: 24px;
		margin-top: -12px;
		margin-left: -12px;
		border-radius: 100%;

	&.-runs
		&:after
			content ''
			display block
			position absolute
			bottom 1px
			left 50%
			z-index 1
			background: white;
			width: 4px;
			height: 4px;
			margin-left: -2px;
			border-radius: 100%;

	&.empty
		&:before
			background: $color_month_time_empty
			width: 10px;
			height: 10px;
			margin-left: -5px;
			margin-top: -5px;
			transition all 300ms ease-in-out

		&:hover 
			&:before
				background $color_primary

	&.-h5
		&:before 
			background $color_primary
			width: 28px;
			height: 28px;
			margin-left: -14px;
			margin-top: -14px;

	&.-h10
		&:before 
			background $color_primary
			width: 32px;
			height: 32px;
			margin-left: -16px;
			margin-top: -16px;

		&:after
			bottom -1px

	&.-h20
		&:before 
			background #3f9598
			width: 40px;
			height: 40px;
			margin-left: -20px;
			margin-top: -20px;

		&:after
			bottom -4px

	&.-h50
		&:before 
			background #30b696
			width: 48px;
			height: 48px;
			margin-left: -24px;
			margin-top: -24px;

		&:after
			bottom -8px

.add_game_time
	font-style: italic;
	color: #0bb83d;
	border-color: #0bb83d;

	&:hover 
		color: #0b772a
		border-color: #0b772a
		background-color: #0bb83d;
		color: white

.synchro_time_run_desc
	font-size: 11px;
	line-height: 12px;

.delete_month_time
	font-size:14px;

.last_update_month_time
	font-size:12px;
	line-height:14px
	opacity: 0.75;

.game_go_stats
	margin-bottom -10px

	a
		color $color_darkgray
		font-weight: 400;
		letter-spacing: 0.5px;
		text-decoration: none;
		transition: all 500ms cubic-bezier(0, 0, 0.14, 0.99);

		&:hover
			color #3f9598;
			text-decoration: underline;

#game_ifyoulike
	margin-bottom: 30px;
	
	.game_like
		float: left;
		padding-bottom: 10px;
		height: 200px;
		width: 127px;
		margin-right: 15px;
		
		@media screen and (min-width: 1201px)
			// &:nth-child(4n)
			// 	margin-right: 0px;
			
			&:nth-child(n+9)
				display:none;
		
		@media screen and (min-width:992px) and (max-width: 1200px)
			width: 141px;
			height: 210px;
			
			&:nth-child(3n)
				margin-right: 0px;
			
			&:nth-child(n+10)
				display:none;
		
		@media screen and (min-width:768px) and (max-width: 991px)
			width: 132px;
			
			&:nth-child(5n)
				margin-right: 0px;
		
		@media screen and (min-width:501px) and (max-width: 767px)
			width: 23.5%;
			margin-right: 2%;
			
			&:nth-child(4n)
				margin-right: 0px;
			
			&:nth-child(n+9)
				display:none;
		
		@media screen and (min-width:0px) and (max-width: 500px)
			width: 32%;
			margin-right: 2%;
			
			&:nth-child(3n)
				margin-right: 0px;
			
			&:nth-child(n+10)
				display:none;
		
		a
			display: block;
			width: 100%;
			overflow: hidden;
			height: 100%;
			text-align: center;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			// transition: all 1000ms ease-in-out;
		
		.ifyoulike_cover
			height: 100%;


.time_game
	font-size 18px
	font-weight bold

.flag_sorties
	height: 16px;
	vertical-align: middle;

// #control_game_poss, #control_game_etat
// 	width: 48%;
// 	margin-bottom: 10px;
// 	margin-right: 2%;

// 	a
// 		margin-right: 5px;
// 		margin-bottom: 5px;
// 		width: 100%;

#buttonTags
	width: 300px;
	white-space: normal;
	background: #FFF;

#listTags
	width: 300px;
	max-width: none;
	padding: 10px;
	top: 32px;
	height: 227px;
	overflow-y: scroll;

	input
		margin-right: 10px;
	
	li:hover
		background: $color_blue;
		color: #FFF;

.game_play
	height: 97px;
	margin-bottom: 10px;
	background: #e0e0e0;
	background: white;
	padding: 10px;
	border: 1px solid #292929;
	border-radius: 8px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	@media (max-width 1024px)
		height auto

	.game_time_text
		display none;

	.game_time_run
		display none;

	&.etat_1, &.etat_2, &.etat_3, &.etat_5
		.game_time_text
			display block;

		.game_time_run
			display block;

	.label_span
		width: 100px;
		display: inline-block;
		padding-left: 5px;

	.edit_global_time
		&.disabled
			color gray
			cursor: default;

.game_time_global
	text-align: right;
	position: absolute;
	top: 0;
	right: 26px;
	display: flex;
	align-items: center;

p.time_spent
	text-transform: uppercase;
	font-size: 10px;
	color: #a19f9f;
	font-weight: 500;
	text-align: right
	margin: 0;
	
.game_play_actions 
	margin-top 17px
	margin-right: -10px;

	.fa
		font-size: 16px;
		color: #333;

	> a
		padding: 10px;
		transition all 250ms ease-in-out
		// background: #f3f3f3;

		&:hover
			background: #f3f3f3;
	
	&:hover
		opacity: 0.8;

#gameFeedDateForm
	overflow initial
	
.wrapper_btn_replay
	// margin-top: -16px;

	.btn
		border: 1px solid #292929;
		border-radius: 8px;
		// border-bottom-right-radius: 8px;

.label-game-support
	background: #B7B7B7;

.label-game-xboxone
	background: #127E11;

.label-game-xbox360
	background: #73B554;

.label-game-ps4
	background: #015BD6;

.label-game-pc
	background: #00AEF0;

.game_players
	background: #f2f2f2;

	&.players_etat_1
		// border 1px solid $color_game_termine
		border-left: 12px solid $color_game_termine

		h2
			color $color_game_termine;
	
	&.players_etat_2
		// border 1px solid $color_game_joue
		border-left: 12px solid $color_game_joue

		h2
			color $color_game_joue;
	
	&.players_etat_3
		// border 1px solid $color_game_encours
		border-left: 12px solid $color_game_encours

		h2
			color $color_game_encours;
	
	&.players_etat_4
		// border 1px solid $color_wish
		border-left: 12px solid $color_wish

		h2
			color $color_wish;
	
	&.players_etat_5
		// border 1px solid $color_game_pause
		border-left: 12px solid $color_game_pause

		h2
			color $color_game_pause;

.icon_abo
	display: inline-block;
	width 15px;
	height 15px
	background-repeat: no-repeat
	background-size cover
	background-position: 50% 50%

	&.icon_abo_none
		background-image url(../../images/jeux-video/icon_abo_none.svg)

	&.icon_abo_gamepass
		background-image url(../../images/jeux-video/icon_abo_gamepass.svg)

	&.icon_abo_psplus
		background-image url(../../images/jeux-video/icon_abo_psplus.svg)

.icon_posstype
	display: inline-block;
	width 15px;
	height 15px
	background-repeat: no-repeat
	background-size cover
	background-position: 50% 50%

	&.icon_posstype_physique
		background-image url(../../images/jeux-video/icon_posstype_physique.svg)

	&.icon_posstype_demat
		background-image url(../../images/jeux-video/icon_posstype_demat.svg)

.filter_jv_trophy
	> a
		text-decoration: none

		&.active
			font-weight: bold

.filter_trophy_playstation
	> a
		text-decoration: none

		&.active
			font-weight: bold

.box_howlongtobeat
	background:#242424

	h2 
		color: white;
		padding-bottom: 0;
		margin-bottom: 0;

		img.hlbt_icon
			height:40px

	.howlongtobeat_link
		width 100%
		color white

		.col
			border-right: 1px solid hsla(0,0%,100%,.2);
			
			&:last-child
				border-right 0

		h3 
			color white
			text-align: center;
			font-size: 15px;
			font-weight: 500;

		.hlbt_hour
			text-align: center;
			font-size 20px
			font-weight: 400
/*********************************************/
/* Rétro */

.btn-retro
	color: #fff;
	background-color: #337ab7;
	border-color: #2e6da4;
	background-color: #292c2c;
	border-color: #292c2c;
	// color: #877201;
	// background-color: #FFD700;
	// border-color: #dbb903;
	font-family: 'Roboto';
	font-weight: 900;
	/* text-transform: uppercase; */
	padding: 10px 30px;
	font-size: 16px;
	transition all 250ms ease-in-out

	&:hover, &:focus
		background: #11538b;
		color: #fff

.retro_slice
	padding-top 60px
	padding-bottom 60px

	h2.subtitle
		color: #fff;
		text-transform: uppercase;
		font-size: 50px;
		line-height: 38px;
		margin-top: 0;
		margin-bottom: 40px;


.retro_slice_intro
	background: #292c2c;
	padding: 40px 0;

	h1 
		color: white;
		text-transform: uppercase;
		font-size: 80px;
		line-height: 70px;

		@media (max-width 1024px)
			font-size: 40px
			line-height: 40px;

		.retro_year
			color $color_blue

	.retro_username
		font-size: 25px;
		font-weight: 500;
		color: $color_blue
		margin-top: 40px;

		.avatar
			display: inline-block;
			width: 65px;
			border-radius: 100%;
			margin-right: 10px;




/* bg color slices */
.retro_slice_numbers
	background-color:$color_darkblue
	color:white;

.retro_slice_series
	background-color #2f7ed8
	background-color $color_darkblue
	background-color #2f353a

.retro_slice_dramas
	background-color #b92c80;

.retro_slice_animes
	background-color #64a5f1
	background-color #214d81
	background-color #5da3af
	
.retro_slice_emissions
	background-color #2367b5
	
.retro_slice_sports
	background-color #48bd8f
	background-color #169d68

.retro_slice_movies
	background-color: #2c2c2c

.retro_slice_games
	background-color:#9503c5

.retro_slice_footer
	background-color:#fafafa
	background-color: #242424



.retro_box_global
	background white 
	color $color_black
	padding 30px

.retro_box_show
	position relative
	display block
	background white 
	color $color_black
	margin-bottom: 30px
	transition all 250ms ease-in-out
	box-shadow: 0px 0px #00000045;
	overflow: hidden

	.show_day
		position absolute
		top 5px
		left 5px
		width 28px
		height 30px
		
		span.calendar
			display block
			position absolute
			top: 10px;
			width 100%
			height 20px
			background $color_darkgray

		i.fa 
			position relative
			font-size: 30px;
			color white

		span.day
			position absolute
			top: 9px;
			left: 0;
			width: 100%;
			font-size: 14px;
			font-weight: 400;
			color white
			text-align: center

	h3.box_white
		font-size 15px
		font-weight: 500;
		padding-top 10px
		padding-left 20px
		padding-right 20px
		padding-bottom: 10px
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	h3.season_number
		font-size 15px
		font-weight: 500;
		padding-top 10px
		padding-left 20px
		padding-right 20px
		padding-bottom: 10px
		margin: 0;
		position absolute
		bottom 0px
		right 0px
		padding 5px 10px
		line-height: 20px;
		font-size 16px
		font-size 13px
		color white
		font-weight: 500
		background-color rgba(0, 0, 0, 0.61);
		width: 100%;
		height: 100%;
		text-align: right;
		transform translateY(100%)
		transition all 250ms ease-in-out
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;

	p
		margin: 0;
		padding: 10px;

	.retro_show_img
		width 100%

	// &.-sf
	// 	h3 
	// 		background-color: #104572;
	// 		background-color: $color_darkblue
	// 		color: #d8eaf9;

	&:hover
		color $color_black
		box-shadow: 0 0 0px 4px rgb(255 255 255);
		transform: scale(1.2);
		z-index: 100;

		@media (max-width 767px)
			transform: scale(1.05);

		.season_number
			transform translateY(0px)

	&.retro_box_show_big
		background: #1a1a1a;
		color: #d1d1d1;

		&:hover
			color #d1d1d1
			transform: scale(1.1);

			@media (max-width 767px)
				transform: scale(1.05);

.retro_next_shows
	a 
		color: #fff;
		text-decoration: underline;

		&:hover
			color #fff;

.retro_month_title
	color: white 
	font-size 18px
	font-style: italic;
	font-weight: 500;

.col-retro-show
	width 20%

	@media (max-width 1024px)
		width 50%

.col-retro-movie
	width 20%

	@media (max-width 1024px)
		width 50%

	@media (max-width 380px)
		width 100%

.retro_box_movie
	width 100%
	display block
	background #1a1a1a 
	color white
	margin-bottom: 30px
	transition all 250ms ease-in-out
	box-shadow: 0px 0px #00000045;

	h3
		font-size 15px
		font-weight: 600;
		padding-top 10px
		padding-left 20px
		padding-right 20px
		padding-bottom: 10px
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	p
		margin: 0;
		padding: 10px;

	.retro_movie_img
		width: 100%;
		height 300px
		background-repeat: no-repeat
		background-size cover
		background-size contain
		background-position: 50% 50%;

	&:hover
		color white
		box-shadow: 0 0 0px 4px rgb(255 255 255);
		transform: scale(1.1);
		z-index: 100;

		@media (max-width 767px)
			transform: scale(1.05);